import { HOME_ROUTE } from '../../utils/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { CustomUserButtonMenu } from './CustomUserButtonMenu';
import { useAuthManager } from '../../hooks/auth/useAuthManager';

export function UserButtonWithAnalytics({
  organization_exists,
  setOrganizationExists,
  handleOnClickOrg,
}) {
  const navigate = useNavigate();
  const { signOut } = useAuthManager();
  const posthog = usePostHog();

  const handleSignOut = () => {
    posthog?.reset();
    signOut().then(() => navigate(HOME_ROUTE));
  };

  return (
    <CustomUserButtonMenu
      organization_exists={organization_exists}
      setOrganizationExists={setOrganizationExists}
      handleOnClickOrg={handleOnClickOrg}
      handleSignOut={handleSignOut}
    />
  );
}
