import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../store/DataContext';
import { useProjectAPIEndpoints } from './useProjectAPIEndpoints';
import { useProjectStore } from './useProjectStore';
import type { ProjectsObj } from '../../utils/types';
import { useAuthManager } from '../auth/useAuthManager';

const useFetchProjects = () => {
  const { data, setData } = useContext(DataContext);
  const fetchedProjects = data.fetchedProjects;
  const [haveCalled, setHaveCalled] = useState(false);
  const { getProjects } = useProjectAPIEndpoints();
  const { user } = useAuthManager();

  const { projects, setProjects, curProjectUuid, setCurProjectUuid } =
    useProjectStore((state) => ({
      projects: state.projects,
      setProjects: state.setProjects,
      curProjectUuid: state.curProjectUuid,
      setCurProjectUuid: state.setCurProjectUuid,
    }));

  const fetchProjects = async () => {
    if (!haveCalled) {
      const projectsResponse = getProjects();
      setData((prevData) => ({
        ...prevData,
        fetchedProjects: projectsResponse,
      }));
      setHaveCalled(true);
    }
  };

  useEffect(() => {
    if (!fetchedProjects || (fetchedProjects.length === 0 && !haveCalled)) {
      fetchProjects();
      setHaveCalled(true);
    }

    if (fetchedProjects?.length > 0) {
      const newProjects: ProjectsObj = { ...projects };
      fetchedProjects.forEach((project) => {
        if (!newProjects[project.uuid]) newProjects[project.uuid] = project;
      });
      setProjects(newProjects);
      if (!curProjectUuid) setCurProjectUuid(Object.keys(newProjects)[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedProjects, haveCalled]);

  useEffect(() => {
    // Read curProjectUuid from local storage when the component mounts
    const savedCurProjectUuid = localStorage.getItem(
      `${user?.id}-curProjectUuid`
    );
    if (savedCurProjectUuid) {
      setCurProjectUuid(savedCurProjectUuid);
    }
  }, [setCurProjectUuid, user?.id]);

  // Update local storage whenever curProjectUuid changes
  useEffect(() => {
    if (curProjectUuid) {
      localStorage.setItem(`${user?.id}-curProjectUuid`, curProjectUuid);
    }
  }, [curProjectUuid, user?.id]);
};

export default useFetchProjects;
