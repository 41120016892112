import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

const IGNORE_DESCRIPTIONS = [
  'Network Error',
  'Unauthorized',
  'Error: Network Error',
  'Error: Unauthorized',
  'Token has expired Signature has expired',
  'Error: Token has expired Signature has expired',
];

export const ErrorAlert = ({
  title,
  description,
  setError,
  errorTimeout = 10000, // Default timeout of 10 seconds
}) => {
  const alertRef = useRef(null);
  const timeoutRef = useRef(null);
  const [timeout, setErrorTimeout] = useState(errorTimeout);
  const errorMsg =
    typeof description === 'object' ? description?.toString() : description;

  useEffect(() => {
    if (errorMsg?.length <= 100) {
      setErrorTimeout(2500);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (errorMsg && alertRef?.current) {
      alertRef?.current?.focus();
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      // Set a timeout to clear the error, but only if errorTimeout is not null
      if (timeout !== null) {
        timeoutRef.current = setTimeout(() => {
          if (setError) {
            setError(null);
          }
        }, timeout);
      }
    }

    // Clean up by clearing the timeout when the component unmounts or error/description changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [errorMsg, setError, timeout]);

  if (!errorMsg || IGNORE_DESCRIPTIONS.includes(errorMsg)) {
    if (IGNORE_DESCRIPTIONS.includes(errorMsg)) {
      console.log('Passive error:', errorMsg);
    }
    return null;
  }

  const handleClose = () => {
    clearTimeout(timeoutRef.current);
    if (setError) {
      setError(null);
    }
  };

  return (
    <Alert
      ref={alertRef}
      tabIndex={-1}
      status="error"
      mb={4}
      outline="none"
      borderRadius="md"
    >
      <AlertIcon />
      <AlertTitle mr={2}>{title}</AlertTitle>
      <AlertDescription sx={{ whiteSpace: 'pre-line' }}>
        {errorMsg}
      </AlertDescription>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={handleClose}
      />
    </Alert>
  );
};
