import type { Message } from './types';

export const findFirstNonMatching = (list, target) => {
  return list.find((item) => item !== target);
};

export const safeFormatTemplateToPrompt = (template, kwargs) => {
  // Only variables '{{...}}' will be replaced, not any in '{...}'

  function replace(match, var_name) {
    return kwargs.hasOwnProperty(var_name) ? String(kwargs[var_name]) : match;
  }

  return template.replace(/{{(\w+)}}/g, replace);
};

export const extractTemplateVariables = (messages: Message[]) => {
  const variables = new Set();
  messages.forEach((message) => {
    const matches = (message?.content || '').match(/{{(\w+)}}/g);
    if (matches) {
      matches.forEach((match) => {
        variables.add(match.slice(2, -2));
      });
    }
  });
  return Array.from(variables);
};

export function incrementId(item) {
  let newId = Math.max(...Object.keys(item).map(Number)) + 1;
  if (newId < 0) {
    newId = 0;
  }
  return newId;
}

export const arraysAreEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((value, index) => value === array2[index]);
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const makeTraceLogFormattedDate = (
  date: string,
  short: boolean = false,
  showTime: boolean = false,
  showSeconds: boolean = true,
  showYear: boolean = true
) => {
  const options = {
    year: showYear ? 'numeric' : undefined,
    month: short ? 'short' : 'long',
    day: 'numeric',
    ...(showTime
      ? {
          hour: 'numeric',
          minute: 'numeric',
          second: showSeconds ? 'numeric' : undefined,
        }
      : {}),
    timeZoneName: 'short',
    hour12: true,
  };

  try {
    const dateObj = new Date(date);
    const timezoneOffset = dateObj.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const localDate = new Date(dateObj.getTime() - timezoneOffset);
    return localDate.toLocaleString('en-US', options);
  } catch (error) {
    console.error('Error formatting date', error);
    return date;
  }
};

export const makeTraceLogFormattedDateP = (
  date: string,
  short: boolean = false,
  showTime: boolean = false,
  showSeconds: boolean = true,
  showYear: boolean = true
) => {
  const options = {
    year: showYear ? 'numeric' : undefined,
    month: short ? 'short' : 'long',
    day: 'numeric',
    ...(showTime
      ? {
          hour: 'numeric',
          minute: 'numeric',
          second: showSeconds ? 'numeric' : undefined,
        }
      : {}),
    timeZoneName: 'short',
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  try {
    return formatter.format(new Date(date));
  } catch (error) {
    console.error('Error formatting date', error);
    return date;
  }
};

export const makeTraceLogFormattedTimeOnly = (date: string) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  try {
    const dateObj = new Date(date);
    const timezoneOffset = dateObj.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const localDate = new Date(dateObj.getTime() - timezoneOffset);
    return localDate.toLocaleString('en-US', options);
  } catch (error) {
    console.error('Error formatting date', error);
    return date;
  }
};

export const makeTraceLogFormattedDateNoTime = (date: string) => {
  return makeTraceLogFormattedDate(date, true, false);
};
export const makeTraceLogFormattedDateDetailed = (date: string) => {
  return makeTraceLogFormattedDate(date, true, true);
};

export const makeTraceLogFormattedDateConcise = (date: string) => {
  return makeTraceLogFormattedDate(date, true, true, false, false);
};

export const makeTraceLogFormattedDateConciseP = (date: string) => {
  return makeTraceLogFormattedDateP(date, true, true, false, false);
};

export const makeFormattedDate = (date) => {
  return makeTraceLogFormattedDate(date, false, false);
};

export const makeFormattedTime = (date) => {
  return makeTraceLogFormattedTimeOnly(date);
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const isToday = (someDate) => {
  if (!someDate) {
    return false;
  }
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export function extractName(currentFunction: string): string | null {
  try {
    const funcObj = JSON.parse(fixJsonString(currentFunction));
    return funcObj.name;
  } catch (error) {
    try {
      // split by commas not inside quotes
      const parts = currentFunction.split(
        /,(?=(?:[^']*'[^']*')*[^']*$)(?=(?:[^"]*"[^"]*")*[^"]*$)/g
      );

      for (const part of parts) {
        // regex to match 'name': 'value' or "name": "value" pattern
        const match = part.match(/(['"]name['"])\s?:\s?(['"])(.*?)\2/);
        if (match) {
          return match[3];
        }
      }

      return null;
    } catch (error) {
      console.error('Invalid format', error);
      return null;
    }
  }
}

export function extractDescription(currentFunction: string): string | null {
  try {
    const funcObj = JSON.parse(fixJsonString(currentFunction));
    return funcObj.description;
  } catch (error) {
    try {
      // split by commas not inside quotes
      const parts = currentFunction.split(
        /,(?=(?:[^']*'[^']*')*[^']*$)(?=(?:[^"]*"[^"]*")*[^"]*$)/g
      );

      for (const part of parts) {
        // regex to match 'description': 'value', "description": "value" or 'description': '''value'''
        const match = part.match(
          /(['"]description['"])\s?:\s?(?:'''([\s\S]*?)'''|"""([\s\S]*?)"""|(['"])(.*?)\4)/
        );
        if (match) {
          // Check which group was captured, trim white spaces and return
          return (match[2] || match[3] || match[5]).trim();
        }
      }

      return null;
    } catch (error) {
      console.error('Invalid format', error);
      return null;
    }
  }
}

const ROP = 'YOU NEED TO MOVE THE REQUIRED FIELD INSIDE OF PARAMETERS.';
const RIP =
  'YOU NEED TO MOVE THE REQUIRED FIELD OUTSIDE OF PROPERTIES BUT STILL INSIDE OF PARAMETERS.';

function makeCorrectRequiredPlacementMsg(message) {
  return `NOTE: ${message} FIX REQUIRED FIELD PLACEMENT THEN TRY AGAIN:\n "parameters": {\n "type": "object",\n "properties": {},\n "required": []\n}`;
}

function fixJsonString(jsonString) {
  // Remove trailing commas from the last elements in objects and arrays
  jsonString = jsonString.replace(/,(\s*[\]}])/g, '$1');

  return jsonString;
}

function replaceAndRemoveSpecialChars(string, replacementString = '-') {
  // Remove all closing brackets
  string = string
    .replace(/[})\]]/g, '')
    .trim()
    .toLowerCase();
  // Replace all special charts except for hyphens and underscores with the hyphen
  return string.replace(/[^a-zA-Z0-9_-]/g, replacementString);
}

export function formatPropertiesAndUpdateRequired(func, toastDispatch) {
  const originalFunc = func.slice();
  let funcObj = null;
  try {
    funcObj = JSON.parse(fixJsonString(func));
  } catch (error) {
    let msg = `JSON formatting error: ${error}`;
    if (
      error instanceof SyntaxError &&
      error?.message?.includes("Expected property name or '}' in JSON")
    ) {
      msg = 'JSON Error: Make sure to use double quotes (i.e., "name")';
    }
    toastDispatch(msg, 'error', 6000);
    return originalFunc;
  }

  const warningMessages = [];

  if (funcObj?.required) {
    toastDispatch(makeCorrectRequiredPlacementMsg(ROP), 'error', 5000);
    return originalFunc;
  }
  if (funcObj?.parameters?.properties?.required) {
    toastDispatch(makeCorrectRequiredPlacementMsg(RIP), 'error', 5000);
    return originalFunc;
  }

  const keyMapping = {};
  if (funcObj.name?.length > 64) {
    warningMessages.push(
      `NOTE: FUNCTION NAME TRUNCATED TO MAX 64 CHARS: ${funcObj.name}`
    );
  }
  funcObj.name = replaceAndRemoveSpecialChars(
    funcObj.name.substring(0, 64),
    '_'
  );

  if (funcObj.parameters && funcObj.parameters.properties) {
    const properties = funcObj.parameters.properties;
    // Format the keys and build a new properties dictionary
    const formattedProperties = {};
    Object.entries(properties).forEach(([key, value]) => {
      const formattedKey = replaceAndRemoveSpecialChars(key);
      formattedProperties[formattedKey] = value;
      keyMapping[key] = formattedKey;
    });

    // Update the properties with formatted keys
    funcObj.parameters.properties = formattedProperties;

    // Update the "required" field with the list of formatted keys
    if (funcObj.parameters?.required?.length > 0) {
      const formattedRequired = [];
      const removedKeys = [];
      funcObj.parameters.required.forEach((key) => {
        const formattedKey = keyMapping[key];
        if (formattedKey) {
          formattedRequired.push(formattedKey);
        } else {
          removedKeys.push(key);
        }
      });
      if (removedKeys.length > 0) {
        warningMessages.push(
          `NOTE: KEYS REMOVED FROM REQUIRED BECAUSE THEY WERE NOT IN PROPERTIES: ${removedKeys}`
        );
      }
      funcObj.parameters.required = formattedRequired;
    }
  }

  if (warningMessages.length > 0 && toastDispatch) {
    const finalMessage = warningMessages.join('\n');
    toastDispatch(finalMessage, 'warning', 2300);
  }

  const updatedSchema = JSON.stringify(funcObj, null, 4);
  if (toastDispatch) {
    let msg = 'Schema formatting successfully updated!';
    let status = 'success';
    if (originalFunc === updatedSchema) {
      msg = 'No changes needed';
      status = 'info';
    }
    toastDispatch(msg, status);
  }
  return updatedSchema;
}

export function getAzureEndpointBasedOnModelName(modelName, mergedAzureOpenAI) {
  if (!modelName || !mergedAzureOpenAI) {
    return null;
  }
  for (const [endpoint, { deploymentNames }] of Object.entries(
    mergedAzureOpenAI || {}
  )) {
    if (deploymentNames.includes(modelName)) {
      return endpoint;
    }
  }
  return null;
}

export function nFormatter(num?: number, digits?: number) {
  if (!num) return '0';
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits || 1).replace(rx, '$1') + item.symbol
    : '0';
}

export const numberFormat = (
  num: number,
  locale = 'en-US',
  fractionDigits = 0
) => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return formatter.format(num);
};

export const roleBadgeColor = (role) => {
  if (role === 'user') {
    return 'emerald';
  } else if (role === 'assistant') {
    return 'sky';
  } else if (role === 'image') {
    return 'rose';
  } else if (role === 'system') {
    return 'yellow';
  } else {
    return 'teal';
  }
};

export function parseValue(value: string): any {
  if (isValidJSON(value)) {
    const parsedValue = JSON.parse(value);
    if (typeof parsedValue === 'number' && parsedValue.toString() !== value) {
      // If the parsed value is a number and its string representation doesn't match the original string,
      // it means the number is too large and should be kept as a string.
      return value;
    }
    return parsedValue;
  }
  return value;
}

function isValidJSON(value: string): boolean {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}
