import { HOME_ROUTE } from '../../utils/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomUserButtonMenu } from './CustomUserButtonMenu';
import { useAuthManager } from '../../hooks/auth/useAuthManager';

export function UserButton({
  organization_exists,
  setOrganizationExists,
  handleOnClickOrg,
}) {
  const navigate = useNavigate();
  const { signOut } = useAuthManager();

  const handleSignOut = () => {
    signOut().then(() => navigate(HOME_ROUTE));
  };

  return (
    <CustomUserButtonMenu
      organization_exists={organization_exists}
      setOrganizationExists={setOrganizationExists}
      handleOnClickOrg={handleOnClickOrg}
      handleSignOut={handleSignOut}
    />
  );
}
