import { useCallback, useEffect } from 'react';
import { ANALYTICS_ENDPOINT } from '../utils/constants';
import { useApiClient } from './useApiClient';

export const usePageVisitedAnalytics = (pageName) => {
  const { postRequest } = useApiClient();

  const updateSiteVisitAnalytics = useCallback(async () => {
    try {
      await postRequest(ANALYTICS_ENDPOINT, {
        name: '$pageview',
        data: { site: pageName },
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateSiteVisitAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useAnalytics = (
  eventName: string,
  data: { [key: string]: string }
) => {
  const { postRequest } = useApiClient();

  const sendAnalytics = useCallback(async (eventName, data) => {
    try {
      await postRequest(ANALYTICS_ENDPOINT, {
        name: eventName,
        data: data,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { sendAnalytics };
};
