import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { Button } from '../ui/button';
import { Progress } from '../ui/progress';
import {
  CHECKOUT_SESSION_ENDPOINT,
  ENTERPRISE_MEETING_ID,
  PLAN_NAME_TO_IDX,
  PLANS,
} from '../../utils/constants';
import { LightningBoltIcon } from '@radix-ui/react-icons';
import { getDaysInMonth } from 'date-fns';
import { nFormatter, numberFormat } from '../../utils/helpers';
import useFetchUsageStats from '../../hooks/useFetchUsageStats';
import { Separator } from '../ui/separator';
import { useUserContext } from '../../store/UserContext';
import { useApiClient } from '../../hooks/useApiClient';
import { useDisclosure } from '@chakra-ui/react';
import { PopupModal } from 'react-calendly';
import { useAuthManager } from '../../hooks/auth/useAuthManager';
import { useMemo } from 'react';

export const FreeTierCard = ({ isSmall }) => {
  const { postRequest } = useApiClient();
  const { userSummaryStats } = useFetchUsageStats(true);
  const { subscriptionPlan } = useUserContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user } = useAuthManager();
  const org_id = useMemo(
    () => user?.organizationMemberships?.[0]?.organization?.id,
    [user]
  );

  const plan_idx = useMemo(
    () => PLAN_NAME_TO_IDX[subscriptionPlan || 'free'],
    [subscriptionPlan]
  );
  const next_tier = useMemo(() => PLANS[plan_idx + 1]?.name, [plan_idx]);
  const LOGS_PER_MONTH = useMemo(
    () => PLANS[plan_idx]?.usageLimits?.loggedEvents,
    [plan_idx]
  );
  const NUMBER_OF_DEPLOYS = useMemo(
    () => PLANS[plan_idx]?.usageLimits?.deployedPrompts,
    [plan_idx]
  );

  const logCount = useMemo(
    () => userSummaryStats?.trace_logs ?? 0,
    [userSummaryStats]
  );
  const logsLeft = LOGS_PER_MONTH - logCount;
  const projectedLogs = useMemo(
    () => (logCount / new Date().getDate()) * getDaysInMonth(new Date()),
    [logCount]
  );

  const deploysCount = useMemo(
    () => userSummaryStats?.deployments ?? 0,
    [userSummaryStats]
  );
  const deploysLeft = NUMBER_OF_DEPLOYS - deploysCount;

  const fullColor = useMemo(() => {
    return plan_idx === 1 ? 'bg-orange-200' : 'bg-red-500';
  }, [plan_idx]);

  const overageText = useMemo(() => {
    return plan_idx === 1 ? `Add'l charges may apply` : 'Over limit';
  }, [plan_idx]);

  const handleUpgrade = async () => {
    if (next_tier === 'Enterprise') {
      onOpen();
      return;
    }

    try {
      const response = await postRequest(CHECKOUT_SESSION_ENDPOINT, {
        plan: next_tier,
        interval: 'month',
        organization_id: org_id,
      });
      if (response.data.status === 200) {
        window.location.replace(response.data.redirect_url);
      }
    } catch (error) {
      console.error(error.detail);
    }
  };

  return (
    <>
      <PopupModal
        url={ENTERPRISE_MEETING_ID}
        onModalClose={onClose}
        open={isOpen}
        rootElement={document.getElementById('root')}
      />
      <Card className="p-2 bg-transparent">
        {!isSmall && (
          <>
            <CardHeader className="p-1">
              <CardTitle>{PLANS[plan_idx]?.name || 'Free'} Tier</CardTitle>
            </CardHeader>
            <CardContent className="p-1 flex flex-col gap-1.5">
              <div className="text-xs text-muted-foreground">
                {logCount} / {numberFormat(LOGS_PER_MONTH)} logs
              </div>
              <div>
                <Progress
                  value={Math.min((logCount / LOGS_PER_MONTH) * 100, 100)}
                  fullColor={logsLeft <= 0 ? fullColor : ''}
                />
              </div>
              <div className="text-xs">
                {logsLeft < 0
                  ? overageText
                  : `${numberFormat(logsLeft)} logs left this month`}
              </div>
              {projectedLogs > 0 && (
                <div className="text-xs">
                  ~{nFormatter(projectedLogs)} logs projected
                </div>
              )}
              <Separator />
              <div className="text-xs text-muted-foreground">
                {deploysCount} / {numberFormat(NUMBER_OF_DEPLOYS)} deployments
              </div>
              <div>
                <Progress
                  value={Math.min(
                    (deploysCount / NUMBER_OF_DEPLOYS) * 100,
                    100
                  )}
                  fullColor={deploysLeft <= 0 ? fullColor : ''}
                />
              </div>
              <div className="text-xs">
                {deploysLeft < 0
                  ? overageText
                  : `${numberFormat(deploysLeft)} deploys left`}
              </div>
            </CardContent>
            <CardFooter className="p-1">
              <Button
                onClick={handleUpgrade}
                size="sm"
                className="group relative justify-center gap-1 w-full transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2 overflow-hidden"
              >
                <span className="absolute right-0 -mt-40 h-64 w-8 translate-x-2 rotate-12 transform bg-white dark:bg-primary-foreground opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-32"></span>
                <LightningBoltIcon className="h-4 w-4" />
                <span>
                  {next_tier === 'Enterprise'
                    ? 'Upgrade to Entpr.'
                    : `Upgrade to ${next_tier}`}
                </span>
              </Button>
            </CardFooter>
          </>
        )}
        {isSmall && (
          <>
            <CardContent className="p-0 flex flex-col gap-1">
              <div
                className={`text-xs text-muted-foreground ${logsLeft <= 0 ? fullColor : ''}`}
              >
                {logCount} / {numberFormat(LOGS_PER_MONTH)} logs
              </div>
              <Button
                onClick={handleUpgrade}
                size="xs"
                className="group relative justify-center gap-1 w-full transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2 overflow-hidden"
              >
                <span className="absolute right-0 -mt-40 h-64 w-8 translate-x-2 rotate-12 transform bg-white dark:bg-primary-foreground opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-32"></span>
                <LightningBoltIcon className="h-4 w-4" />
                <span>
                  {next_tier === 'Enterprise'
                    ? 'Upgrade to Entpr.'
                    : `Upgrade to ${next_tier}`}
                </span>
              </Button>
            </CardContent>
          </>
        )}
      </Card>
    </>
  );
};
