import { OnboardingStepItem } from '../../../subframe/components/OnboardingStepItem';

export default function StepList(props: {
  currentStep: number,
  setStep: (value: number) => void,
}) {
  const { currentStep, setStep } = props;

  const steps = [
    {
      name: 'Create your organization',
      icon: 'FeatherUsers',
      step: 1,
      status:
        currentStep === 1
          ? 'current'
          : currentStep > 1
            ? 'complete'
            : 'upcoming',
    },
    // {
    //   name: 'Generate API key',
    //   icon: 'FeatherKey',
    //   step: 2,
    //   status:
    //     currentStep === 2
    //       ? 'current'
    //       : currentStep > 2
    //         ? 'complete'
    //         : 'upcoming',
    // },
    {
      name: 'Observability & Evals',
      icon: 'FeatherScale',
      step: 2,
      status:
        currentStep === 2
          ? 'current'
          : currentStep > 2
            ? 'complete'
            : 'upcoming',
    },
    {
      name: 'Parea LLM Proxy',
      icon: 'FeatherLink',
      step: 3,
      status:
        currentStep === 3
          ? 'current'
          : currentStep > 3
            ? 'complete'
            : 'upcoming',
    },
    {
      name: 'Send your first event',
      icon: 'FeatherActivity',
      step: 4,
      status:
        currentStep === 4
          ? 'current'
          : currentStep > 4
            ? 'complete'
            : 'upcoming',
    },
  ];

  return (
    <>
      {steps.map((step) => (
        <OnboardingStepItem
          selected={step.status === 'current'}
          completed={step.status === 'complete'}
          icon={step.icon}
          onClick={() => {
            setStep(step.step);
          }}
        >
          <p className="text-sm font-medium leading-none">{step.name}</p>
        </OnboardingStepItem>
      ))}
    </>
  );
}
