import { NavItem } from '@saas-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { HOMEPAGE } from '../../utils/constants';
import React, { useCallback } from 'react';
import { SignedIn } from '../common/Auth';
import { useAuthManager } from '../../hooks/auth/useAuthManager';

export function LogoutNav() {
  const { signOut } = useAuthManager();

  const handleClickSignOut = useCallback(() => {
    signOut({ redirectUrl: HOMEPAGE });
  }, [signOut]);

  return (
    <SignedIn>
      <NavItem icon={<FiLogOut />} onClick={handleClickSignOut}>
        Logout
      </NavItem>
    </SignedIn>
  );
}
