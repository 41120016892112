import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { PersonaAvatar } from '@saas-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useAuthManager } from '../../hooks/auth/useAuthManager';
import { ENABLE_CLERK_AUTHENTICATION } from '../../utils/constants';

export function CustomUserButtonMenu({
  organization_exists,
  setOrganizationExists,
  handleOnClickOrg,
  handleSignOut,
}) {
  const { user, openUserProfile } = useAuthManager();
  const imgUrl = user?.imageUrl;
  const fullName = user?.fullName;
  setOrganizationExists(user?.organizationMemberships?.length > 0);
  const orgText = organization_exists ? 'Organization' : 'Create Organization';

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={
          <PersonaAvatar
            presence="online"
            size="xs"
            src={imgUrl}
            name={fullName}
          />
        }
        variant="ghost"
      />
      <MenuList>
        {ENABLE_CLERK_AUTHENTICATION === true && (
          <>
            <MenuItem onClick={() => openUserProfile()}>Profile</MenuItem>
            {!organization_exists && (
              <MenuItem onClick={handleOnClickOrg}>{orgText}</MenuItem>
            )}
          </>
        )}
        <MenuItem icons={<FiLogOut />} onClick={handleSignOut}>
          Sign Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
