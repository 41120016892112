import {
  useAuth as useClerkAuth,
  useClerk,
  useUser as useClerkUser,
} from '@clerk/clerk-react';
import { ENABLE_CLERK_AUTHENTICATION } from '../../utils/constants';
import { useMemo } from 'react';
import { useSSOProfile, useSSOToken, useSSOUser } from './ssoAuthHooks';

const authMethods = {
  clerk: useClerkAuth,
  sso: useSSOToken,
};

const userMethods = {
  clerk: useClerkUser,
  sso: useSSOUser,
};

const generalMethods = {
  clerk: useClerk,
  sso: useSSOProfile,
};

// the user object returned by the auth hook
export interface User {
  id: string;
  name: string;
  fullName: string;
  imageUrl: string;
  primaryEmailAddress?: { emailAddress: string };
  organizationMemberships?: { organization: { id: string, name: string } }[];
}

export const useAuthManager = () => {
  const authMethod = ENABLE_CLERK_AUTHENTICATION === true ? 'clerk' : 'sso';
  const authHook = useMemo(() => authMethods[authMethod], [authMethod]);
  const userHook = useMemo(() => userMethods[authMethod], [authMethod]);
  const generalHook = useMemo(() => generalMethods[authMethod], [authMethod]);

  if (!authHook || !userHook) {
    throw new Error(`Unsupported authentication method: ${authMethod}`);
  }

  const { getToken, isLoaded, isSignedIn } = authHook();
  const { user } = userHook();
  const { signOut, openUserProfile, openOrganizationProfile } = generalHook();

  return {
    isLoaded,
    isSignedIn,
    getToken,
    user,
    signOut,
    openUserProfile,
    openOrganizationProfile,
  };
};
