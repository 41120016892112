import { useEffect } from 'react';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { useJune } from './hooks/useJune';
import * as Sentry from '@sentry/react';
import RouteNav from './RouteNav';
import { useAppEntryDataFetching } from './hooks/useAppEntryDataFetching';
import { POSTHOG_HOST, POSTHOG_KEY } from './utils/constants';
import { useAuthManager } from './hooks/auth/useAuthManager';

export default function AppRoutesWithAnalytics() {
  const { loading } = useAppEntryDataFetching();
  const posthog = usePostHog();
  const juneAnalytics = useJune();
  const { user } = useAuthManager();

  useEffect(() => {
    if (user) {
      const org = user?.organizationMemberships?.at(0)?.organization;
      const orgName = org?.name ?? 'n/a';
      const orgId = org?.id ?? 'n/a';
      const email = user?.primaryEmailAddress?.emailAddress || '';

      posthog?.identify(user?.id, {
        email: email,
        name: user?.fullName,
        organization: orgName,
      });
      posthog?.group('organization', orgId);
      juneAnalytics?.identify(user?.id, {
        email: email,
        name: user?.fullName,
        avatar: user?.imageUrl,
        organization_name: orgName,
        organization_id: orgId,
        groupId: orgId,
      });
      juneAnalytics?.group(orgId, {
        name: orgName,
        avatar: org?.imageUrl,
      });
      Sentry.setUser({
        id: user?.id,
        email: email,
        username: user?.fullName,
        organization: orgName,
      });
    }
  }, [juneAnalytics, posthog, user]);

  return (
    <PostHogProvider apiKey={POSTHOG_KEY} options={{ api_host: POSTHOG_HOST }}>
      <RouteNav loading={loading} />
    </PostHogProvider>
  );
}
