import { useRef } from 'react';
import { useToast } from '@chakra-ui/react';
import { copyToClipboard } from '../utils/clipboardUtils';

export const useCopyToClipboard = () => {
  const toast = useToast();
  const elementRef = useRef();

  const handleCopy = async (copyText = null, skipToast = false) => {
    if (!copyText && !elementRef.current) return;

    const text =
      elementRef?.current?.textContent || typeof copyText === 'object'
        ? copyText.target.innerText
        : copyText;

    const { success, error } = await copyToClipboard(text);

    if (success && !skipToast) {
      toast({
        title: 'Copied to clipboard',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } else if (error && !skipToast) {
      toast({
        title: 'Error copying to clipboard',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return { handleCopy, elementRef };
};
