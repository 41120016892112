import type { GetToken } from '../../store/SSOAuthContext';
import { useSSOAuth } from '../../store/SSOAuthContext';
import { Session } from '@sentry/react';
import type { User } from './useAuthManager';

type UseAuthReturn =
  | {
      isLoaded: false,
      isSignedIn: undefined,
      getToken: GetToken,
      getUser: () => null,
      signOut: () => Promise<void>,
    }
  | {
      isLoaded: true,
      isSignedIn: false,
      getToken: GetToken,
      getUser: () => null,
      signOut: () => Promise<void>,
    }
  | {
      isLoaded: true,
      isSignedIn: true,
      session: Session,
      getToken: GetToken,
      getUser: () => User,
      signOut: () => Promise<void>,
    };

export const useSSOToken = (): UseAuthReturn => {
  const { isLoaded, isSignedIn, getToken, getUser, signOut } = useSSOAuth();

  if (!isLoaded) {
    return {
      isLoaded: false,
      isSignedIn: undefined,
      getToken,
      getUser: () => null,
      signOut,
    };
  }

  if (!isSignedIn) {
    return {
      isLoaded: true,
      isSignedIn: false,
      getToken,
      getUser: () => null,
      signOut,
    };
  }

  return {
    isLoaded: true,
    isSignedIn: true,
    getToken,
    getUser,
    signOut,
  };
};

export const useSSOUser = (): User | null => {
  const { getUser } = useSSOAuth();
  return { user: getUser() };
};

export const useSSOProfile = () => {
  const { signOut } = useSSOAuth();

  const openUserProfile = () => {
    // Implement the logic to open the user profile
    // This can be a navigation or redirection to the user profile page
  };

  const openOrganizationProfile = () => {
    // Implement the logic to open the organization profile
    // This can be a navigation or redirection to the organization profile page
  };

  return {
    signOut,
    openUserProfile,
    openOrganizationProfile,
  };
};
