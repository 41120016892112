import useFetchProjects from '../../hooks/project/useFetchProjects';
import { useProjectStore } from '../../hooks/project/useProjectStore';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { useEffect, useState } from 'react';

const SelectProject = ({ isSidebarOpen }) => {
  useFetchProjects();
  const { projects, curProjectUuid, setCurProjectUuid } = useProjectStore(
    (state) => ({
      projects: state.projects,
      curProjectUuid: state.curProjectUuid,
      setCurProjectUuid: state.setCurProjectUuid,
    })
  );

  const [selectedProjectedUuid, setSelectedProjectedUuid] = useState(null);

  useEffect(() => {
    if (!selectedProjectedUuid && curProjectUuid) {
      setSelectedProjectedUuid(curProjectUuid);
    }
  }, [curProjectUuid, selectedProjectedUuid]);

  const handleSelectProject = (uuid: string) => {
    setSelectedProjectedUuid(uuid);
    setCurProjectUuid(uuid);
  };

  if (Object.keys(projects || {}).length === 0 || !isSidebarOpen) {
    return;
  }

  return (
    <div>
      <p className="text-md ml-1 mb-1">Projects:</p>
      <Select value={selectedProjectedUuid} onValueChange={handleSelectProject}>
        <SelectTrigger className="w-[180px] truncate">
          <SelectValue placeholder="Select project" />
        </SelectTrigger>
        <SelectContent>
          {Object.keys(projects).map((uuid) => (
            <SelectItem key={uuid} value={uuid}>
              {projects[uuid].name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default SelectProject;
