import CollapsibleSidebar from './CollapsibleSidebar';

const Layout = ({ children }) => {
  return (
    <CollapsibleSidebar>
      <div className="pt-2 overflow-auto max-h-screen max-w-full">
        {children}
      </div>
    </CollapsibleSidebar>
  );
};

export default Layout;
