import {
  Heading,
  HStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import PublicApiKey from '../../components/settings/PublicApiKey';
import { NewPageBoxWrapper } from '../../components/common/NewPageBoxWrapper';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Separator } from '../../components/ui/separator';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../components/ui/avatar';
import { FormDialog, FormLayout } from '@saas-ui/react';
import openAILogoWhite from '../../assets/openai-white-logomark.png';
import openAILogo from '../../assets/openai-logomark.png';
import anthropicLogo from '../../assets/anthropic-logo.png';
import azureLogo from '../../assets/azure-logo.png';
import anyscaleLogo from '../../assets/anyscale-logo-blue.png';
import awsLogo from '../../assets/aws-logo.png';
import gcpLogo from '../../assets/gcp-logo-cloud.webp';
import openRouterLogo from '../../assets/open-router-logo.png';
import mistralLogo from '../../assets/mistral-logo.png';
import liteLLMLogo from '../../assets/litellm-logo.png';
import groqLogo from '../../assets/groq-logo.png';
import fireworksLogo from '../../assets/fireworks-logo.png';

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../../components/ui/tabs';
import { useState } from 'react';
import { useUserContext } from '../../store/UserContext';
import { useSuccessfulToast } from '../../hooks/useSuccessfulToast';
import { capitalize } from '../../utils/helpers';
import { CreateOrg } from '../../components/common/CreateOrgModal';
import { Badge } from '../../components/ui/badge';
import {
  useAnalytics,
  usePageVisitedAnalytics,
} from '../../hooks/usePageVisitedAnalytics';
import { OPENROUTER_MODELS } from '../../utils/constants';
import MultiSelectComponent from '../../components/common/MultiSelectCombobox';
import { PlusIcon } from '@radix-ui/react-icons';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../components/ui/accordion';
import { useAuthManager } from '../../hooks/auth/useAuthManager';

const Settings = () => {
  usePageVisitedAnalytics('Settings');
  return (
    <NewPageBoxWrapper>
      <div className="flex-col">
        <HStack>
          <Heading
            size="md"
            className="text-3xl font-bold tracking-tight pt-3 pl-4"
          >
            Settings
          </Heading>
        </HStack>
        <div className="flex-1 space-y-4 p-4 pt-6">
          <APIKeyTabs />
          <Separator className="my-1" />
          <PublicApiKey />
        </div>
      </div>
    </NewPageBoxWrapper>
  );
};

export function APIKeySetupCard({ forOrg = false }) {
  const openAILogoSrc = useColorModeValue(openAILogo, openAILogoWhite);
  const { user } = useAuthManager();
  const org_id = user?.organizationMemberships?.[0]?.organization?.id;

  const {
    openAIKey,
    anthropicApiKey,
    azureOpenAI,
    mistralApiKey,
    orgMistralApiKey,
    anyscaleApiKey,
    awsAccessKeyId,
    awsSecretAccessKey,
    awsRegion,
    vertexAIJson,
    orgOpenAIKey,
    orgAnthropicApiKey,
    orgAzureOpenAI,
    orgAnyscaleApiKey,
    orgAwsAccessKeyId,
    orgAwsSecretAccessKey,
    orgAwsRegion,
    orgVertexAIJson,
    openRouterKey,
    orgOpenRouterKey,
    openRouterDeploymentNames,
    orgOpenRouterDeploymentNames,
    liteLlmEndpoint,
    orgLiteLlmEndpoint,
    liteLlmDeploymentNames,
    orgLiteLlmDeploymentNames,
    liteLlmApiKey,
    orgLiteLlmApiKey,
    groqApiKey,
    orgGroqApiKey,
    fireworksApiKey,
    orgFireworksApiKey,
  } = useUserContext();

  const Providers = [
    {
      logo: openAILogoSrc,
      logoFallback: 'O',
      name: 'OpenAI',
      apikey: forOrg ? orgOpenAIKey : openAIKey,
      model_provider: 'openai',
    },
    {
      logo: anthropicLogo,
      logoFallback: 'A',
      name: 'Anthropic',
      apikey: forOrg ? orgAnthropicApiKey : anthropicApiKey,
      model_provider: 'anthropic',
    },
    {
      logo: azureLogo,
      logoFallback: 'AZ',
      name: 'Azure',
      apikey: forOrg
        ? Object.values(orgAzureOpenAI || {})[0]?.apiKey
        : Object.values(azureOpenAI || {})[0]?.apiKey,
      endpointToDeploymentsAndKey: forOrg ? orgAzureOpenAI : azureOpenAI,
      model_provider: 'azure',
    },
    {
      logo: anyscaleLogo,
      logoFallback: 'AS',
      name: 'Anyscale (LLama & Mistral)',
      apikey: forOrg ? orgAnyscaleApiKey : anyscaleApiKey,
      model_provider: 'anyscale',
    },
    {
      logo: awsLogo,
      logoFallback: 'AWS',
      name: 'AWS Bedrock',
      apikey: forOrg ? orgAwsSecretAccessKey : awsSecretAccessKey,
      awsAccessKeyId: forOrg ? orgAwsAccessKeyId : awsAccessKeyId,
      awsRegion: forOrg ? orgAwsRegion : awsRegion,
      model_provider: 'aws',
    },
    {
      logo: gcpLogo,
      logoFallback: 'GCP',
      name: 'Google Vertex AI',
      apikey: forOrg ? orgVertexAIJson : vertexAIJson,
      model_provider: 'vertexai',
    },
    {
      logo: mistralLogo,
      logoFallback: 'M',
      name: 'Mistral',
      apikey: forOrg ? orgMistralApiKey : mistralApiKey,
      model_provider: 'mistral',
    },
    {
      logo: openRouterLogo,
      logoFallback: 'OR',
      name: 'Open Router',
      apikey: forOrg ? orgOpenRouterKey : openRouterKey,
      orDeployments: forOrg
        ? orgOpenRouterDeploymentNames
        : openRouterDeploymentNames,
      model_provider: 'openrouter',
    },
    {
      logo: liteLLMLogo,
      logoFallback: 'LLP',
      name: 'LiteLLM Proxy',
      apikey: forOrg ? orgLiteLlmApiKey : liteLlmApiKey,
      endpoint: forOrg ? orgLiteLlmEndpoint : liteLlmEndpoint,
      deployments: forOrg ? orgLiteLlmDeploymentNames : liteLlmDeploymentNames,
      model_provider: 'litellm',
    },
    {
      logo: groqLogo,
      logoFallback: 'G',
      name: 'Groq',
      apikey: forOrg ? orgGroqApiKey : groqApiKey,
      model_provider: 'groq',
    },
    {
      logo: fireworksLogo,
      logoFallback: 'F',
      name: 'Fireworks',
      apikey: forOrg ? orgFireworksApiKey : fireworksApiKey,
      model_provider: 'fireworks',
    },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          LLM Provider Keys ({forOrg ? 'Organization' : 'Personal'})
        </CardTitle>
        <CardDescription>
          {`Set provider API Keys for your account. ${
            forOrg
              ? 'These keys will be applied to everyone in your organization.'
              : 'If you have any organization, you can also set the provider keys there and they will be applied to everyone in the organization.'
          }`}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Separator className="my-4" />
        <div className="space-y-4">
          <div className="grid gap-6">
            {Providers.map((provider) => (
              <div
                key={provider.name}
                className="flex items-center justify-between space-x-4"
              >
                <ProviderAvatar {...provider} />
                <KeyFormDialog
                  provider={provider}
                  org_id={org_id}
                  forOrg={forOrg}
                />
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export function ProviderAvatar(props) {
  const {
    logo,
    logoFallback,
    name,
    apikey,
    model_provider,
    endpoint,
    deployments,
  } = props;
  const isDisabled =
    (model_provider !== 'litellm' && !apikey) ||
    (model_provider === 'litellm' && (!endpoint || !deployments));
  return (
    <div className="flex items-center space-x-4">
      <Avatar>
        <AvatarImage src={logo} />
        <AvatarFallback>{logoFallback}</AvatarFallback>
      </Avatar>
      <p className="text-sm font-medium leading-none">{name}</p>
      <Badge variant={isDisabled ? 'destructive' : 'outline'}>
        {isDisabled ? 'Disabled' : 'Enabled'}
      </Badge>
    </div>
  );
}

export function KeyFormDialog({
  provider,
  org_id,
  forOrg,
  isOnboarding = false,
}) {
  const { sendAnalytics } = useAnalytics();

  const sendClickEvent = async (event, option) => {
    if (isOnboarding) {
      await sendAnalytics('onboarding_click_events', {
        page: 'llm_proxy',
        event: event,
        option: option,
        step: 1,
      });
    }
  };

  const disclosure = useDisclosure();
  const showSuccessToast = useSuccessfulToast();
  const { updateAPIKey } = useUserContext();

  const isAzure = provider.model_provider === 'azure';
  const isAws = provider.model_provider === 'aws';
  const isVertexAI = provider.model_provider === 'vertexai';
  const isOpenRouter = provider.model_provider === 'openrouter';
  const isLiteLlm = provider.model_provider === 'litellm';

  const items = OPENROUTER_MODELS.map((model) => ({
    value: model,
    label: model,
  }));

  const initialSelected = provider?.orDeployments;
  const [selectedItems, setSelectedItems] = useState(initialSelected);

  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();

  const handleCreateOrg = () => {
    onProfileOpen();
  };

  const [localAzure, setLocalAzure] = useState(
    isAzure && provider?.endpointToDeploymentsAndKey
      ? Object.entries(provider?.endpointToDeploymentsAndKey)?.map(
          ([endpoint, { deploymentNames, apiKey }]) => ({
            endpoint,
            deploymentNames: deploymentNames?.join(',') || '',
            apiKey,
          })
        )
      : [{ endpoint: '', deploymentNames: '', apiKey: '' }]
  );

  // Handle dynamic form fields for Azure endpoints and deployments
  const handleAzureChange = (index, event) => {
    setLocalAzure((prev) => {
      const name = event.target.name.split('-')[0];
      const newFormValues = [...prev];
      newFormValues[index] = {
        ...newFormValues[index],
        [name]: event.target.value,
      };
      return newFormValues;
    });
  };

  const addAzureEndpointsFields = (e) => {
    e.preventDefault();
    setLocalAzure((prev) => [
      ...prev,
      { endpoint: '', deploymentNames: '', apiKey: '' },
    ]);
  };

  const removeAzureEndpointsFields = (e, index) => {
    e.preventDefault();
    setLocalAzure((prev) => {
      const newFormValues = [...prev];
      newFormValues.splice(index, 1);
      return newFormValues;
    });
  };

  const onSubmit = async (data) => {
    if (isAzure) {
      data.endpointToDeploymentsAndKey =
        localAzure?.reduce((acc, curr) => {
          acc[curr.endpoint] = {
            deploymentNames: curr.deploymentNames
              .split(',')
              .map((dep) => dep.trim()),
            apiKey: curr.apiKey,
          };
          return acc;
        }, {}) || {};
    }

    let deploymentNames = null;
    if (isOpenRouter) {
      deploymentNames = selectedItems.join(',');
    } else if (isLiteLlm) {
      if (Array.isArray(data.deployments)) {
        deploymentNames = data.deployments.join(',');
      } else {
        deploymentNames = data.deployments;
      }
    }

    updateAPIKey(
      data.apikey,
      provider.model_provider,
      forOrg ? org_id : undefined,
      data?.endpointToDeploymentsAndKey,
      deploymentNames,
      data?.endpoint || null,
      data?.awsAccessKeyId || undefined,
      data?.awsRegion || undefined
    );

    showSuccessToast('API Key saved.');
    sendClickEvent('save_api_key', provider.model_provider);
    disclosure.onClose();
  };

  const cantSetup = forOrg && !org_id;

  const handleButtonClick = () => {
    if (cantSetup) {
      handleCreateOrg();
    } else {
      disclosure.onOpen();
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        className="ml-auto shrink-0"
        onClick={handleButtonClick}
      >
        {cantSetup ? 'First create a workspace' : 'Setup'}
      </Button>
      <CreateOrg
        isProfileOpen={isProfileOpen}
        onProfileClose={onProfileClose}
      />
      <FormDialog
        title={`${capitalize(provider.model_provider)} API Keys`}
        values={{
          apikey: provider.apikey || '',
          endpoint: provider?.endpoint || '',
          deployments: provider?.deployments || '',
          awsAccessKeyId: provider?.awsAccessKeyId || '',
          awsRegion: provider?.awsRegion || '',
          ...localAzure?.reduce((acc, curr, index) => {
            acc[`endpoint-${index}`] = curr.endpoint || '';
            acc[`apiKey-${index}`] = curr.apiKey || '';
            acc[`deploymentNames-${index}`] = curr.deploymentNames || '';
            return acc;
          }, {}),
        }}
        {...disclosure}
        onSubmit={onSubmit}
        size={'xl'}
      >
        {({ Field }) => (
          <FormLayout>
            {isAzure && (
              <AzureAccordion
                localAzure={localAzure}
                Field={Field}
                handleAzureChange={handleAzureChange}
                removeAzureEndpointsFields={removeAzureEndpointsFields}
                addAzureEndpointsFields={addAzureEndpointsFields}
              />
            )}
            {!isAws && !isVertexAI && !isAzure && !isLiteLlm && (
              <Field name="apikey" label="API Key" type="password" autoFocus />
            )}
            {isLiteLlm && (
              <div className="space-y-4">
                <Field
                  name="endpoint"
                  label="LiteLLM Proxy URL"
                  type="text"
                  autoFocus
                />
                <Field
                  name={`deployments`}
                  label="List of models in Proxy Config.yaml (comma separated)"
                  type="text"
                />
                <Field
                  name="apikey"
                  label="LiteLLM Master API Key (if specified in Proxy Config.yaml)"
                  type="password"
                  autoFocus
                />
                {/*</div>*/}
              </div>
            )}
            {isOpenRouter && (
              <div className="space-y-4 w-[528px]">
                <MultiSelectComponent
                  name="openRouterModels"
                  options={items}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  initialSelected={initialSelected}
                />
              </div>
            )}
            {isAws && (
              <div className="space-y-4">
                <Field
                  name="awsAccessKeyId"
                  label="Access Key ID"
                  type="text"
                />
                <Field
                  name="apikey"
                  label="Secret Access Key"
                  type="password"
                />
                <Field name="awsRegion" label="Region" type="text" />
              </div>
            )}
            {isVertexAI && (
              <div className="space-y-4">
                <div className="text-md ">
                  <a
                    href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                    style={{ textDecoration: 'underline', color: 'lightblue' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Create a service account key JSON file
                  </a>{' '}
                  and paste it below.
                </div>
                <div className="text-md text-gray-500"></div>
                <Field
                  name="apikey"
                  label="Service Account Key JSON"
                  type="textarea"
                  autoFocus
                />
              </div>
            )}
          </FormLayout>
        )}
      </FormDialog>
    </>
  );
}

export function APIKeyTabs() {
  return (
    <Tabs defaultValue="personal" className="w-full">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="personal">Personal</TabsTrigger>
        <TabsTrigger value="organization">Organization</TabsTrigger>
      </TabsList>
      <TabsContent value="personal">
        <APIKeySetupCard />
      </TabsContent>
      <TabsContent value="organization">
        <APIKeySetupCard forOrg={true} />
      </TabsContent>
    </Tabs>
  );
}

const AzureAccordion = ({
  localAzure,
  Field,
  handleAzureChange,
  removeAzureEndpointsFields,
  addAzureEndpointsFields,
}) => {
  return (
    <>
      <Accordion
        type="multiple"
        className="w-full"
        defaultValue={
          localAzure?.map((_, index) => `config-${index + 1}`) || []
        }
        collapsible
      >
        {localAzure?.map((input, index) => (
          <AccordionItem value={`config-${index + 1}`}>
            <AccordionTrigger>{`Configuration ${index + 1}`}</AccordionTrigger>
            <AccordionContent>
              <Card className="w-full" key={index}>
                <CardHeader>
                  {index === 0 && (
                    <CardDescription>
                      Each configuration should be for a unique endpoint URL.
                      Otherwise, you can provide multiple deployment names for
                      the same endpoint as comma separated values. e.g
                      "deploymentName1,deploymentName2"
                    </CardDescription>
                  )}
                </CardHeader>
                <CardContent>
                  <div className="grid w-full items-center gap-4">
                    <div className="flex flex-col space-y-1.5">
                      <Field
                        name={`endpoint-${index}`}
                        label="Endpoint URL"
                        onChange={(event) => handleAzureChange(index, event)}
                      />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Field
                        name={`apiKey-${index}`}
                        label="API Key"
                        type="password"
                        onChange={(event) => handleAzureChange(index, event)}
                      />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Field
                        name={`deploymentNames-${index}`}
                        label="Deployment Names (comma separated)"
                        onChange={(event) => handleAzureChange(index, event)}
                      />
                    </div>
                  </div>
                </CardContent>
                <CardFooter className="flex justify-between">
                  <Button
                    variant="destructive"
                    onClick={(e) => removeAzureEndpointsFields(e, index)}
                  >
                    Remove
                  </Button>
                </CardFooter>
              </Card>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      <Button onClick={addAzureEndpointsFields} className="mt-2">
        <PlusIcon className="w-5 h-5 mr-2" />
        Add More
      </Button>
    </>
  );
};

export default Settings;
