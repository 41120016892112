import { useColorModeValue, VStack } from '@chakra-ui/react';
import { SidebarSection } from '@saas-ui/react';
import React from 'react';
import { CreateOrg } from '../common/CreateOrgModal';
import { dark } from '@clerk/themes';
import { OrganizationSwitcher, SignedIn } from '../common/Auth';

export default function OrgSwitcherSection({
  isOpen,
  isProfileOpen,
  onProfileClose,
  organization_exists,
}) {
  const clerkTheme = useColorModeValue('default', dark);

  return (
    <SidebarSection direction={isOpen ? 'row' : 'column'}>
      <SignedIn>
        <VStack align="left" spacing={2} maxW="50px" paddingBottom={2}>
          {organization_exists && isOpen && (
            <OrganizationSwitcher
              hidePersonal={true}
              appearance={{
                baseTheme: clerkTheme,
                elements: {
                  organizationSwitcherTrigger: {
                    width: '175px',
                  },
                },
              }}
            />
          )}
        </VStack>
        {!organization_exists && (
          <CreateOrg
            isProfileOpen={isProfileOpen}
            onProfileClose={onProfileClose}
          />
        )}
      </SignedIn>
    </SidebarSection>
  );
}
