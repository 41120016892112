import { ErrorAlert } from './ErrorAlert';
import { ErrorBounderFallback } from './ErrorBoundary';
import { Page, PageBody } from '@saas-ui-pro/react';
import { LoadingSpinnerWrapper } from './LoadingSpinnerWrapper';
import { ErrorBoundary } from '@saas-ui/react';

export function NewPageBoxWrapper({
  loading,
  error,
  setError,
  loadingComponent = null,
  errorNoTimeout = null,
  setErrorNoTimeout = null,
  children,
}) {
  return (
    <ErrorBoundary fallback={<ErrorBounderFallback />}>
      <Page>
        <PageBody contentWidth="full">
          {loading && (loadingComponent ?? <LoadingSpinnerWrapper />)}
          {error && (
            <ErrorAlert title="Error" description={error} setError={setError} />
          )}
          {!error && errorNoTimeout && (
            <ErrorAlert
              title="Error"
              description={errorNoTimeout}
              setError={setErrorNoTimeout}
              errorTimeout={null}
            />
          )}
          {!loading && children}
        </PageBody>
      </Page>
    </ErrorBoundary>
  );
}
