import { useToast } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { useCallback } from 'react';

export const useSuccessfulToast = () => {
  const toast = useToast();

  return useCallback(
    (message, status = 'success', duration = 1800) => {
      toast({
        title: `${capitalize(status)}: ${message}`,
        status: status,
        duration: duration,
        isClosable: true,
        position: 'bottom-left',
      });
    },
    [toast]
  );
};
