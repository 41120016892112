import React, { useEffect, useMemo, useState } from 'react';
import StepCardWrapper from '../StepCardWrapper';
import LoadingAnimation from '../../../components/common/loadingAnimation';
import * as SuccessAnimation from '../../../assets/lottie/SuccessAnimation.json';
import * as Listening from '../../../assets/lottie/Listening.json';
import { useApiClient } from '../../../hooks/useApiClient';
import {
  DASHBOARD_ROUTE,
  EXPERIMENTS_ROUTE,
  hasAnyTraceLogsEndpoint,
  ONBOARDING_MEETING_ID,
} from '../../../utils/constants';
import { useAuthManager } from '../../../hooks/auth/useAuthManager';
import { useAnalytics } from '../../../hooks/usePageVisitedAnalytics';
import { PopupModal } from 'react-calendly';
import { useDisclosure } from '@chakra-ui/react';
import { Button } from '../../../components/ui/button';
import { CalendarIcon } from '@radix-ui/react-icons';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../../components/ui/card';
import useFetchProjects from '../../../hooks/project/useFetchProjects';

interface EventListenProps {
  previousStep: () => void;
  nextStep: () => void;
}

type HasTraceLogsResponse = {
  has_logs: boolean,
  has_experiments: boolean,
};

const EventListen = (props: EventListenProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { previousStep, nextStep } = props;

  useFetchProjects();

  const { getRequest } = useApiClient();
  const { user } = useAuthManager();
  const orgId = useMemo(
    () => user?.organizationMemberships?.[0]?.organization?.id,
    [user]
  );
  const { sendAnalytics } = useAnalytics();
  const [hasExperiment, setHasExperiment] = useState(false);
  const [hasLogs, setHasLogs] = useState(false);
  const data = useMemo(
    () => hasExperiment || hasLogs,
    [hasExperiment, hasLogs]
  );
  const [eventSent, setEventSent] = useState(false);
  const [waitTime, setWaitTime] = useState(0);
  const [startTime, setStartTime] = useState(null);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response: {
            data: HasTraceLogsResponse,
            status: number,
          } = await getRequest(hasAnyTraceLogsEndpoint(orgId));
          setHasExperiment(response.data.has_experiments);
          setHasLogs(response.data.has_logs);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      if (!data && startTime === null) {
        setStartTime(Date.now());
      }

      const intervalId = setInterval(() => {
        if (!data) {
          fetchData();
          if (startTime !== null) {
            const currentWaitTime = Math.floor((Date.now() - startTime) / 1000);
            setWaitTime(currentWaitTime);
          }
        } else if (startTime !== null) {
          const totalWaitTime = Math.floor((Date.now() - startTime) / 1000);
          setWaitTime(totalWaitTime);
          setStartTime(null); // Reset start time
        }
      }, 2000);

      // Clean up the interval when the component unmounts or when data is received
      return () => clearInterval(intervalId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, getRequest, orgId, startTime]
  );

  useEffect(
    () => {
      if (data && !eventSent) {
        sendAnalytics('onboarding_click_events', {
          page: 'listening_for_events',
          event: 'event_wait_time',
          wait_time: waitTime,
        });
        setEventSent(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, sendAnalytics, waitTime]
  );

  const handleBookDemo = () => {
    sendAnalytics('onboarding_click_events', {
      page: 'listening_for_events',
      event: 'book_demo_clicked',
    });
    onOpen();
  };

  return (
    <StepCardWrapper
      nextStep={() => {
        nextStep(hasExperiment ? EXPERIMENTS_ROUTE : DASHBOARD_ROUTE);
      }}
      previousStep={previousStep}
      title={'Fetching logs sent to Parea'}
      description={undefined}
      nextButtonText={'Finish Onboarding!'}
      isLoading={!data}
      isLastStep={true}
    >
      <React.Fragment>
        <div className="flex flex-col p-4 pb-16">
          {data ? (
            <>
              <LoadingAnimation
                animation={SuccessAnimation}
                height={200}
                width={200}
              />
              <p className="text-lg md:text-xl font-semibold text-center mt-4">
                Successfully received an event
              </p>
              <p className="text-md md:text-lg text-gray-500 font-light mt-5 text-center">
                You&apos;re all set to use Parea! Click next to see your logs
                and get started.
              </p>
              <div className="flex items-center justify-center gap-4 mt-4">
                <Card className="w-[350px]">
                  <CardHeader>
                    <CardTitle className="text-center">Book a Demo</CardTitle>
                  </CardHeader>
                  <CardContent className="text-center">
                    <p className="text-sm text-gray-500">
                      (Optional) If you want to chat more about how Parea can
                      meet your specific needs feel free to book a demo with us!
                    </p>
                  </CardContent>
                  <CardFooter className="justify-center">
                    <Button onClick={handleBookDemo}>
                      <CalendarIcon className="w-6 h-6 mr-2" />
                      Book
                    </Button>
                  </CardFooter>
                  <PopupModal
                    url={ONBOARDING_MEETING_ID}
                    onModalClose={onClose}
                    open={isOpen}
                    rootElement={document.getElementById('root')}
                  />
                </Card>
              </div>
            </>
          ) : (
            <>
              <p className="text-lg md:text-xl font-semibold text-center">
                Listening for Events
              </p>
              <p className="text-md md:text-lg text-gray-500 font-light mt-4 text-center">
                Send your first event to Parea to view your dashboard
              </p>

              <div className="flex flex-col w-full">
                <LoadingAnimation
                  animation={Listening}
                  height={200}
                  width={200}
                  style={{
                    pointerEvents: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    </StepCardWrapper>
  );
};

export default EventListen;
