import { useCallback, useEffect, useState } from 'react';
import { USER_SUMMARY_ENDPOINT } from '../utils/constants';
import { useApiClient } from './useApiClient';

type UserSummaryStats = {
  prompts: number,
  test_cases: number,
  functions: number,
  evaluations: number,
  trace_logs: number,
  experiments: number,
  deployments: number,
};

const EmptyUserSummaryStats: UserSummaryStats = {
  prompts: 0,
  test_cases: 0,
  functions: 0,
  evaluations: 0,
  trace_logs: 0,
  experiments: 0,
  deployments: 0,
};

export default function useFetchUsageStats(forMonth: boolean = false): {
  userSummaryStats: UserSummaryStats,
} {
  const { getRequest } = useApiClient();
  const [userSummaryStats, setUserSummaryStats] = useState(
    EmptyUserSummaryStats
  );

  const getUserSummaryStats = useCallback(async () => {
    try {
      const URL = forMonth
        ? `${USER_SUMMARY_ENDPOINT}/period`
        : USER_SUMMARY_ENDPOINT;
      const response = await getRequest(URL);
      if (response.status === 200) {
        setUserSummaryStats({
          prompts: response.data.prompts,
          test_cases: response.data.test_cases,
          functions: response.data.functions,
          evaluations: response.data.evaluations,
          trace_logs: response.data.trace_logs,
          experiments: response.data.experiments,
          deployments: response.data.deployments,
        });
      }
    } catch (error) {
      throw new Error(`Failed to get user summary stats: ${error}`);
    }
  }, [forMonth, getRequest]);

  useEffect(() => {
    async function getData() {
      await getUserSummaryStats();
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { userSummaryStats };
}
