import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  AUTH_SERVICE_URL,
  BACKEND_URL,
  SELF_HOSTED_ORGANIZATION_ID,
  SELF_HOSTED_ORGANIZATION_NAME,
} from '../utils/constants';
import { User } from '../hooks/auth/useAuthManager';
import { LoadingSpinnerWrapper } from '../components/common/LoadingSpinnerWrapper';

export type Session = {
  accessToken: string,
  user: {
    id: string,
    email: string,
    image: string,
  },
};

type GetTokenOptions = {
  skipCache?: boolean,
};

export type GetToken = (options?: GetTokenOptions) => Promise<string | null>;

type AuthContextValue = {
  isLoaded: boolean,
  isSignedIn: boolean,
  session: Session | null,
  getToken: GetToken,
  getUser: () => User | null,
  signOut: () => Promise<void>,
  setSession: (session: Session | null) => void,
  setIsSignedIn: (isSignedIn: boolean) => void,
  saveUser: () => Promise<void>,
};

export const SSOAuthContext: React.Context<AuthContextValue> = createContext({
  isLoaded: false,
  isSignedIn: false,
  session: null,
  getToken: async () => null,
  getUser: () => null,
  signOut: async () => {},
  setSession: () => {},
  setIsSignedIn: () => {},
  saveUser: async () => {},
});

export const SSOAuthProvider = ({
  children,
}: {
  children: React.ReactNode,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [session, setSession] = useState(null);

  const saveUser = async () => {
    if (!isSignedIn || !session?.accessToken || !session?.user?.id) {
      return;
    }

    try {
      await axios.post(
        `${BACKEND_URL}/api/user/selfhost/signup`,
        {
          user_id: session.user.id,
          organization_id: SELF_HOSTED_ORGANIZATION_ID,
          org_name: SELF_HOSTED_ORGANIZATION_NAME,
        },
        {
          headers: {
            Authorization: `Bearer ${session.accessToken}`,
            'X-XSRF-Token': session.accessToken,
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  useEffect(() => {
    const getSession = async () => {
      try {
        const storedSession = localStorage.getItem('session');
        if (storedSession) {
          const parsedSession = JSON.parse(storedSession);
          setSession(parsedSession);
          setIsSignedIn(true);
          setIsLoaded(true);
        } else {
          const res: { data: Session } = await axios.get(
            `${AUTH_SERVICE_URL}/api/auth/session`,
            {
              withCredentials: true,
            }
          );
          if (!res.data) {
            setIsSignedIn(false);
            window.location.href = `${AUTH_SERVICE_URL}/api/auth/signin`;
          } else {
            setSession(res.data);
            setIsSignedIn(true);
            localStorage.setItem('session', JSON.stringify(res.data));
            await saveUser();
            setIsLoaded(true);
          }
        }
      } catch (err) {
        setIsSignedIn(false);
        window.location.href = `${AUTH_SERVICE_URL}/api/auth/signin`;
      }
    };

    getSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getToken: GetToken = async (options = {}) => {
    return session?.accessToken || null;
  };

  const getUser = (): User | null => {
    if (session?.user) {
      return {
        id: session.user.id,
        name: session.user.email,
        fullName: session.user?.name || session.user.email,
        imageUrl: session.user?.image,
        primaryEmailAddress: { emailAddress: session.user?.email },
        organizationMemberships: [
          {
            organization: {
              id: SELF_HOSTED_ORGANIZATION_ID,
              name: SELF_HOSTED_ORGANIZATION_NAME,
            },
          },
        ],
      };
    }
    return null;
  };

  const signOut = async () => {
    try {
      await axios.get(`${AUTH_SERVICE_URL}/api/auth/signout`, {
        withCredentials: true,
        headers: { 'X-XSRF-Token': session?.accessToken },
      });
      setSession(null);
      setIsSignedIn(false);
      localStorage.removeItem('session');
    } catch (err) {
      console.error('Sign out failed', err);
    }
  };

  const value: AuthContextValue = {
    isLoaded,
    isSignedIn,
    session,
    getToken,
    getUser,
    signOut,
    setSession,
    setIsSignedIn,
    saveUser,
  };

  if (!isLoaded) {
    return <LoadingSpinnerWrapper text={'Loading user information'} />;
  }

  return (
    <SSOAuthContext.Provider value={value}>{children}</SSOAuthContext.Provider>
  );
};

export const useSSOAuth = () => useContext(SSOAuthContext);
