import { Select, SelectButton, SelectList } from '@saas-ui/react';

const MAX_DISPLAYED_ITEMS = 2; // You can change this to show more or fewer items

const renderSelectedItems = (selected) => {
  // Filter out any empty or undefined values first
  const validSelected = selected.filter(Boolean);

  if (validSelected.length <= MAX_DISPLAYED_ITEMS) {
    // If the number of selected items is less than or equal to the max, display them all
    return validSelected.join(', ');
  } else {
    // If more than the max, display the first few and then "+N more"
    const displayed = validSelected.slice(0, MAX_DISPLAYED_ITEMS).join(', ');
    const remainingCount = validSelected.length - MAX_DISPLAYED_ITEMS;
    return `${displayed} +${remainingCount} more`;
  }
};

const MultiSelectComponent = ({
  options,
  name,
  selectedItems,
  setSelectedItems,
  initialSelected,
}) => {
  const handleChange = (value) => {
    setSelectedItems(value);
  };

  return (
    <Select
      name={name}
      placeholder="Select your models"
      options={options}
      multiple
      value={selectedItems}
      renderValue={renderSelectedItems}
      onChange={handleChange}
      defaultValue={initialSelected}
    >
      <SelectButton />
      <SelectList maxH="400px" overflowY="auto" />
    </Select>
  );
};

export default MultiSelectComponent;
