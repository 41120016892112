import { Box, Button, Card, CardBody, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const ErrorBounderFallback = () => (
  <Box
    width={{ base: '100%', md: '90%', lg: '80%' }}
    marginX="auto"
    mt={8}
    px={4}
    mb={5}
  >
    <Card>
      <CardBody>
        <Text fontWeight="bold" fontSize="xl" mb={5}>
          Oops! There was an error.
        </Text>
        <Text>
          Our team has been notified, and we'll fix the problem as soon as
          possible.
        </Text>
      </CardBody>
    </Card>
    <Box>
      <BackToHomeButton />
    </Box>
  </Box>
);

function BackToHomeButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <Button mt={4} onClick={handleClick}>
      Go Back to Home Page
    </Button>
  );
}
