import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Analytics } from '@vercel/analytics/react';
import './index.css';
import { SpeedInsights } from '@vercel/speed-insights/react';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
    <SpeedInsights />
    <Analytics />
  </React.StrictMode>
);
