import {
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon, CopyIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { PUBLIC_API_KEY_ENDPOINT } from '../../utils/constants';
import { useApiClient } from '../../hooks/useApiClient';
import { useSuccessfulToast } from '../../hooks/useSuccessfulToast';
import { ErrorAlert } from '../common/ErrorAlert';
import { CreatePareaAPIKeyModal } from '../common/CreatePareaAPIKeyModal';
import { makeFormattedDate } from '../../utils/helpers';
import { CreateOrg } from '../common/CreateOrgModal';
import { ViewOffIcon } from '@saas-ui/react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { Button as ShadButton } from '../ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@tremor/react';
import { Card, CardContent, CardDescription, CardHeader } from '../ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { useAuthManager } from '../../hooks/auth/useAuthManager';

const PublicApiKey = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuthManager();
  const org_id = user?.organizationMemberships?.[0]?.organization?.id;
  const hasOrg = !!org_id;

  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();

  const [items, setItems] = useState([]);
  const [error, setError] = useState('');
  const { getRequest } = useApiClient();

  useEffect(() => {
    const fetchOrgAndAPIKeys = async () => {
      if (org_id) {
        try {
          const response = await getRequest(
            `${PUBLIC_API_KEY_ENDPOINT}/${org_id}`
          );
          if (response.status === 200) {
            for (const item of response.data) {
              item.created_at = makeFormattedDate(item.created_at);
            }
            setItems(response.data);
          }
        } catch (error) {
          setError(error.detail);
        }
      }
    };

    fetchOrgAndAPIKeys();
  }, [getRequest, org_id]);

  return (
    <>
      {error && (
        <ErrorAlert title="Error" description={error} setError={setError} />
      )}
      <Accordion
        type="single"
        collapsible
        className="w-full"
        defaultValue="api-keys"
      >
        <AccordionItem value="api-keys">
          <AccordionTrigger className="border rounded-md bg-tremor-background-muted dark:bg-dark-tremor-background-muted p-2 mb-2">
            Parea API Keys
          </AccordionTrigger>
          <AccordionContent>
            <Card>
              <CardHeader>
                <CardDescription>
                  Your secret API keys are listed below. Please note that we do
                  not display your secret API keys again after you generate
                  them. Do not share your API key with others, or expose it in
                  the browser or other client-side code. API keys allow you to
                  interact with Parea programmatically through our API and SDK.
                  <Link
                    ml={3}
                    color="blue.500"
                    href="https://docs.parea.ai/api-reference/authentication"
                    isExternal
                  >
                    <Text as="i" fontSize="sm">
                      Read our docs to learn more
                    </Text>
                  </Link>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {items?.length > 0 ? (
                    <APIKeyTable
                      org_id={org_id}
                      items={items}
                      setItem={setItems}
                      setError={setError}
                    />
                  ) : (
                    <>
                      {!hasOrg && (
                        <ShadButton size="sm" onClick={onProfileOpen}>
                          First create a workspace
                        </ShadButton>
                      )}
                      <CreateOrg
                        isProfileOpen={isProfileOpen}
                        onProfileClose={onProfileClose}
                      />
                    </>
                  )}
                  {hasOrg && (
                    <ShadButton size="sm" onClick={onOpen}>
                      <AddIcon className={'mr-2'} />
                      Create new API key
                    </ShadButton>
                  )}
                </div>
              </CardContent>
            </Card>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <CreatePareaAPIKeyModal
        onClose={onClose}
        isOpen={isOpen}
        org_id={org_id}
        setError={setError}
        setItems={setItems}
      />
    </>
  );
};

const APIKeyTable = ({ org_id, items, setItems, setError }) => {
  const { deleteRequest } = useApiClient();
  const showSuccessToast = useSuccessfulToast();

  const handleDelete = async (keyName) => {
    if (org_id) {
      try {
        await deleteRequest(PUBLIC_API_KEY_ENDPOINT, {
          name: keyName,
          organization_id: org_id,
        });
        showSuccessToast('API key deleted successfully');
        const newItems = items.filter((item) => item.name !== keyName);
        setItems(newItems);
      } catch (error) {
        setError(error.detail);
      }
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border bg-tremor-background-muted dark:bg-dark-tremor-background-muted">
            {['Name', 'Key', 'Created On', '...'].map((title) => (
              <HeaderCell key={title} title={title} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableUsageExample
              key={item.name}
              item={item}
              handleDelete={handleDelete}
            />
          ))}
        </TableBody>
      </Table>
      <Divider />
    </>
  );
};

const HeaderCell = ({ title }) => (
  <TableHeaderCell className="border-b border-tremor-border dark:border-dark-tremor-border">
    {title}
  </TableHeaderCell>
);

export function TableUsageExample({ item, handleDelete }) {
  const { handleCopy } = useCopyToClipboard();
  const [showKey, setShowKey] = useState(false);
  const api_key = item?.api_key;

  return (
    <TableRow key={item.name}>
      <TableCell>{item.name}</TableCell>
      <TableCell>{showKey ? api_key : item.key}</TableCell>
      <TableCell>{item.created_at}</TableCell>
      <TableCell>
        <ButtonGroup>
          {api_key && (
            <>
              <IconButton
                alignSelf="flex-start"
                icon={<CopyIcon />}
                aria-label="Copy"
                size="xs"
                onClick={() => handleCopy(api_key)}
              />
              <IconButton
                alignSelf="flex-start"
                icon={showKey ? <ViewIcon /> : <ViewOffIcon />}
                aria-label="View"
                size="xs"
                onClick={() => setShowKey(!showKey)}
              />
            </>
          )}
          <IconButton
            alignSelf="flex-start"
            icon={<DeleteIcon />}
            aria-label="Delete"
            size="xs"
            onClick={() => handleDelete(item.name)}
          />
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
}

export default PublicApiKey;
