import React, { createContext, useState } from 'react';
import type { DataContextValue } from '../utils/types';

const initialDataContext: DataContextValue = {
  fetchedPastVersions: null,
  fetchedSessionData: null,
  fetchedTestCaseCollectionData: null,
  fetchedEvalMetrics: null,
  fetchedEvalJobs: null,
  fetchedPideSessionsData: null,
  fetchedProjects: null,
};

export const DataContext = createContext({
  data: initialDataContext,
  setData: (value: DataContextValue) => {},
});

const DataProvider = (props) => {
  const [data, setData] = useState(initialDataContext);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {props.children}
    </DataContext.Provider>
  );
};

export default DataProvider;
