import { NavItem } from '@saas-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { HOMEPAGE } from '../../utils/constants';
import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';
import { SignedIn } from '../common/Auth';
import { useAuthManager } from '../../hooks/auth/useAuthManager';

export function LogoutNavWithAnalytics() {
  const { signOut } = useAuthManager();
  const posthog = usePostHog();

  const handleClickSignOut = useCallback(() => {
    posthog?.reset();
    signOut({ redirectUrl: HOMEPAGE });
  }, [signOut, posthog]);

  return (
    <SignedIn>
      <NavItem icon={<FiLogOut />} onClick={handleClickSignOut}>
        Logout
      </NavItem>
    </SignedIn>
  );
}
