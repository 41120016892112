import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { JUNE_KEY } from '../utils/constants';

export function useJune() {
  const [analytics, setAnalytics] = useState(undefined);

  useEffect(() => {
    const loadAnalytics = async () => {
      const response = AnalyticsBrowser.load({
        writeKey: JUNE_KEY,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, []);

  return analytics;
}
