import { useEffect, useMemo } from 'react';
import { INTERCOM_APP_ID } from '../../utils/constants';
import { useAuthManager } from '../../hooks/auth/useAuthManager';

const IntercomApp = () => {
  const { user } = useAuthManager();
  const intercomAppId = INTERCOM_APP_ID;

  const userInfo = useMemo(() => {
    return {
      name: user?.fullName,
      id: user?.id,
      email: user?.primaryEmailAddress?.emailAddress,
      createdAt: user?.createdAt,
    };
  }, [user]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    const intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: intercomAppId,
      name: userInfo.name,
      user_id: userInfo.id,
      email: userInfo.email,
      created_at: userInfo.createdAt,
    };
    window.intercomSettings = intercomSettings;

    const loadIntercom = () => {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', intercomSettings);
      } else {
        const i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = () => {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${intercomAppId}`;
          const x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else {
          w.attachEvent
            ? w.attachEvent('onload', l)
            : w.addEventListener('load', l, false);
        }
      }
    };

    loadIntercom();

    // Cleanup function to shut down Intercom when the component unmounts
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [intercomAppId, userInfo]); // Only re-run the effect if user details change

  return null; // Render nothing - Intercom works through the global `window.Intercom` object
};

export default IntercomApp;
