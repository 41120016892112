import React from 'react';
import * as SubframeCore from '@subframe/core';

interface OnboardingStepItemRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  selected?: boolean;
  completed?: boolean;
  children?: string;
  icon?: SubframeCore.IconName;
  className?: string;
}

const OnboardingStepItemRoot = React.forwardRef(function OnboardingStepItemRoot(
  {
    selected = false,
    completed = false,
    children,
    icon = 'FeatherListChecks',
    className,
    ...otherProps
  }: OnboardingStepItemRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'flex w-full cursor-pointer items-center gap-4 overflow-hidden rounded py-3 px-4 transition-all hover:bg-accent hover:text-accent-foreground',
        {
          'border-none': completed,
          'bg-accent text-accent-foreground': selected,
        },
        className
      )}
      ref={ref}
      {...otherProps}
    >
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          'text-subheader font-subheader text-default-font',
          { 'hidden text-subtext-color': completed, 'text-brand-700': selected }
        )}
        name={icon}
      />
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          'hidden text-body font-body text-black',
          {
            'inline-flex text-subheader font-subheader text-green-700':
              completed,
            hidden: selected,
          }
        )}
        name="FeatherCheck"
      />
      {children ? (
        <span
          className={SubframeCore.twClassNames(
            'grow shrink-0 basis-0 text-body-bold font-body-bold text-sm font-medium leading-none'
            // { 'text-default-font': completed, 'text-brand-700': selected }
          )}
        >
          {children}
        </span>
      ) : null}
    </div>
  );
});

export const OnboardingStepItem = OnboardingStepItemRoot;
