import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spacer,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { useApiClient } from '../../hooks/useApiClient';
import { useSuccessfulToast } from '../../hooks/useSuccessfulToast';
import { PUBLIC_API_KEY_ENDPOINT } from '../../utils/constants';
import { CopyIcon } from '@chakra-ui/icons';
import { makeFormattedDate } from '../../utils/helpers';

export const NewAPIKey = ({ org_id, setError, setItem, setItems }) => {
  const [keyName, setKeyName] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const { onCopy, hasCopied } = useClipboard(secretKey);
  const { postRequest } = useApiClient();
  const showSuccessToast = useSuccessfulToast();

  const generateAPIKey = async (keyName) => {
    if (org_id) {
      try {
        const response = await postRequest(PUBLIC_API_KEY_ENDPOINT, {
          name: keyName,
          organization_id: org_id,
        });
        if (response.status === 200) {
          showSuccessToast('API key created successfully');
          return response.data;
        }
      } catch (error) {
        setError(error.detail);
        return null;
      }
    } else {
      setError('You need to create an organization first');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const secretKeyResponse = await generateAPIKey(keyName);
    setSecretKey(secretKeyResponse.key);
    const newPubKey = {
      name: keyName,
      key: `pai...${secretKeyResponse.key.slice(-4)}`,
      created_at: makeFormattedDate(secretKeyResponse.created_at),
    };
    setItem(newPubKey);
    setItems((prevItems) => [...prevItems, newPubKey]);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {!secretKey && (
          <VStack spacing={3} align="stretch" justify="left">
            <FormControl>
              <FormLabel>Provide a name for your key</FormLabel>
              <Input
                type="text"
                isRequired
                value={keyName}
                onChange={(event) => setKeyName(event.target.value)}
              />
            </FormControl>
            <Spacer />
            <Button type="submit">Submit</Button>
          </VStack>
        )}
        {secretKey && (
          <VStack spacing={3} align="stretch" justify="left">
            <Text whiteSpace={'pre-wrap'} size={'sm'}>
              Please save this secret key somewhere safe and accessible. For
              security reasons, you won't be able to view it again through your
              Parea account. If you lose this secret key, you'll need to
              generate a new one.
            </Text>
            <Spacer />
            <FormControl>
              <FormLabel>New api key created</FormLabel>
              <Input isReadOnly value={secretKey} />
            </FormControl>
            <Button onClick={onCopy} display="flex" alignItems="center">
              {hasCopied ? 'Copied' : 'Copy'}
              <CopyIcon />
            </Button>
          </VStack>
        )}
      </form>
    </Box>
  );
};
