import React, { useCallback, useEffect } from 'react';
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { ReloadIcon } from '@radix-ui/react-icons';
import { Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from '../../hooks/usePageVisitedAnalytics';

interface StepCardWrapperProps {
  title: string;
  description?: string;
  nextStep: () => void;
  previousStep?: () => void;
  isLoading: boolean;
  nextButtonText?: string;
  backButtonText?: string;
  isLastStep?: boolean;
  children: React.ReactNode;
}

const StepCardWrapper = ({
  title,
  description,
  nextStep,
  previousStep,
  isLoading,
  nextButtonText = 'Next',
  backButtonText = 'Back',
  isLastStep = false,
  children,
}: StepCardWrapperProps) => {
  const navigate = useNavigate();
  const { sendAnalytics } = useAnalytics();

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // if (isEditingInput) return; // Don't trigger shortcuts if editing name

      if (event.metaKey && event.key === 'ArrowRight') {
        event.preventDefault();
        nextStep(event);
      } else if (event.metaKey && event.key === 'ArrowLeft' && previousStep) {
        event.preventDefault();
        previousStep();
      }
    },
    [nextStep, previousStep]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleSkipOnboarding = async () => {
    await sendAnalytics('onboarding', {
      step: 4,
      skipped: true,
      onboarding_completed: false,
    });
    localStorage.setItem('onboarding_seen', 'true');
    navigate('/');
  };

  return (
    <Card
      id="content"
      className="w-full h-full flex flex-col min-h-[400px] pr-12 sm:min-h-[100px]"
    >
      <CardHeader className="pb-4">
        <CardTitle>{title}</CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent className="flex-grow grid gap-3 max-h-[775px] overflow-y-auto">
        <div className="flex flex-col space-y-8 w-full">{children}</div>
      </CardContent>
      <CardFooter className="mt-auto flex w-full items-center justify-end gap-2">
        {previousStep && (
          <Tooltip
            hasArrow
            placement="top-start"
            label={'[⌘ + ←]'}
            defaultIsOpen
          >
            <Button variant={'secondary'} onClick={() => previousStep()}>
              <ArrowLeft className="h-4 w-4 mr-2" />
              {backButtonText}
            </Button>
          </Tooltip>
        )}
        {isLastStep && (
          <Button variant={'outline'} onClick={handleSkipOnboarding}>
            Skip waiting for events
          </Button>
        )}
        <Tooltip
          hasArrow
          isDisabled={isLoading}
          label={'[⌘ + →]'}
          placement="top-end"
          defaultIsOpen
        >
          <Button onClick={nextStep} variant="green" disabled={isLoading}>
            {isLoading ? (
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <>
                {nextButtonText}
                <ArrowRight className="h-3 w-3 ml-2" />
              </>
            )}
          </Button>
        </Tooltip>
      </CardFooter>
    </Card>
  );
};

export default StepCardWrapper;
