import { BrowserRouter, useNavigate } from 'react-router-dom';
import Layout from './components/layout/Layout';
import {
  CLERK_PUB_KEY,
  ENABLE_ANALYTICS,
  ENABLE_CLERK_AUTHENTICATION,
} from './utils/constants';
import { UserContextProvider } from './store/UserContext';
import AppRoutes from './Routes';
import { enableMapSet } from 'immer';
import { ClerkAuthProvider } from '@saas-ui/clerk';
import { ColorModeScript, extendTheme } from '@chakra-ui/react';
import { baseTheme, ModalsProvider, SaasProvider } from '@saas-ui/react';
import { ThemeProvider } from './components/layout/theme-provider';
import DataProvider from './store/DataContext';
import React, { Suspense, useEffect } from 'react';
import { TooltipProvider } from './components/ui/tooltip';
import AppRoutesWithAnalytics from './RoutesWithAnalytics';
import { initializeSentry } from './utils/initializeSentry';
import { SSOAuthProvider } from './store/SSOAuthContext';

const config = { initialColorMode: 'dark', useSystemColorMode: false };
const theme = extendTheme({ config }, baseTheme);

enableMapSet();

function AuthProviderWithRoutes({ children }) {
  const navigate = useNavigate();

  const afterSignInUrl =
    '/' + window.location.href.split('/').slice(3).join('/');

  return (
    <>
      {ENABLE_CLERK_AUTHENTICATION === true ? (
        <ClerkAuthProvider
          publishableKey={CLERK_PUB_KEY}
          navigate={(to) => navigate(to)}
          afterSignInUrl={afterSignInUrl}
          afterSignUpUrl={afterSignInUrl}
        >
          {() => <ProviderWrapper>{children}</ProviderWrapper>}
        </ClerkAuthProvider>
      ) : (
        <SSOAuthProvider>
          <ProviderWrapper>{children}</ProviderWrapper>
        </SSOAuthProvider>
      )}
    </>
  );
}

function ProviderWrapper({ children }) {
  return (
    <SaasProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <UserContextProvider>
        <ModalsProvider>
          <DataProvider>
            <TooltipProvider>
              <Layout>{children}</Layout>
            </TooltipProvider>
          </DataProvider>
        </ModalsProvider>
      </UserContextProvider>
    </SaasProvider>
  );
}

export default function App() {
  useEffect(() => {
    if (ENABLE_ANALYTICS === true) {
      // For posthog.
      // survey will only pop up after user has stayed around for at least 5 minutes, by pass onboarding and other sign up activities
      setTimeout(() => {
        const h1 = document.querySelector('h1');
        if (h1) {
          h1.classList.add('delayed-survey');
        }
      }, 300000);

      initializeSentry();
    }
  }, []);

  return (
    <ThemeProvider
      defaultTheme={theme.config.initialColorMode}
      storageKey="ui-theme"
    >
      <BrowserRouter>
        <Suspense>
          <AuthProviderWithRoutes>
            {ENABLE_ANALYTICS === true ? (
              <AppRoutesWithAnalytics />
            ) : (
              <AppRoutes />
            )}
          </AuthProviderWithRoutes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}
