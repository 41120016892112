import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApiClient } from '../hooks/useApiClient';
import {
  USER_API_KEY_ENDPOINT,
  USER_API_KEYS_ENDPOINT,
  USER_CREDITS_ENDPOINT,
  USER_ORG_API_KEYS_ENDPOINT,
} from '../utils/constants';
import { useAuthManager } from '../hooks/auth/useAuthManager';

const initialUserContextValue = {
  creditsUsed: 0,
  addedCredits: 0,
  isStripeCustomer: false,
  // Personal level
  openAIKey: '',
  anthropicApiKey: '',
  azureOpenAI: {
    // '': {
    //   apiKey: '',
    //   deploymentNames: [],
    // },
  },
  azureOpenAIKey: '',
  azureOpenAIEndpoint: '',
  azureOpenAIDeploymentNames: [],
  anyscaleApiKey: '',
  awsAccessKeyId: '',
  awsSecretAccessKey: '',
  awsRegion: '',
  vertexAIJson: '',
  openRouterKey: '',
  openRouterDeploymentNames: [],
  mistralApiKey: '',
  liteLlmApiKey: '',
  liteLlmEndpoint: '',
  liteLlmDeploymentNames: [],
  // Organization level
  orgOpenAIKey: '',
  orgAnthropicApiKey: '',
  orgAzureOpenAI: {
    // '': {
    //   apiKey: '',
    //   deploymentNames: [],
    // },
  },
  orgOpenRouterKey: '',
  orgOpenRouterDeploymentNames: [],
  orgAnyscaleApiKey: '',
  orgAwsAccessKeyId: '',
  orgAwsSecretAccessKey: '',
  orgAwsRegion: '',
  orgVertexAIJson: '',
  orgMistralApiKey: '',
  orgLiteLlmApiKey: '',
  orgLiteLlmEndpoint: '',
  orgLiteLlmDeploymentNames: [],
  groqApiKey: '',
  orgGroqApiKey: '',
  fireworksApiKey: '',
  orgFireworksApiKey: '',
  setCredits: () => {},
  subscriptionPlan: '',
};

const UserContext = createContext(initialUserContextValue);

export function useUserContext() {
  return useContext(UserContext);
}

export function UserContextProvider({ children }) {
  const { getToken, user } = useAuthManager();
  const { getRequest, postRequest } = useApiClient();

  const [userCredits, setUserCredits] = useState({
    creditsUsed: initialUserContextValue.creditsUsed,
    addedCredits: initialUserContextValue.addedCredits,
    isStripeCustomer: initialUserContextValue.isStripeCustomer,
    subscriptionPlan: initialUserContextValue.subscriptionPlan,
  });

  // Personal level
  const [openAIKey, setOpenAIKey] = useState(initialUserContextValue.openAIKey);
  const [anthropicApiKey, setAnthropicApiKey] = useState(
    initialUserContextValue.anthropicApiKey
  );
  const [azureOpenAI, setAzureOpenAI] = useState(
    initialUserContextValue.azureOpenAI
  );
  const [anyscaleApiKey, setAnyscaleApiKey] = useState(
    initialUserContextValue.anyscaleApiKey
  );
  const [mistralApiKey, setMistralApiKey] = useState(
    initialUserContextValue.mistralApiKey
  );
  const [awsAccessKeyId, setAwsAccessKeyId] = useState(
    initialUserContextValue.awsAccessKeyId
  );
  const [awsSecretAccessKey, setAwsSecretAccessKey] = useState(
    initialUserContextValue.awsSecretAccessKey
  );
  const [awsRegion, setAwsRegion] = useState(initialUserContextValue.awsRegion);
  const [vertexAIJson, setVertexAIJson] = useState(
    initialUserContextValue.vertexAIJson
  );
  const [openRouterKey, setOpenRouterKey] = useState(
    initialUserContextValue.openRouterKey
  );
  const [openRouterDeploymentNames, setOpenRouterDeploymentNames] = useState(
    initialUserContextValue.openRouterDeploymentNames
  );
  const [liteLlmApiKey, setLiteLlmApiKey] = useState(
    initialUserContextValue.liteLlmApiKey
  );
  const [liteLlmEndpoint, setLiteLlmEndpoint] = useState(
    initialUserContextValue.liteLlmEndpoint
  );
  const [liteLlmDeploymentNames, setLiteLlmDeploymentNames] = useState(
    initialUserContextValue.liteLlmDeploymentNames
  );
  const [groqApiKey, setGroqApiKey] = useState(
    initialUserContextValue.groqApiKey
  );
  const [fireworksApiKey, setFireworksApiKey] = useState(
    initialUserContextValue.fireworksApiKey
  );

  // Organization level
  const [orgOpenAIKey, setOrgOpenAIKey] = useState(
    initialUserContextValue.orgOpenAIKey
  );
  const [orgAnthropicApiKey, setOrgAnthropicApiKey] = useState(
    initialUserContextValue.orgAnthropicApiKey
  );
  const [orgAzureOpenAI, setOrgAzureOpenAI] = useState(
    initialUserContextValue.orgAzureOpenAI
  );
  const [orgAnyscaleApiKey, setOrgAnyscaleApiKey] = useState(
    initialUserContextValue.orgAnyscaleApiKey
  );
  const [orgAwsAccessKeyId, setOrgAwsAccessKeyId] = useState(
    initialUserContextValue.orgAwsAccessKeyId
  );
  const [orgAwsSecretAccessKey, setOrgAwsSecretAccessKey] = useState(
    initialUserContextValue.orgAwsSecretAccessKey
  );
  const [orgAwsRegion, setOrgAwsRegion] = useState(
    initialUserContextValue.orgAwsRegion
  );
  const [orgVertexAIJson, setOrgVertexAIJson] = useState(
    initialUserContextValue.orgVertexAIJson
  );
  const [orgOpenRouterKey, setOrgOpenRouterKey] = useState(
    initialUserContextValue.orgOpenRouterKey
  );
  const [orgMistralApiKey, setOrgMistralApiKey] = useState(
    initialUserContextValue.orgMistralApiKey
  );
  const [orgOpenRouterDeploymentNames, setOrgOpenRouterDeploymentNames] =
    useState(initialUserContextValue.orgOpenRouterDeploymentNames);
  const [orgLiteLlmApiKey, setOrgLiteLlmApiKey] = useState(
    initialUserContextValue.orgLiteLlmApiKey
  );
  const [orgLiteLlmEndpoint, setOrgLiteLlmEndpoint] = useState(
    initialUserContextValue.orgLiteLlmEndpoint
  );
  const [orgLiteLlmDeploymentNames, setOrgLiteLlmDeploymentNames] = useState(
    initialUserContextValue.orgLiteLlmDeploymentNames
  );
  const [orgGroqApiKey, setOrgGroqApiKey] = useState(
    initialUserContextValue.orgGroqApiKey
  );
  const [orgFireworksApiKey, setOrgFireworksApiKey] = useState(
    initialUserContextValue.orgFireworksApiKey
  );

  const fetched_org_id = user?.organizationMemberships?.[0]?.organization?.id;

  const keySetters = {
    openai: setOpenAIKey,
    anthropic: setAnthropicApiKey,
    anyscale: setAnyscaleApiKey,
    aws: setAwsSecretAccessKey,
    vertexai: setVertexAIJson,
    openrouter: setOpenRouterKey,
    mistral: setMistralApiKey,
    liteLlm: setLiteLlmApiKey,
    groq: setGroqApiKey,
    fireworks: setFireworksApiKey,
  };
  const orgKeySetters = {
    openai: setOrgOpenAIKey,
    anthropic: setOrgAnthropicApiKey,
    anyscale: setOrgAnyscaleApiKey,
    aws: setOrgAwsSecretAccessKey,
    vertexai: setOrgVertexAIJson,
    openrouter: setOrgOpenRouterKey,
    mistral: setOrgMistralApiKey,
    liteLlm: setOrgLiteLlmApiKey,
    groq: setOrgGroqApiKey,
    fireworks: setOrgFireworksApiKey,
  };
  const azureSetter = setAzureOpenAI;
  const orgAzureSetter = setOrgAzureOpenAI;
  const awsSpecificSetters = {
    aws_access_key_id: setAwsAccessKeyId,
    aws_region: setAwsRegion,
  };
  const orgAwsSpecificSetters = {
    aws_access_key_id: setOrgAwsAccessKeyId,
    aws_region: setOrgAwsRegion,
  };
  const openRouterSpecificSetters = {
    deployment_names: setOpenRouterDeploymentNames,
  };
  const orgOpenRouterSpecificSetters = {
    deployment_names: setOrgOpenRouterDeploymentNames,
  };
  const liteLlmSpecificSetters = {
    endpoint: setLiteLlmEndpoint,
    deployment_names: setLiteLlmDeploymentNames,
  };
  const orgLiteLlmSpecificSetters = {
    endpoint: setOrgLiteLlmEndpoint,
    deployment_names: setOrgLiteLlmDeploymentNames,
  };

  const processAPIKeys = (
    setters,
    azureSetter,
    awsSetters,
    openRouterSetters,
    liteLlmSetters,
    modelProvider
  ) => {
    const keySetter = setters[modelProvider.model_provider];

    if (keySetter) keySetter(modelProvider.api_key);

    if (modelProvider.model_provider === 'azure') {
      azureSetter((prevValue) => ({
        ...prevValue,
        [modelProvider.endpoint]: {
          apiKey: modelProvider.api_key,
          deploymentNames: modelProvider.deployment_names,
        },
      }));
    } else if (modelProvider.model_provider === 'aws') {
      Object.keys(awsSetters).forEach((key) => {
        if (modelProvider[key]) awsSetters[key](modelProvider[key]);
      });
    } else if (modelProvider.model_provider === 'openrouter') {
      Object.keys(openRouterSetters).forEach((key) => {
        if (modelProvider[key]) openRouterSetters[key](modelProvider[key]);
      });
    } else if (modelProvider.model_provider === 'litellm') {
      Object.keys(liteLlmSetters).forEach((key) => {
        if (modelProvider[key]) liteLlmSetters[key](modelProvider[key]);
      });
    }
  };

  const createUserKeysProcessor =
    (setters, azureSetters, awsSetters, openRouterSetters, liteLlmSetters) =>
    (modelProvider) =>
      processAPIKeys(
        setters,
        azureSetters,
        awsSetters,
        openRouterSetters,
        liteLlmSetters,
        modelProvider
      );

  const processUserKeys = createUserKeysProcessor(
    keySetters,
    azureSetter,
    awsSpecificSetters,
    openRouterSpecificSetters,
    liteLlmSpecificSetters
  );
  const processOrgKeys = createUserKeysProcessor(
    orgKeySetters,
    orgAzureSetter,
    orgAwsSpecificSetters,
    orgOpenRouterSpecificSetters,
    orgLiteLlmSpecificSetters
  );

  const setCredits = useCallback(async () => {
    try {
      const response = await getRequest(USER_CREDITS_ENDPOINT);
      if (response.status === 200) {
        setUserCredits((prevState) => ({
          ...prevState,
          creditsUsed: response.data.credits_used,
          addedCredits: response.data.credits_added,
          isStripeCustomer: response.data.has_stripe_customer,
          subscriptionPlan:
            response.data.subscription_plan || prevState.subscriptionPlan,
        }));
      }
    } catch (error) {
      throw new Error(`Failed to refresh credits: ${error}`);
    }
  }, [getRequest]);

  const fetchAPIKeys = useCallback(async () => {
    try {
      const [response, orgResponse] = await Promise.all([
        getRequest(USER_API_KEYS_ENDPOINT),
        postRequest(USER_ORG_API_KEYS_ENDPOINT, {
          organization_id: fetched_org_id,
        }),
      ]);
      response.data.forEach((modelProvider) => processUserKeys(modelProvider));
      orgResponse.data.forEach((modelProvider) =>
        processOrgKeys(modelProvider)
      );
    } catch (error) {
      throw new Error(`Failed to fetch API keys: ${error}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest, fetched_org_id]);

  useEffect(() => {
    async function executeOnlyWhenSignedIn() {
      const token = await getToken();
      if (token !== null) {
        await setCredits();
        await fetchAPIKeys();
      }
    }

    executeOnlyWhenSignedIn();
  }, [fetchAPIKeys, getToken, setCredits]);

  const updateAPIKey = useCallback(
    async (
      key,
      model_provider,
      org_id = null,
      azureEndpointToDeploymentsAndKey = null,
      deploymentNamesOpenRouterLiteLLM = null,
      liteLlmEndpoint = null,
      awsAccessKeyId = null,
      awsRegion = null
    ) => {
      try {
        let deploymentNamesArray = null;
        if (deploymentNamesOpenRouterLiteLLM) {
          deploymentNamesArray = deploymentNamesOpenRouterLiteLLM
            .split(',')
            .map((name) => name.trim())
            .filter((name) => name !== '');
        }
        let request = [];

        if (model_provider === 'azure') {
          request =
            Object.keys(azureEndpointToDeploymentsAndKey || {})?.length > 0
              ? Object.entries(azureEndpointToDeploymentsAndKey).map(
                  ([endpoint, { apiKey, deploymentNames }]) => ({
                    model_provider,
                    api_key: apiKey,
                    organization_id: org_id,
                    endpoint,
                    deployment_names: deploymentNames,
                  })
                )
              : [
                  {
                    model_provider,
                    api_key: '',
                    organization_id: org_id,
                    endpoint: null,
                    deployment_names: [],
                  },
                ];
        } else {
          request = [
            {
              model_provider,
              api_key: key,
              organization_id: org_id,
              endpoint: liteLlmEndpoint,
              deployment_names: deploymentNamesArray,
              aws_access_key_id:
                model_provider === 'aws' ? awsAccessKeyId : null,
              aws_region: model_provider === 'aws' ? awsRegion : null,
            },
          ];
        }
        await postRequest(USER_API_KEY_ENDPOINT, request);

        switch (model_provider) {
          case 'openai':
            org_id ? setOrgOpenAIKey(key) : setOpenAIKey(key);
            break;
          case 'anthropic':
            org_id ? setOrgAnthropicApiKey(key) : setAnthropicApiKey(key);
            break;
          case 'openrouter':
            if (org_id) {
              setOrgOpenRouterKey(key);
              setOrgOpenRouterDeploymentNames(deploymentNamesArray);
            } else {
              setOpenRouterKey(key);
              setOpenRouterDeploymentNames(deploymentNamesArray);
            }
            break;
          case 'azure':
            if (org_id) {
              setOrgAzureOpenAI(azureEndpointToDeploymentsAndKey);
            } else {
              setAzureOpenAI(azureEndpointToDeploymentsAndKey);
            }
            break;
          case 'anyscale':
            org_id ? setOrgAnyscaleApiKey(key) : setAnyscaleApiKey(key);
            break;
          case 'mistral':
            org_id ? setOrgMistralApiKey(key) : setMistralApiKey(key);
            break;
          case 'aws':
            if (org_id) {
              setOrgAwsSecretAccessKey(key);
              setOrgAwsAccessKeyId(awsAccessKeyId);
              setOrgAwsRegion(awsRegion);
            } else {
              setAwsSecretAccessKey(key);
              setAwsAccessKeyId(awsAccessKeyId);
              setAwsRegion(awsRegion);
            }
            break;
          case 'vertexai':
            org_id ? setOrgVertexAIJson(key) : setVertexAIJson(key);
            break;
          case 'litellm':
            if (org_id) {
              setOrgLiteLlmApiKey(key);
              setOrgLiteLlmEndpoint(liteLlmEndpoint);
              setOrgLiteLlmDeploymentNames(deploymentNamesArray);
            } else {
              setLiteLlmApiKey(key);
              setLiteLlmEndpoint(liteLlmEndpoint);
              setLiteLlmDeploymentNames(deploymentNamesArray);
            }
            break;
          case 'groq':
            org_id ? setOrgGroqApiKey(key) : setGroqApiKey(key);
            break;
          case 'fireworks':
            org_id ? setOrgFireworksApiKey(key) : setFireworksApiKey(key);
            break;
          default:
            break;
        }
      } catch (error) {
        throw new Error(`Failed to update ${model_provider} key: ${error}`);
      }
    },

    [postRequest]
  );

  const value = useMemo(() => {
    return {
      ...userCredits,
      openAIKey,
      orgOpenAIKey,
      anthropicApiKey,
      orgAnthropicApiKey,
      mistralApiKey,
      orgMistralApiKey,
      azureOpenAI,
      orgAzureOpenAI,
      openRouterKey,
      orgOpenRouterKey,
      openRouterDeploymentNames,
      orgOpenRouterDeploymentNames,
      anyscaleApiKey,
      orgAnyscaleApiKey,
      awsAccessKeyId,
      awsSecretAccessKey,
      awsRegion,
      orgAwsAccessKeyId,
      orgAwsSecretAccessKey,
      orgAwsRegion,
      vertexAIJson,
      orgVertexAIJson,
      liteLlmApiKey,
      orgLiteLlmApiKey,
      liteLlmEndpoint,
      orgLiteLlmEndpoint,
      liteLlmDeploymentNames,
      orgLiteLlmDeploymentNames,
      groqApiKey,
      orgGroqApiKey,
      fireworksApiKey,
      orgFireworksApiKey,
      updateAPIKey,
      setCredits,
    };
  }, [
    userCredits,
    openAIKey,
    orgOpenAIKey,
    anthropicApiKey,
    orgAnthropicApiKey,
    mistralApiKey,
    orgMistralApiKey,
    azureOpenAI,
    orgAzureOpenAI,
    openRouterKey,
    orgOpenRouterKey,
    openRouterDeploymentNames,
    orgOpenRouterDeploymentNames,
    anyscaleApiKey,
    orgAnyscaleApiKey,
    awsAccessKeyId,
    awsSecretAccessKey,
    awsRegion,
    orgAwsAccessKeyId,
    orgAwsSecretAccessKey,
    orgAwsRegion,
    vertexAIJson,
    orgVertexAIJson,
    liteLlmApiKey,
    orgLiteLlmApiKey,
    liteLlmEndpoint,
    orgLiteLlmEndpoint,
    liteLlmDeploymentNames,
    orgLiteLlmDeploymentNames,
    groqApiKey,
    orgGroqApiKey,
    fireworksApiKey,
    orgFireworksApiKey,
    updateAPIKey,
    setCredits,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
