import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { LoadingSpinnerWrapper } from './LoadingSpinnerWrapper';

export const ModalView = ({
  children,
  header_text,
  isOpen,
  onClose,
  footerChildren,
  compact = false,
  size = null,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={size || { base: 'xl', md: '6xl' }}
      blockScrollOnMount={false}
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        {header_text && (
          <ModalHeader textAlign="center">{header_text}</ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody p={compact ? null : 5} mt={compact ? null : 7}>
          <Suspense fallback={<LoadingSpinnerWrapper />}>{children}</Suspense>
        </ModalBody>
        {footerChildren && (
          <ModalFooter display="flex" justifyContent="flex-start">
            {footerChildren}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
