import {
  AUTH_SERVICE_URL,
  ENABLE_CLERK_AUTHENTICATION,
} from '../../utils/constants';
import {
  CreateOrganization as ClerkCreateOrganization,
  OrganizationSwitcher as ClerkOrganizationSwitcher,
  SignedIn as ClerkSignedIn,
  SignedOut as ClerkSignedOut,
  SignIn as ClerkSignIn,
  SignUp as ClerkSignUp,
} from '@clerk/clerk-react';
import SSOSignedIn from './SSOAuth/SSOSignedIn';
import SSOSignedOut from './SSOAuth/SSOSignedOut';

export const SignedIn = ({ children }) => {
  if (ENABLE_CLERK_AUTHENTICATION) {
    return <ClerkSignedIn>{children}</ClerkSignedIn>;
  }
  return <SSOSignedIn>{children}</SSOSignedIn>;
};

export const SignedOut = ({ children }) => {
  if (ENABLE_CLERK_AUTHENTICATION) {
    return <ClerkSignedOut>{children}</ClerkSignedOut>;
  }
  return <SSOSignedOut>{children}</SSOSignedOut>;
};

export const SignIn = ({ children }) => {
  if (ENABLE_CLERK_AUTHENTICATION) {
    return <ClerkSignIn>{children}</ClerkSignIn>;
  } else {
    window.location.href = `${AUTH_SERVICE_URL}/api/auth/signin`;
  }
};

export const SignUp = ({ children }) => {
  if (ENABLE_CLERK_AUTHENTICATION) {
    return <ClerkSignUp>{children}</ClerkSignUp>;
  } else {
    window.location.href = `${AUTH_SERVICE_URL}/api/auth/signin`;
  }
};

export const CreateOrganization = ({
  afterCreateOrganizationUrl,
  skipInvitationScreen,
  children,
}) => {
  if (ENABLE_CLERK_AUTHENTICATION)
    return (
      <ClerkCreateOrganization
        skipInvitationScreen={skipInvitationScreen}
        afterCreateOrganizationUrl={afterCreateOrganizationUrl}
      >
        {children}
      </ClerkCreateOrganization>
    );

  return <>{children}</>;
};

export const OrganizationSwitcher = ({
  children,
  hidePersonal,
  appearance,
}) => {
  if (ENABLE_CLERK_AUTHENTICATION)
    return (
      <ClerkOrganizationSwitcher
        hidePersonal={hidePersonal}
        appearance={appearance}
      >
        {children}
      </ClerkOrganizationSwitcher>
    );

  return <>{children}</>;
};
