import { useCallback, useContext, useEffect, useState } from 'react';
import {
  LAB_GET_SESSIONS_ENDPOINT,
  STUDIO_ENDPOINT,
  TEST_CASES_GET_COLLECTIONS_ENDPOINT,
} from '../utils/constants';
import { useApiClient } from './useApiClient';
import { DataContext } from '../store/DataContext';
import { useProjectAPIEndpoints } from './project/useProjectAPIEndpoints';
import { useAuthManager } from './auth/useAuthManager';

export const useAppEntryDataFetching = () => {
  const { setData } = useContext(DataContext);
  const { getToken } = useAuthManager();

  const { getRequest } = useApiClient();
  const [loading, setLoading] = useState(false);
  const { getProjects } = useProjectAPIEndpoints();

  const [token, setToken] = useState(null);

  const Token = useCallback(async () => {
    const _token = await getToken();
    if (_token !== null) {
      setToken(_token);
    }
  }, [getToken]);

  useEffect(() => {
    Token();
  }, [Token]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const [
        pastVersionsResponse,
        sessionResponse,
        testCollectionsResponse,
        projectsResponse,
      ] = await Promise.all([
        getRequest(STUDIO_ENDPOINT),
        getRequest(LAB_GET_SESSIONS_ENDPOINT),
        getRequest(TEST_CASES_GET_COLLECTIONS_ENDPOINT),
        getProjects(),
      ]);
      setData((prevData) => ({
        ...prevData,
        fetchedPastVersions: pastVersionsResponse.data,
        fetchedSessionData: sessionResponse.data,
        fetchedTestCaseCollectionData:
          testCollectionsResponse.data.test_case_collections,
        fetchedProjects: projectsResponse,
      }));
    } catch (error) {
      throw new Error(error.detail);
    } finally {
      setLoading(false);
    }
  }, [getProjects, getRequest, setData]);

  useEffect(() => {
    async function executeOnlyWhenSignedIn() {
      if (token !== null) {
        await fetchData();
      }
    }

    executeOnlyWhenSignedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { loading };
};
