import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, useColorModeValue } from '@chakra-ui/react';
import { Check, Copy, FileCode2 } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import {
  CodeEditor,
  CodeEditorPasteTheme,
} from '@twilio-paste/core/code-editor-library';
import { Tabs, TabsList, TabsTrigger } from '../../components/ui/tabs';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { Label } from '../../components/ui/label';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../components/ui/avatar';
import openAILogoWhite from '../../assets/openai-white-logomark.png';
import openAILogo from '../../assets/openai-logomark.png';
import anthropicLogo from '../../assets/anthropic-logo.png';
import { cn } from '../../lib/utils';
import { v4 as uuidv4 } from 'uuid';
import FormattedCopyToClipboard from '../../subframe/components/FormattedCopyToClipboard';
import { useAnalytics } from '../../hooks/usePageVisitedAnalytics';

type SupportedLanguages =
  | 'typescript'
  | 'python'
  | 'langchain'
  | 'langchainjs'
  | 'curl';

type Providers = 'openai' | 'anthropic' | 'none';
type TabOptions = 'observability' | 'evaluation';

const newUUID = uuidv4();

const generateCodeSnippet = (
  apiKey: string,
  provider: Providers,
  language: SupportedLanguages,
  tabSelection: TabOptions
) => {
  const snippets = {
    python: {
      observability: {
        openai: `from openai import OpenAI
from parea import Parea, trace

client = OpenAI()
p = Parea(api_key="${apiKey}")
p.wrap_openai_client(client)


@trace
def main() -> str:
    completion = client.chat.completions.create(
        model="gpt-4o-mini",
        messages=[{"role": "user", "content": "Write a haiku about the moon"}],
    )
    return completion.choices[0].message.content`,
        anthropic: `from anthropic import Anthropic
from parea import Parea, trace

client = Anthropic()
p = Parea(api_key="${apiKey}")
p.wrap_anthropic_client(client)


@trace
def main() -> str:
    message = client.messages.create(
        model="claude-3-haiku-20240307",
        messages=[{"role": "user", "content": "Write a haiku about the moon"}],
        max_tokens=100,
    )
    return message.content[0].text`,
        none: `from parea import Parea, trace

p = Parea(api_key="${apiKey}")


@trace
def main(some_param: str = "moon") -> str:
    return f"Here is a haiku about the {some_param}"`,
      },
      evaluation: {
        openai: `from openai import OpenAI
from parea import Parea, trace
from parea.schemas import EvaluationResult, Log

client = OpenAI()
p = Parea(api_key="${apiKey}")
p.wrap_openai_client(client)


def is_correct(log: Log) -> EvaluationResult:
    if log.output == log.target:
        return EvaluationResult(name="is_correct", score=1.0, reason="Output matches target")
    return EvaluationResult(name="is_correct", score=0, reason="Output does not match target")


@trace(eval_funcs=[is_correct])
def main(problem: str) -> str:
    completion = client.chat.completions.create(
        model="gpt-4o-mini",
        messages=[
            {
                "role": "user",
                "content": f"""Solve the math problem. Only respond with the answer.\\n
                 Example:\\nProblem: What is 2+2.\\nAnswer: 4\\n
                 Problem:\\n{problem}\\nAnswer:""",
            }
        ],
    )
    return completion.choices[0].message.content


# target is a reserved keyword and will be passed to the evaluation function automatically
dataset = [{"problem": "What is 2+2?", "target": "4"}, {"problem": "What is 3+3?", "target": "6"}]
p.experiment(name="Math", data=dataset, func=main).run()`,
        anthropic: `from anthropic import Anthropic
from parea import Parea, trace
from parea.schemas import EvaluationResult, Log

client = Anthropic()
p = Parea(api_key="${apiKey}")
p.wrap_anthropic_client(client)


def is_correct(log: Log) -> EvaluationResult:
    if log.output == log.target:
        return EvaluationResult(name="is_correct", score=1.0, reason="Output matches target")
    return EvaluationResult(name="is_correct", score=0, reason="Output does not match target")


@trace(eval_funcs=[is_correct])
def main(problem: str) -> str:
    message = client.messages.create(
        model="claude-3-haiku-20240307",
        messages=[
            {
                "role": "user",
                "content": f"""Solve the math problem. Only respond with the answer.\\n
                 Example:\\nProblem: What is 2+2.\\nAnswer: 4\\n
                 Problem:\\n{problem}\\nAnswer:""",
            }
        ],
        max_tokens=100,
    )
    return message.content[0].text


# target is a reserved keyword and will be passed to the evaluation function automatically
dataset = [{"problem": "What is 2+2?", "target": "4"}, {"problem": "What is 3+3?", "target": "6"}]
p.experiment(name="Math", data=dataset, func=main).run()`,
        none: `from parea import Parea, trace
from parea.evals.general import levenshtein

p = Parea(api_key="${apiKey}")


@trace(eval_funcs=[levenshtein])
def main(name: str) -> str:
    return f"Hello {name}"


# target is a reserved keyword and will be passed to the evaluation function automatically
dataset = [{"name": "Foo", "target": "Hi Foo"}, {"name": "Bar", "target": "Hello Bar"}]
p.experiment(name="Levenshtein", data=dataset, func=main).run()`,
      },
    },
    typescript: {
      observability: {
        openai: `import OpenAI from 'openai';
import { Parea, patchOpenAI, trace } from "parea-ai";

const openai = new OpenAI();
new Parea("${apiKey}");
patchOpenAI(openai);

const main = trace('main', async () => {
      const response = await openai.chat.completions.create({
          model: 'gpt-4o-mini',
          messages: [{"role": "user", "content": "Write a haiku about the moon"}]
      });
      return response.choices[0].message.content
  },
);`,
        anthropic: ``,
        none: `import { Parea trace } from "parea-ai";

new Parea("${apiKey}");

const main = trace('main', async (someParam: string = "moon") => {
    return \`Here is a haiku about the \${someParam}\`
  },
);`,
      },
      evaluation: {
        openai: `import OpenAI from 'openai';
import { EvaluationResult, Log, Parea, patchOpenAI, trace } from 'parea-ai';

const openai = new OpenAI();
const p = new Parea("${apiKey}");
patchOpenAI(openai);

function isCorrect(log: Log): EvaluationResult {
  if (log.output === log.target) {
    return { name: 'is_correct', score: 1.0, reason: 'Output matches target' };
  } else {
    return { name: 'is_correct', score: 0, reason: 'Output does not match target' };
  }
}

const main = trace('main', async (problem: string) => {
    const response = await openai.chat.completions.create({
      model: 'gpt-4o-mini',
      messages: [
        {
          role: 'user',
          content: \`Solve the math problem. Only respond with the answer.\\n
            Example:\\nProblem: What is 2+2.\\nAnswer: 4\\n
            Problem:\\n\${problem}\\nAnswer:\`,
        },
      ],
    });
    return response.choices[0].message.content;
  },
  { evalFuncs: [isCorrect] },
);

async function eval() {
  const dataset = [
    { problem: 'What is 2+2?', target: '4' },
    { problem: 'What is 3+3?', target: '6' },
  ];
  const e = p.experiment('Math', dataset, main);
  return await e.run();
}
`,
        anthropic: ``,
        none: `import { Parea, trace, levenshtein } from 'parea-ai';

const p = new Parea("${apiKey}");

const main = trace('main', (name: string) => {
    return \`Hello \${name}\`;
  },
  { evalFuncs: [levenshtein] },
);

// target is a reserved keyword and will be passed to the evaluation function automatically
const dataset = [{ name: 'Foo', target: 'Hi Foo' }, { name: 'Bar', target: 'Hello Bar' }];
async function eval() {
  const e = p.experiment('Levenshtein', dataset, main);
  return await e.run();
}`,
      },
    },
    langchain: {
      observability: {
        openai: `from langchain_core.output_parsers import StrOutputParser
from langchain_core.prompts import ChatPromptTemplate
from langchain_openai import ChatOpenAI

from parea import Parea
from parea.utils.trace_integrations.langchain import PareaAILangchainTracer

p = Parea(api_key="${apiKey}")
handler = PareaAILangchainTracer()

llm = ChatOpenAI()
prompt = ChatPromptTemplate.from_messages([("user", "{input}")])
chain = prompt | llm | StrOutputParser()


def main():
    return chain.invoke(
        {"input": "Write a haiku about the moon"},
        config={"callbacks": [handler]},
    )`,
        anthropic: `from langchain_core.output_parsers import StrOutputParser
from langchain_core.prompts import ChatPromptTemplate
from langchain_anthropic import ChatAnthropic

from parea import Parea
from parea.utils.trace_integrations.langchain import PareaAILangchainTracer

p = Parea(api_key="${apiKey}")
handler = PareaAILangchainTracer()

llm = ChatAnthropic()
prompt = ChatPromptTemplate.from_messages([("user", "{input}")])
chain = prompt | llm | StrOutputParser()


def main():
    return chain.invoke(
        {"input": "Write a haiku about the moon"},
        config={"callbacks": [handler]},
    )`,
      },
      evaluation: {
        openai: '',
        anthropic: '',
        none: '',
      },
    },
    langchainjs: {
      observability: {
        openai: `import { Parea, PareaAILangchainTracer } from 'parea-ai';
import { PromptTemplate } from '@langchain/core/prompts';
import { OpenAI } from '@langchain/openai';
import { StringOutputParser } from '@langchain/core/output_parsers';

new Parea('${apiKey}');
const handler = new PareaAILangchainTracer();

const model = new OpenAI();
const prompt = PromptTemplate.fromTemplate('Write a haiku about the {topic}');
const parser = new StringOutputParser();
const chain = prompt.pipe(model).pipe(parser);

chain.invoke({ topic: 'moon' }, { callbacks: [handler] });`,
        anthropic: `import { Parea, PareaAILangchainTracer } from 'parea-ai';
import { PromptTemplate } from '@langchain/core/prompts';
import { StringOutputParser } from '@langchain/core/output_parsers';
import { ChatAnthropic } from '@langchain/anthropic';

new Parea('${apiKey}');
const handler = new PareaAILangchainTracer();

const model = new ChatAnthropic();
const prompt = PromptTemplate.fromTemplate('Write a haiku about the {topic}');
const parser = new StringOutputParser();
const chain = prompt.pipe(model).pipe(parser);

chain.invoke({ topic: 'moon' }, { callbacks: [handler] });`,
      },
      evaluation: {
        openai: '',
        anthropic: '',
        none: '',
      },
    },
    curl: {
      observability: {
        openai: `curl --location 'https://parea-ai-backend-us-9ac16cdbc7a7b006.onporter.run/api/parea/v1/trace_log' \\
--header 'Content-Type: application/json' \\
--header 'x-api-key: ${apiKey}' \\
--data '{
    "trace_id": "${newUUID}",
    "root_trace_id": "${newUUID}",
    "parent_trace_id": "${newUUID}",
    "trace_name": "llm-Openai",
    "project_name": "default",
    "inputs": {"topic": "moon"},
    "output": "Here is a haiku about the moon",
    "configuration": {
        "model": "gpt-4o-mini",
        "provider": "openai",
        "messages": [{"role": "user", "content": "Write a haiku about the moon"}]
    },
    "start_timestamp": "${new Date().toISOString()}",
    "end_timestamp": "${new Date().toISOString()}",
    "latency": 0.6771,
    "input_tokens": 14,
    "output_tokens": 18,
    "total_tokens": 32,
    "cost": 0.0006,
    "status": "success"
}'
`,
        anthropic: `curl --location 'https://parea-ai-backend-us-9ac16cdbc7a7b006.onporter.run/api/parea/v1/trace_log' \\
--header 'Content-Type: application/json' \\
--header 'x-api-key: ${apiKey}' \\
--data '{
    "trace_id": "${newUUID}",
    "root_trace_id": "${newUUID}",
    "parent_trace_id": "${newUUID}",
    "trace_name": "llm-Anthropic",
    "project_name": "default",
    "inputs": {"topic": "moon"},
    "output": "Here is a haiku about the moon",
    "configuration": {
        "model": "claude-3-haiku-20240307",
        "provider": "anthropic",
        "messages": [{"role": "user", "content": "Write a haiku about the moon"}]
    },
    "start_timestamp": "${new Date().toISOString()}",
    "end_timestamp": "${new Date().toISOString()}",
    "latency": 0.6771,
    "input_tokens": 14,
    "output_tokens": 18,
    "total_tokens": 32,
    "cost": 0.0006,
    "status": "success"
}'`,
        none: `curl --location 'https://parea-ai-backend-us-9ac16cdbc7a7b006.onporter.run/api/parea/v1/trace_log' \\
--header 'Content-Type: application/json' \\
--header 'x-api-key: ${apiKey}' \\
--data '{
    "trace_id": "${newUUID}",
    "root_trace_id": "${newUUID}",
    "parent_trace_id": "${newUUID}",
    "trace_name": "LLM",
    "project_name": "default",
    "inputs": {"topic": "moon"},
    "output": "Here is a haiku about the moon",
    "start_timestamp": "${new Date().toISOString()}",
    "end_timestamp": "${new Date().toISOString()}",
    "status": "success",
    "metadata": {"purpose": "testing"}
}'
`,
      },
      evaluation: {
        openai: '',
        anthropic: '',
        none: '',
      },
    },
  };
  return snippets[language][tabSelection][provider];
};

export const supportedLangToIDELang = {
  typescript: 'typescript',
  python: 'python',
  langchain: 'python',
  langchainjs: 'javascript',
  curl: 'shell',
};

const docLink = {
  observability: 'https://docs.parea.ai/welcome/getting-started',
  evaluation: 'https://docs.parea.ai/welcome/getting-started-evaluation',
};

const IntegrationCodeSnippet = ({ apiKey }) => {
  const { handleCopy } = useCopyToClipboard();

  const initialLang: SupportedLanguages = 'curl';
  const [language, setLanguage] = useState(initialLang);
  const initialTab: TabOptions = 'observability';
  const [tab, setTab] = useState(initialTab);
  const initialProvider: Providers = 'openai';
  const [selectedProvider, setSelectedProvider] = useState(initialProvider);

  const [copied, setCopied] = useState(false);
  const [codeSnippet, setCodeSnippet] = useState('');
  const openAILogoSrc = useColorModeValue(openAILogo, openAILogoWhite);

  const { sendAnalytics } = useAnalytics();

  useEffect(() => {
    if (language === 'curl' && tab === 'evaluation') {
      setLanguage('python');
    }
  }, [language, tab]);

  const sendClickEvent = async (event, option) => {
    await sendAnalytics('onboarding_click_events', {
      page: 'integrations',
      event: event,
      option: option,
    });
  };

  const tabDescription = {
    observability: 'Trace your application and send the logs to Parea.',
    evaluation: 'Run evaluations on your code and track them as experiments.',
  };

  const editorBackground = useColorModeValue('light', 'paste');
  const editorRef = useRef(null);

  const value = useMemo(() => {
    return generateCodeSnippet(apiKey, selectedProvider, language, tab);
  }, [apiKey, selectedProvider, language, tab]);

  useEffect(() => {
    setCodeSnippet(value);
  }, [value]);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    monaco.editor.defineTheme('paste', CodeEditorPasteTheme);
    monaco.editor.setTheme(editorBackground);
  }

  const handleLanguageChange = (newLanguage: SupportedLanguages) => {
    setLanguage(newLanguage);
    sendClickEvent('language_change', newLanguage);
  };

  const handleProviderChange = (newProvider: Providers) => {
    setSelectedProvider(newProvider);
    sendClickEvent('provider_change', newProvider);
  };

  const handleTabChange = (newTab: TabOptions) => {
    setTab(newTab);
    sendClickEvent('tab_change', newTab);
  };

  const handleCopyCode = () => {
    handleCopy(value).then(() => {
      setCopied(true);
    });
    sendClickEvent('copy_code', `${tab}_${language}_${selectedProvider}`);
  };

  const handleViewDocs = () => {
    const url = docLink[tab];
    window.open(docLink[tab], '_blank');
    sendClickEvent('view_docs', url);
  };

  return (
    <>
      <Tabs value={tab} onValueChange={handleTabChange}>
        <div className="flex items-center space-x-2">
          <TabsList>
            <TabsTrigger value="observability">Observability</TabsTrigger>
            <TabsTrigger value="evaluation">Evaluation</TabsTrigger>
          </TabsList>
          <p className="text-sm text-muted-foreground italic">
            {tabDescription[tab]}
          </p>
        </div>
      </Tabs>
      <div className="grid w-full items-center gap-4">
        {apiKey !== '' && (
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="generated-key">
              Your Default Parea API Key&nbsp;
            </Label>
            <FormattedCopyToClipboard
              name="generated-key"
              id="generated-key"
              className="h-[50px] w-full bg-transparent pt-3"
              original={apiKey}
            >
              {apiKey}
            </FormattedCopyToClipboard>
          </div>
        )}
        <Label htmlFor="provider">Select your provider</Label>
        <div className="flex flex-wrap gap-4 w-full">
          <Button
            className={cn(
              'flex cursor-pointer items-center gap-2 border border-gray-300 rounded-lg p-6 transition-all hover:bg-accent hover:text-accent-foreground',
              selectedProvider === 'openai' &&
                'bg-accent text-accent-foreground border-blue-500'
            )}
            variant={'secondary'}
            onClick={() => handleProviderChange('openai')}
          >
            <Avatar>
              <AvatarImage src={openAILogoSrc} />
              <AvatarFallback>O</AvatarFallback>
            </Avatar>
            <h2 className="font-semibold">OpenAI</h2>
          </Button>
          <Button
            className={cn(
              'flex cursor-pointer items-center gap-2 border border-gray-300 rounded-lg p-6 transition-all hover:bg-accent hover:text-accent-foreground',
              selectedProvider === 'anthropic' &&
                'bg-accent text-accent-foreground border-blue-500'
            )}
            variant={'secondary'}
            onClick={() => handleProviderChange('anthropic')}
          >
            <Avatar>
              <AvatarImage src={anthropicLogo} />
              <AvatarFallback>A</AvatarFallback>
            </Avatar>
            <h2 className="font-semibold">Anthropic</h2>
          </Button>
          <Button
            className={cn(
              'flex cursor-pointer items-center gap-2 border border-gray-300 rounded-lg p-6 transition-all hover:bg-accent hover:text-accent-foreground',
              selectedProvider === 'none' &&
                'bg-accent text-accent-foreground border-blue-500'
            )}
            variant={'secondary'}
            onClick={() => handleProviderChange('none')}
          >
            <h2 className="font-semibold">None</h2>
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div className="grid w-full items-center gap-4">
          <Label htmlFor="language">Select your integration type</Label>
          <Select value={language} onValueChange={handleLanguageChange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="python">Python</SelectItem>
              {selectedProvider !== 'anthropic' && (
                <SelectItem value="typescript">Node.js</SelectItem>
              )}
              {tab === 'observability' && selectedProvider !== 'none' && (
                <>
                  <SelectItem value="langchain">Langchain</SelectItem>
                  <SelectItem value="langchainjs">LangchainJs</SelectItem>
                </>
              )}
              {tab === 'observability' && (
                <SelectItem value="curl">curl</SelectItem>
              )}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <Button variant="ghost" size="sm" onClick={handleCopyCode}>
            {copied ? (
              <Check className="w-4 h-4 mr-2" />
            ) : (
              <Copy className="w-4 h-4 mr-2" />
            )}
            {copied ? 'Copied!' : 'Copy Code'}
          </Button>
          <Button variant="ghost" size="sm" onClick={handleViewDocs}>
            <FileCode2 className="w-4 h-4 mr-2" />
            View Docs
          </Button>
        </div>
      </div>
      <div className="grid w-full items-center gap-4">
        <Label htmlFor="code-editor">
          {`Copy the code snippet and paste it in your ${language === 'curl' ? 'terminal' : 'IDE'}`}
        </Label>
        <CodeEditor
          language={supportedLangToIDELang[language]}
          value={codeSnippet}
          onMount={handleEditorDidMount}
          className="border-2 rounded-md h-[250px] xl:h-[375px]"
          options={{
            readOnly: true,
            minimap: { enabled: false },
          }}
        />
      </div>
    </>
  );
};

export default IntegrationCodeSnippet;
