import { ModalView } from './ModalView';
import { NewAPIKey } from './NewPareaAPIKey';
import { useState } from 'react';

export const CreatePareaAPIKeyModal = ({
  onClose,
  isOpen,
  org_id,
  setError,
  setItems,
}) => {
  const [item, setItem] = useState(null);
  return (
    <ModalView
      header_text={'Create new API key'}
      onClose={() => {
        onClose(item);
      }}
      isOpen={isOpen}
      compact={true}
      size={'lg'}
    >
      <NewAPIKey
        org_id={org_id}
        setError={setError}
        setItem={setItem}
        setItems={setItems}
      />
    </ModalView>
  );
};
