import { LoadingOverlay, LoadingSpinner, LoadingText } from '@saas-ui/react';
import { Card, CardBody } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const LoadingSpinnerWrapper = ({ text = null }) => {
  const [isLoading, setIsLoading] = useState(true);

  // Set a smaller timer before we show loader, so it doesn't flash for a split second
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  return !isLoading ? (
    <div className="flex-1 space-y-4 p-4 pt-6">
      <Card position="relative" height="100px">
        <CardBody>
          <LoadingOverlay variant="overlay">
            <LoadingSpinner size="lg" thickness="4px" />
            <LoadingText>{text || null}</LoadingText>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  ) : null;
};

export const LoadingSpinnerWrapperNoCard = ({ text = null }) => {
  const [isLoading, setIsLoading] = useState(true);

  // Set a smaller timer before we show loader, so it doesn't flash for a split second
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  return !isLoading ? (
    <LoadingOverlay variant="overlay">
      <LoadingSpinner size="lg" thickness="4px" />
      <LoadingText>{text || null}</LoadingText>
    </LoadingOverlay>
  ) : null;
};
