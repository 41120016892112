import axios from 'axios';
import {
  AUTH_SERVICE_URL,
  BACKEND_URL,
  DEV_ENV,
  ENABLE_ANALYTICS,
  ENABLE_CLERK_AUTHENTICATION,
} from '../utils/constants';
import { useCallback, useEffect, useState } from 'react';
import axiosRetry from 'axios-retry';
import { sendSentryError } from '../utils/sendSentryError';
import { useAuthManager } from './auth/useAuthManager';

const apiClient = axios.create({
  baseURL: BACKEND_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
});
const authApiClient = axios.create({
  baseURL: AUTH_SERVICE_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const retryCondition = (error) => {
  return error.response && error.response.status === 401;
};

const retryDelay = (retryCount) => {
  return retryCount * 1000; // Increase delay by 1 second for each retry
};

const axiosRetryConfig = {
  retries: 0,
  retryCondition,
  retryDelay,
};

axiosRetry(apiClient, axiosRetryConfig);

const handleResponse = (response, setError) => {
  if (response.status >= 200 && response.status < 300) {
    return {
      status: response.status,
      data: response.data,
    };
  } else {
    const msg =
      'Error: ' + response?.data?.message ||
      `with response status: ${response.status}`;
    const error = new Error(msg);
    error.status = response.status;
    error.detail = response.statusText;
    setError(error);
    throw error;
  }
};

const handleError = (error, setError) => {
  let errorMessage;
  let errorStatus = 500;
  if (axios.isCancel(error)) {
    errorMessage = 'Request was canceled';
    errorStatus = 0;
  } else if (error.response) {
    errorStatus = error.response.status;
    switch (errorStatus) {
      case 404:
        errorMessage = 'Resource not found';
        break;
      case 401:
        errorMessage = 'Unauthorized';
        break;
      case 409:
        errorMessage = 'Conflict';
        break;
      case 429:
        errorMessage = 'Too many requests';
        break;
      case 413:
        errorMessage = 'Request too large';
        break;
      case 403:
        errorMessage = 'Forbidden';
        break;
      case 503:
        errorMessage = 'Service unavailable';
        break;
      default:
        errorMessage = error.response.data.message || error.response.statusText;
    }
  } else if (error.request) {
    console.error(`Error: ${error.request}`);
    errorMessage = 'Network Error';
  } else {
    errorMessage = error.message || 'Something went wrong';
  }
  if (DEV_ENV) {
    console.error(`Error [${errorStatus}]: ${errorMessage}`);
  }
  const errorObj = new Error(errorMessage);
  errorObj.status = errorStatus;
  errorObj.detail = error.response?.data?.detail || errorMessage;
  setError(errorObj);
  throw errorObj;
};

export const useApiClient = () => {
  const { getToken } = useAuthManager();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error && !DEV_ENV && ENABLE_ANALYTICS === true) {
      sendSentryError(error);
    }
  }, [error]);

  const request = useCallback(
    async (method, url, data = null, headers = {}, authService = false) => {
      const _apiClient = authService === true ? authApiClient : apiClient;
      const token = await getToken();
      const config = {
        method,
        url,
        headers: { ...headers },
        timeout: _apiClient.defaults.timeout,
      };
      if (!authService) {
        config.headers['Authorization'] = `Bearer ${token}`;
        if (ENABLE_CLERK_AUTHENTICATION === false) {
          config.headers['X-XSRF-Token'] = token;
          config.withCredentials = true;
        }
      }

      if (data) {
        config.data = data;
      }

      if (DEV_ENV) {
        console.log(
          `${method}-${url} Request called ${
            data ? `with: ${JSON.stringify(data, null, 2)} ` : ``
          }`
        );
      }

      try {
        const response = await _apiClient(config);
        return handleResponse(response, setError);
      } catch (err) {
        handleError(err, setError);
      }
    },
    [getToken]
  );

  const getRequest = useCallback(
    (url, headers = {}, authService = false) =>
      request('get', url, null, headers, authService),
    [request]
  );
  const postRequest = useCallback(
    (url, data, headers = {}, authService = false) =>
      request('post', url, data, headers, authService),
    [request]
  );
  const deleteRequest = useCallback(
    (url, data, headers = {}) => request('delete', url, data, headers),
    [request]
  );
  const putRequest = useCallback(
    (url, data, headers = {}) => request('put', url, data, headers),
    [request]
  );

  return { getRequest, postRequest, deleteRequest, putRequest };
};
