import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import type { DeployedVersionObj } from '../utils/types';

export interface DeployedPromptStoreInitialState {
  loading: boolean;
  error: string | null;
  deployedVersions: DeployedVersionObj;
  deployedVersionIds: Set<number>;
}

const initialState: DeployedPromptStoreInitialState = {
  loading: false,
  error: null,
  deployedVersions: {},
  deployedVersionIds: new Set(),
};

interface DeployedPromptStateStore extends DeployedPromptStoreInitialState {
  setError: (error: string | null) => void;
  setLoading: (loading: boolean) => void;
  setShowAllDeployedVersions: (showAllDeployedVersions: boolean) => void;
  setDeployedVersions: (deployedVersions: DeployedVersionObj) => void;
  setDeployedVersionIds: (deployedVersionIds: Set<number>) => void;
  addDeployedVersionId: (id: number) => void;
  removeDeployedVersionId: (id: number) => void;
  deleteDeployedVersion: (id: number) => void;
}

export const useDeployedPromptStore: () => DeployedPromptStateStore = create(
  immer((set) => ({
    ...initialState,
    setError: (error: string | null) =>
      set((state) => {
        state.error = error;
      }),
    setLoading: (loading: boolean) =>
      set((state) => {
        state.loading = loading;
      }),
    setShowAllDeployedVersions: (showAllDeployedVersions: boolean) =>
      set((state) => {
        state.showAllDeployedVersions = showAllDeployedVersions;
      }),
    setDeployedVersions: (deployedVersions: DeployedVersionObj) =>
      set((state) => {
        state.deployedVersions = deployedVersions;
      }),
    setDeployedVersionIds: (deployedVersionIds: Set<number>) =>
      set((state) => {
        state.deployedVersionIds = deployedVersionIds;
      }),
    addDeployedVersionId: (id: number) =>
      set((state) => {
        state.deployedVersionIds.add(id);
      }),
    removeDeployedVersionId: (id: number) =>
      set((state) => {
        state.deployedVersionIds.delete(id);
      }),
    deleteDeployedVersion: (id) =>
      set((state) => {
        delete state.deployedVersions[id];
      }),
  }))
);
