import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuthManager } from '../../hooks/auth/useAuthManager';
import { CreateOrganization } from './Auth';

export const CreateOrg = ({ isProfileOpen, onProfileClose, header_text }) => {
  const { user } = useAuthManager();
  const org_id = user?.organizationMemberships?.[0]?.organization?.id;

  useEffect(() => {
    if (org_id) {
      onProfileClose();
    }
  }, [onProfileClose, org_id]);

  const afterSignInUrl =
    '/' + window.location.href.split('/').slice(3).join('/');

  return (
    <Modal isOpen={isProfileOpen} onClose={onProfileClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        {header_text && (
          <ModalHeader textAlign="center">{header_text}</ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody>
          <CreateOrganization
            afterCreateOrganizationUrl={afterSignInUrl}
            skipInvitationScreen={true}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
