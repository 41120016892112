import {
  BACKEND_URL,
  DEV_ENV,
  ENABLE_ANALYTICS,
  ENABLE_CLERK_AUTHENTICATION,
} from '../utils/constants';
import { useCallback, useEffect } from 'react';
import { useStreamingStore } from '../store/useStreamingStore';
import { useLabStore } from '../store/useLabStore';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import type { LogProbability } from '../utils/types';
import { sendSentryError } from '../utils/sendSentryError';
import { useAuthManager } from './auth/useAuthManager';

export const useFetchApiClient = () => {
  const { getToken } = useAuthManager();

  const { setMessage, setStreamStart, setStreamDone, setInferenceId } =
    useStreamingStore((state) => ({
      setMessage: state.setMessage,
      setStreamStart: state.setStreamStart,
      setStreamDone: state.setStreamDone,
      setInferenceId: state.setInferenceId,
    }));
  const { error, setInferenceCellLoading, setError } = useLabStore((state) => ({
    error: state.error,
    setInferenceCellLoading: state.setInferenceCellLoading,
    setError: state.setError,
  }));

  useEffect(() => {
    if (error && !DEV_ENV && ENABLE_ANALYTICS === true) {
      sendSentryError(error);
    }
  }, [error]);

  const streamPostRequest = useCallback(
    async (url, body = null, index = null) => {
      const abortCtrl = new AbortController();
      // Add a flag to track if the stream has been aborted
      let isAborted = false;

      const token = await getToken();
      const headers = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Connection: 'keep-alive',
        Authorization: `Bearer ${token}`,
      };
      if (ENABLE_CLERK_AUTHENTICATION === false) {
        headers['X-XSRF-Token'] = token;
      }

      if (DEV_ENV) {
        console.log(
          `$POST-${url} Request called ${
            body ? `with: ${JSON.stringify(body, null, 2)} ` : ``
          }`
        );
      }

      const [column, row] = index.split('-');
      let chunks = '';
      let infId = '';
      let start = null;
      let logprobs: LogProbability[] = [];
      setInferenceCellLoading(column, row, true);

      // Use fetchEventSource to handle Server-Sent Events.
      let errorMsg = '';
      return new Promise((resolve, reject) => {
        fetchEventSource(`${BACKEND_URL}${url}`, {
          headers,
          body: body ? JSON.stringify(body) : undefined,
          method: 'POST',
          credentials:
            ENABLE_CLERK_AUTHENTICATION === false ? 'include' : 'same-origin',
          signal: abortCtrl.signal,
          onopen(response) {
            // Called when the connection is opened.
            start = performance.now();
            setStreamStart(index, start);
            setInferenceCellLoading(column, row, false);
            if (response.ok && response.status === 200) {
              return;
            }

            if (response.status === 401) {
              return;
            }

            if (!response.ok) {
              response.text().then((errMessageFromServer) => {
                try {
                  const errMsg = JSON.parse(errMessageFromServer);
                  errorMsg = errMsg.detail || 'An unexpected error occurred';
                } catch (e) {
                  errorMsg = 'Failed to parse error message';
                }
                if (errorMsg === 'Invalid token') {
                  if (DEV_ENV) {
                    console.log(
                      'onopen: ',
                      errorMsg,
                      'Invalid token should be ignored'
                    );
                  }
                } else {
                  setError(errorMsg);
                }
                reject(new Error(errorMsg));
                isAborted = true; // Set the aborted flag
                abortCtrl.abort(); // Abort the stream
              });
              return;
            }

            if (response.status !== 200) {
              errorMsg = `Unexpected response status: ${response.status}`;
              reject(new Error(errorMsg)); // Reject the promise with the error
              isAborted = true; // Set the aborted flag
              abortCtrl.abort(); // Abort the stream
            }
          },
          onmessage(event) {
            if (isAborted) {
              // Check if the operation was aborted
              return; // If so, ignore the message
            }
            // Called for each message received.
            if (event?.data?.startsWith('ID_START')) {
              infId = event?.data?.split('ID_START_')[1];
              setInferenceId(index, infId);
            } else if (event?.data && event?.data?.length > 0) {
              let payload = {};
              try {
                payload = JSON.parse(event.data);
              } catch (e) {
                throw e;
              }
              const chunk = payload['chunk'];
              const logProb = payload['logprob'];
              chunks += chunk;
              if (logProb !== undefined && logProb !== null)
                logprobs = [...logprobs, { token: chunk, logprob: logProb }];
              setMessage(index, chunks, logprobs);
            }
          },
          onclose() {
            // Called when the connection is closed by the server.
            const end = performance.now();
            const latency = (end - start) / 1000;
            setStreamDone(index, end);
            resolve({
              content: chunks,
              latency: latency,
              infId: infId,
              logprobs,
            });
          },
          onerror(err) {
            // Called on any error during the event stream.
            setInferenceCellLoading(column, row, false);
            isAborted = true; // Set the aborted flag if there's an error
            reject(errorMsg ? new Error(errorMsg) : err);
          },
        });
      });
    },
    [
      getToken,
      setInferenceCellLoading,
      setStreamStart,
      setError,
      setInferenceId,
      setMessage,
      setStreamDone,
    ]
  );

  return {
    streamPostRequest,
  };
};
