import StepList from './steps/stepList';
import CreateOrg from './steps/createOrg';
import React, { useMemo, useState } from 'react';
import Integrations from './steps/integrations';
import Features from './steps/features';
import EventListen from './steps/eventListen';
import { useNavigate } from 'react-router-dom';
import { Progress } from '../../components/ui/progress';
import { Button } from '../../components/ui/button';
import { useAuthManager } from '../../hooks/auth/useAuthManager';
import { Tooltip, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Persona } from '@saas-ui/react';
import PareaIconLight from '../../assets/PareaIconLight.png';
import { useAnalytics } from '../../hooks/usePageVisitedAnalytics';
import { MoonIcon, SunIcon } from '../../components/layout/ThemeModeToggler';
import { useTheme } from '../../components/layout/theme-provider';
import { useSuccessfulToast } from '../../hooks/useSuccessfulToast';

interface WelcomePageProps {
  currentStep: number;
}

const WelcomePage = (props: WelcomePageProps) => {
  const { currentStep } = props;

  const navigate = useNavigate();
  const showToast = useSuccessfulToast();

  const [step, setStep] = useState(currentStep);
  const [apiKey, setApiKey] = useState('');
  const onboardingStartTime = useMemo(() => new Date(), []);

  const { sendAnalytics } = useAnalytics();
  const { user } = useAuthManager();
  const orgId = useMemo(
    () => user?.organizationMemberships?.[0]?.organization?.id,
    [user]
  );

  const onboardingSeen = localStorage.getItem('onboarding_seen');

  if (onboardingSeen) {
    navigate('/');
  }

  const handleStepChange = (step: number, newOrgId = null) => {
    if (!orgId && step > 1 && !newOrgId) {
      showToast(
        'You need to create an org before you can skip onboarding',
        'error'
      );
      return;
    }
    navigate(`/welcome?step=${step}`);
    setStep(step);
  };

  const stepArray = [
    <CreateOrg
      key={1}
      nextStep={function (newOrgId, joiningExisting): void {
        if (joiningExisting) {
          localStorage.setItem('onboarding_seen', 'true');
          navigate('/');
        } else {
          handleStepChange(2, newOrgId);
          sendAnalytics('onboarding', {
            step: 1,
            step_name: 'Create your organization',
          });
        }
      }}
    />,
    // <GenerateAPIKey
    //   key={2}
    //   apiKey={apiKey}
    //   setApiKey={setApiKey}
    //   previousStep={function (): void {
    //     handleStepChange(1);
    //   }}
    //   nextStep={function (): void {
    //     handleStepChange(3);
    //   }}
    // />,
    <Integrations
      key={3}
      apiKey={apiKey}
      setApiKey={setApiKey}
      previousStep={function (): void {
        handleStepChange(1);
      }}
      nextStep={function (): void {
        handleStepChange(3);
        sendAnalytics('onboarding', {
          step: 2,
          step_name: 'Observability & Evals',
        });
      }}
    />,
    <Features
      key={4}
      apiKey={apiKey}
      previousStep={function (): void {
        handleStepChange(2);
      }}
      nextStep={function (): void {
        handleStepChange(4);
        sendAnalytics('onboarding', {
          step: 3,
          step_name: 'Parea LLM Proxy',
        });
      }}
    />,
    <EventListen
      key={5}
      previousStep={function (): void {
        handleStepChange(3);
      }}
      nextStep={async function (path: string) {
        sendAnalytics('onboarding', {
          step: 4,
          step_name: 'Send your first event',
          path: path,
          onboarding_completed: true,
          time_to_value_seconds: Math.floor(
            (new Date().getTime() - onboardingStartTime.getTime()) / 1000
          ),
        });
        localStorage.setItem('onboarding_seen', 'true');
        navigate(path);
      }}
    />,
  ];

  const SwitchIcon = useColorModeValue(MoonIcon, SunIcon);
  const { colorMode, toggleColorMode } = useColorMode();
  const { setTheme } = useTheme();

  const handleColorMode = () => {
    toggleColorMode();
    if (SwitchIcon === MoonIcon) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  const handleSkipOnboarding = async () => {
    if (!orgId) {
      showToast(
        'You need to create an org before you can skip onboarding',
        'error'
      );
      return;
    }
    await sendAnalytics('onboarding', {
      step: step,
      skipped: true,
      onboarding_completed: false,
      completion_rate: (Number(step) / stepArray?.length) * 100,
    });
    localStorage.setItem('onboarding_seen', 'true');
    navigate('/');
  };
  return (
    <div className="flex h-screen w-full items-start mobile:flex-col mobile:gap-0">
      <div className="flex w-100 flex-none flex-col items-start self-stretch border-r border-solid border-neutral-border mobile:h-auto mobile:w-full mobile:flex-none mobile:border-b mobile:border-solid mobile:border-neutral-border">
        <div className="flex w-full flex-col items-start gap-4 border-b border-solid border-neutral-border pt-12 pr-6 pb-6 pl-6">
          <Persona
            src={PareaIconLight}
            name="Welcome to Parea AI"
            secondaryLabel="Experimentation for Devs & Domain Experts"
          />
          <div className="flex w-full items-center gap-4">
            <Progress
              value={(Number(step) / stepArray?.length) * 100}
              fullColor={'bg-green-800'}
            />
            <span className="w-[170px] text-right text-label-bold font-label-bold text-default-font">
              {Number(step)}/{stepArray?.length} completed
            </span>
          </div>
          <div className="flex w-full items-center gap-4 justify-between">
            <Tooltip
              hasArrow
              placement="top"
              label={
                'You need to at least create an org before you can skip onboarding'
              }
              isDisabled={!!orgId}
            >
              <Button
                size={'sm'}
                variant={'secondary'}
                onClick={handleSkipOnboarding}
              >
                Skip Onboarding
              </Button>
            </Tooltip>
            <Button size={'sm'} variant={'outline'} onClick={handleColorMode}>
              <SwitchIcon />
              <span className="ml-2">
                {colorMode === 'light' ? 'Light Mode' : 'Dark Mode'}
              </span>
            </Button>
          </div>
        </div>
        <div className="flex w-full flex-col items-start pt-2 pr-2 pb-2 pl-2">
          <StepList
            currentStep={step}
            setStep={(step) => {
              handleStepChange(step);
            }}
          />
        </div>
      </div>

      <div className="flex grow shrink-0 basis-0 flex-col items-start gap-6 self-stretch pt-6 pr-6 pb-6 pl-6 mobile:h-auto mobile:w-full mobile:flex-none">
        <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4">
          {stepArray[step - 1]}
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
