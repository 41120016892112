import React, { useEffect, useMemo, useState } from 'react';
import StepCardWrapper from '../StepCardWrapper';
import IntegrationCodeSnippet from '../IntegrationCodeSnippet';
import { useSuccessfulToast } from '../../../hooks/useSuccessfulToast';
import { useApiClient } from '../../../hooks/useApiClient';
import { PUBLIC_API_KEY_ENDPOINT } from '../../../utils/constants';
import { makeFormattedDate } from '../../../utils/helpers';
import { useAuthManager } from '../../../hooks/auth/useAuthManager';
import { LoadingSpinnerWrapper } from '../../../components/common/LoadingSpinnerWrapper';
import useFetchProjects from '../../../hooks/project/useFetchProjects';

interface IntegrationsProps {
  apiKey: string;
  setApiKey: (apiKey: string) => void;
  previousStep: () => void;
  nextStep: () => void;
}

type ApiKey = {
  name: string,
  key: string,
  created_at: string,
  api_key?: string,
};

const Integrations = (props: IntegrationsProps) => {
  const { apiKey, setApiKey, previousStep, nextStep } = props;

  useFetchProjects();

  const { user } = useAuthManager();
  const org_id = useMemo(
    () => user?.organizationMemberships?.[0]?.organization?.id,
    [user]
  );

  const [isLoading, setIsLoading] = useState(false);
  const showToast = useSuccessfulToast();

  const { getRequest } = useApiClient();

  useEffect(
    () => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await getRequest(
            `${PUBLIC_API_KEY_ENDPOINT}/${org_id}`
          );
          if (response.status === 200) {
            const keys: ApiKey[] = response.data;
            for (const key of keys) {
              key.created_at = makeFormattedDate(key.created_at);
              if (key?.api_key) {
                setApiKey(key.api_key);
                break;
              }
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          showToast(error.detail, 'error');
        } finally {
          setIsLoading(false);
        }
      };

      let timeoutId;
      const checkAndFetch = () => {
        if (!apiKey) {
          fetchData();
          timeoutId = setTimeout(checkAndFetch, 2000);
        }
      };

      checkAndFetch();

      // Clean up the timeout when the component unmounts or when data is received
      return () => clearTimeout(timeoutId);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiKey, org_id]
  );

  return (
    <StepCardWrapper
      nextStep={() => {
        nextStep();
      }}
      previousStep={previousStep}
      title={'Observability and Evals'}
      description={'Get started with Parea by logging your first event.'}
      isLoading={isLoading}
    >
      {(!apiKey || isLoading) && <LoadingSpinnerWrapper />}
      {apiKey && <IntegrationCodeSnippet apiKey={apiKey} />}
    </StepCardWrapper>
  );
};

export default Integrations;
