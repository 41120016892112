import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SignedIn, SignedOut } from './components/common/Auth';
import {
  ANNOTATION_CRITERION_DETAILED_ROUTE,
  ANNOTATION_QUEUE_DETAILED_ROUTE,
  ANNOTATION_QUEUE_ROUTE,
  BILLING_ROUTE,
  DASHBOARD_DETAILED_ROUTE,
  DASHBOARD_ROUTE,
  DEV_ENV,
  ENABLE_CLERK_AUTHENTICATION,
  EXPERIMENT_COMPARE_ROUTE,
  EXPERIMENTS_ROUTE,
  getPublicExperimentsRoute,
  getPublicSingleExperimentRoute,
  HIDE_BILLING,
  HOME_ROUTE,
  HOMEPAGE,
  LAB_ROUTE,
  LAB_SESSION_DETAILED_ROUTE,
  PUBLIC_LOGS_ROUTE,
  SETTINGS_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
  SINGLE_EXPERIMENT_ROUTE,
  STUDIO_DETAILED_ROUTE,
  STUDIO_ROUTE,
  TEST_HUB_ROUTE,
} from './utils/constants';
import { Box } from '@chakra-ui/react';
import { LoadingSpinnerWrapper } from './components/common/LoadingSpinnerWrapper';
import WelcomePage from './pages/Welcome/welcomePage';

const Settings = lazy(() => import('./pages/Settings/Settings'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const Deployments = lazy(() => import('./pages/Deployments/Deployments'));
const DeploymentsDetailed = lazy(
  () => import('./pages/Deployments/DeploymentsDetailed')
);
const SignInPage = lazy(() => import('./pages/ClerkAuth/SignIn'));
const SignUpPage = lazy(() => import('./pages/ClerkAuth/SignUp'));
const Lab = lazy(() => import('./pages/Playground/Lab'));
const Datasets = lazy(() => import('./pages/Datasets/Datasets'));
const LabDetailedViewWrapper = lazy(
  () => import('./pages/Playground/LabDetailedViewWrapper')
);
const Billing = lazy(() => import('./pages/Settings/Billing'));
const TraceExplorer = lazy(
  () => import('./pages/TraceLogs/DashboardDetailedViewNew')
);
const HomePageDashboard = lazy(() => import('./pages/Home/HomePageDashboard'));
const TraceLogTableContainer = lazy(
  () => import('./pages/TraceLogs/Dashboard')
);
const ExperimentDetailed = lazy(
  () => import('./pages/Experiments/ExperimentDetailed')
);
const ExperimentsOverview = lazy(
  () => import('./pages/Experiments/ExperimentsOverview')
);
const PublicExperimentsOverview = lazy(
  () => import('./pages/Experiments/Public/PublicExperimentsOverview')
);
const PublicExperimentDetailed = lazy(
  () => import('./pages/Experiments/Public/PublicExperimentDetailed')
);
const ExperimentCompare = lazy(
  () => import('./pages/Experiments/ExperimentCompare')
);
const DatasetTestCaseDetailed = lazy(
  () => import('./pages/Datasets/DatasetTestCaseDetailed')
);
const DatasetCollectionDetailed = lazy(
  () => import('./pages/Datasets/DatasetCollectionDetailed')
);
const AnnotationQueueDetailed = lazy(
  () => import('./pages/Annotation/AnnotationQueueDetailed')
);
const AnnotationOverview = lazy(
  () => import('./pages/Annotation/AnnotationOverview')
);
const AnnotationCriterionDetailed = lazy(
  () => import('./pages/Annotation/AnnotationCriterionDetailed')
);
const Callback = lazy(() => import('./components/common/Callback'));

const RedirectToParea = () => {
  if (!DEV_ENV && ENABLE_CLERK_AUTHENTICATION === false) {
    window.location.href = HOMEPAGE;
    return null;
  } else {
    return <SignInPage />;
  }
};

export default function RouteNav({ loading }) {
  if (loading)
    return (
      <Box width={{ base: '100%', md: '90%', lg: '80%' }} marginX="auto" mt={8}>
        <LoadingSpinnerWrapper text="Loading your saved data..." />
      </Box>
    );

  return (
    <Routes>
      <Route
        path={HOME_ROUTE}
        element={
          <>
            <SignedOut>
              <RedirectToParea />
            </SignedOut>
            <SignedIn>
              <HomePageDashboard />
            </SignedIn>
          </>
        }
      />
      <Route
        path={SIGNIN_ROUTE}
        element={
          <>
            <SignedOut>
              <SignInPage />
            </SignedOut>
            <SignedIn>
              <HomePageDashboard />
            </SignedIn>
          </>
        }
      />
      <Route
        path={SIGNUP_ROUTE}
        element={
          <>
            <SignedOut>
              <SignUpPage />
            </SignedOut>
            <SignedIn>
              <HomePageDashboard />
            </SignedIn>
          </>
        }
      />
      <Route path={SETTINGS_ROUTE} element={checkSignInWrapper(Settings)} />
      {HIDE_BILLING === false && (
        <Route path={BILLING_ROUTE} element={checkSignInWrapper(Billing)} />
      )}
      <Route path={LAB_ROUTE} element={checkSignInWrapper(Lab)} />
      <Route
        path={`${LAB_SESSION_DETAILED_ROUTE}/:session_id`}
        element={checkSignInWrapper(LabDetailedViewWrapper)}
      />
      <Route path={STUDIO_ROUTE} element={checkSignInWrapper(Deployments)} />
      <Route
        path={`/parea-testing${STUDIO_ROUTE}`}
        element={checkSignInWrapper(Deployments)}
      />
      <Route
        path={STUDIO_DETAILED_ROUTE}
        element={checkSignInWrapper(DeploymentsDetailed)}
      />
      <Route
        path={`/parea-testing${STUDIO_DETAILED_ROUTE}`}
        element={checkSignInWrapper(DeploymentsDetailed)}
      />
      <Route path={TEST_HUB_ROUTE} element={checkSignInWrapper(Datasets)} />
      <Route
        path={`${TEST_HUB_ROUTE}/:collection_id`}
        element={checkSignInWrapper(DatasetCollectionDetailed)}
      />
      <Route
        path={`${TEST_HUB_ROUTE}/:collection_id/t/:testcase_id`}
        element={checkSignInWrapper(DatasetTestCaseDetailed)}
      />
      <Route
        path={DASHBOARD_ROUTE}
        element={checkSignInWrapper(TraceLogTableContainer)}
      />
      <Route
        path={`/parea-testing${DASHBOARD_ROUTE}`}
        element={checkSignInWrapper(TraceLogTableContainer)}
      />
      <Route
        path={`${DASHBOARD_DETAILED_ROUTE}/:id`}
        element={checkSignInWrapper(TraceExplorer)}
      />
      <Route
        path={EXPERIMENTS_ROUTE}
        element={checkSignInWrapper(ExperimentsOverview)}
      />
      <Route
        path={SINGLE_EXPERIMENT_ROUTE}
        element={checkSignInWrapper(ExperimentDetailed)}
      />
      <Route
        path={`/parea-testing${EXPERIMENTS_ROUTE}`}
        element={checkSignInWrapper(ExperimentsOverview)}
      />
      <Route
        path={`/parea-testing${SINGLE_EXPERIMENT_ROUTE}`}
        element={checkSignInWrapper(ExperimentDetailed)}
      />
      <Route
        path={EXPERIMENT_COMPARE_ROUTE}
        element={checkSignInWrapper(ExperimentCompare)}
      />
      <Route
        path={getPublicExperimentsRoute()}
        element={<PublicExperimentsOverview />}
      />
      <Route
        path={getPublicSingleExperimentRoute()}
        element={<PublicExperimentDetailed />}
      />
      <Route path={`${PUBLIC_LOGS_ROUTE}/:id`} element={<TraceExplorer />} />
      <Route
        path={ANNOTATION_QUEUE_DETAILED_ROUTE}
        element={checkSignInWrapper(AnnotationQueueDetailed)}
      />
      <Route
        path={ANNOTATION_QUEUE_ROUTE}
        element={checkSignInWrapper(AnnotationOverview)}
      />
      <Route
        path={ANNOTATION_CRITERION_DETAILED_ROUTE}
        element={checkSignInWrapper(AnnotationCriterionDetailed)}
      />
      <Route path="/auth/callback" element={<Callback />} />
      <Route path="/welcome" element={<WelcomePage currentStep={1} />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

function checkSignInWrapper(Component: React.FC) {
  return (
    <>
      <SignedIn>
        <Component />
      </SignedIn>
      <SignedOut>
        <SignInPage />
      </SignedOut>
    </>
  );
}
