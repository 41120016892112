import { CheckIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import * as React from 'react';

export type LabSession = {
  id: number,
  name: string,
  created_at: string,
  last_updated_at: string,
  evaluation_metric_ids: number[],
  version_ids: number[],
  // each list represents a column with length equal to the number of rows
  result_ids: number[][] | null,
};

export type SessionObj = {
  [key: number]: LabSession,
};

export type LogProbability = {
  token: string,
  logprob: number,
};

export type SelectedSession = {
  id: number,
  item: LabSession,
};

export type ResponseFormat = {
  [key: string]: string | null,
};

export type ModelParams = {
  model: string,
  temp: number,
  top_p: number,
  frequency_penalty: number,
  presence_penalty: number,
  max_length: number | null,
  response_format: ResponseFormat | null,
  safe_prompt: boolean | null,
};

export type Scores = {
  [key: number]: number,
};

export type Detail = {
  content: string,
  tokens: number,
  cost: number,
  latency: number,
  scores: Scores | null,
  feedback: 'good' | 'bad' | null,
  logprobs: LogProbability[] | null,
  perplexity: number | null,
};

export type ExampleDict = {
  [key: string]: string,
};

export type InputUnit = {
  inputs: ExampleDict,
  target: string | null,
  test_case_id: number | null,
  tags: string[],
  collection_name: string | null,
  collection_id: number | null,
};

export type ResultDetails = {
  details: {
    example_inputs: ExampleDict,
    original_details: Detail,
    optimized_details: Detail,
  }[],
};

export type Result = {
  id: number,
  content: string,
  example_inputs: ExampleDict | null,
  test_case_id: number | null,
  tokens: number | null,
  latency: number | null,
  cost: number,
  scores: Scores | null,
  feedback: 'good' | 'bad' | null,
  logprobs: LogProbability[] | null,
  perplexity: number | null,
};

export type InferenceResults = {
  input_units: InputUnit[],
  results: Result[][],
};

export type Inference = {
  [key: number]: {
    content: string,
    latency: number,
    cost: number,
    completion_tokens: number,
    resultId: number | null,
    loading: boolean,
    evalLoading: boolean,
    scores: Scores | null,
    reasons: Record<number, string> | null,
    feedback: 'good' | 'bad' | null,
    logprobs: LogProbability[] | null,
    perplexity: number | null,
    last_run?: Date | null,
  },
};

export type InferenceObj = {
  [key: number]: Inference,
};

export type Message = {
  content: string,
  role: 'user' | 'assistant' | 'system' | 'tool' | 'function',
};

export type MessageObj = {
  [key: number]: Message,
};

export type Query = {
  query_id: number,
  version_id: number,
  version_number: number,
  versions_list: {
    version_id: number,
    version: number,
  }[],
  messages: MessageObj,
  result_details: ResultDetails,
  name: string,
  result_params: ModelParams,
  type: 'prompt' | 'template',
  rating: 'good' | 'bad' | null,
  tags: string[],
  favorite: boolean,
  score: number,
  lab_session_ids: number[],
  function_ids: Set<number> | null,
  created_at: string,
};

export type SelectedQuery = Query & {
  selected_session_id: number,
  function_call: string,
};

export type ReDeployedVersion = {
  deployment_id: string,
  version_id: number,
  version_number: number,
  active: boolean,
  name: string | null,
  messages: MessageObj,
  input_variables: string[] | null,
  function_ids: number[] | null,
  evaluation_metric_ids: number[] | null,
  function_call: string | null,
  model_type: string,
  provider: string,
  is_azure: boolean,
  model_parameters: ModelParams,
  deployer_name: string,
  commit_message: string | null,
  event: 'create' | 'bump' | 'revert',
  from_version: number,
};

export type LabSessionDetails = {
  session_id: number,
  name: string,
};

export type HistoryItem = {
  deployment_id: string,
  version_id: number,
  created_at: string,
  updated_at: string,
  event: 'create' | 'bump' | 'revert',
  to_version: number,
  from_version: number,
  deployer_name: string,
  commit_message: string | null,
};

export type DeployedVersion = ReDeployedVersion & {
  deployer_name: string,
  organization_id: string,
  lab_sessions: LabSessionDetails[],
  history_items: HistoryItem[],
  created_at: string | null,
  last_updated_at: string | null,
};

export type DeployedPromptTable = {
  id: number,
  deployment_id: string,
  version_number: number,
  name: string | null,
  inputs: string[] | null,
  has_functions: boolean,
  model: string,
  provider: string,
  model_parameters: ModelParams,
  created_at: string,
  last_updated_at: string,
  deployer_name: string,
  temperature: number,
  lab_sessions: string[],
  max_tokens: number | string,
};

export type QueriesResponse = {
  queries: Query[],
};

export type VersionObj = {
  [key: number]: Query,
};

export type VersionItemsObj = {
  [key: number]: SelectedQuery,
};

export type DeployedVersionObj = {
  [key: number]: DeployedVersion,
};

export type InputsCellType = {
  keys: string[],
  values: string[],
  target: string | null,
  testCaseId: number | null,
  tags: string[],
  collectionName: string | null,
  collectionId: number | null,
};

export type InputsCellsObj = {
  [key: number]: InputsCellType,
};

export type FunctionType = {
  id: number,
  parent_id: number | null,
  name: string,
  description: string,
  signature: string,
  tokens: number,
  content: string | null,
  created_at: string,
  version_number: number,
  versions_list: {
    version_id: number,
    version: number,
  }[],
};

export type LabFunctionType = {
  id: number,
  parent_id: number | null,
  name: string,
  description: string,
  content: string | null,
  version_number: number,
};

export type FunctionRequest = {
  name: string,
  description: string | null,
  signature: string | null,
  content: string,
  tokens: number | null,
};

export type FunctionVersionUpRequest = {
  function_id: number,
  parent_id: number | null,
  name: string | null,
  description: string | null,
  signature: string | null,
  content: string | null,
  tokens: number | null,
};

export type FunctionObj = {
  [key: number]: FunctionType,
};

export type LabFunction = {
  id: number,
  parent_id: number | null,
  versions_list: {
    version: number,
    id: number,
  }[],
  name: string,
  description: string,
  selected_version: number,
  version_number: number,
  is_checked: boolean,
  content: string | null,
  created_at: string,
};

export type LabFunctionObj = {
  [key: number]: LabFunction,
};

export type LabDrawerFunctionObj = {
  [key: number]: LabFunctionObj,
};

export type FunctionState = {
  error: string | null,
  loading: boolean,
  functions: FunctionObj,
  labFunctionDrawer: LabFunctionObj,
  labFunctionDrawerShown: LabDrawerFunctionObj,
  functionContent: {
    [key: string]: string,
  },
  labOverviewShown: LabFunctionObj,
  versionUp: boolean,
};

export type NewSessionState = {
  session: LabSession,
  versionItems: VersionItemsObj,
  inputsCells: InputsCellsObj,
  inferenceCells: InferenceObj,
};

export type CreateLabSessionComment = {
  lab_session_id: number,
  user_id: string,
  organization_id: string,
  author_name: string,
  comment: string,
};

export type GetLabSessionComments = {
  lab_session_id: number,
  organization_id: string,
};

export type LabSessionComment = CreateLabSessionComment & {
  id: number,
  created_at: string,
};

export type CommentObj = {
  // comment_id as key
  [key: number]: LabSessionComment,
};

export type LabSessionCommentsObj = {
  // lab_session_id as key
  [key: number]: CommentObj,
};

export type LabState = {
  error: string | null,
  loading: boolean,
  saving: boolean,
  validationError: string | null,
  showingLogprobs: boolean,
  pastVersions: VersionObj,
  sessions: SessionObj,
  selectedSession: SelectedSession,
  versionItems: VersionItemsObj,
  inputsCells: InputsCellsObj,
  inferenceCells: InferenceObj,
  openInLabData: NewSessionState,
  sessionComments: LabSessionCommentsObj,
};

export type StudioState = {
  loading: boolean,
  error: string | null,
  itemLoading: boolean,
  itemLoadingId: number | null,
  currentPage: number,
  itemsPerPage: number,
  detailedItem: VersionObj,
  pastVersions: VersionObj,
  deployedVersions: DeployedVersionObj,
  deployedVersionIds: Set<number>,
  recentVersions: VersionObj,
  sharedVersions: VersionObj,
  showAllBaseVersions: boolean,
};

export type QueryState = {
  result: string | null,
  query: string | null,
  messages: MessageObj,
  variables: string[],
  examples: string[],
  cost: number,
  error: string | null,
  loading: boolean,
  queryTokens: number,
  totalExampleTokens: number,
  validationError: string | null,
  params: ModelParams,
};

export type InferenceRequest = {
  column: number,
  row: number,
  unformatted_messages: Message[],
  version_id: number | null,
  example_inputs: ExampleDict | null,
  test_case_id: number | null,
  openai_api_key?: string,
  anthropic_api_key?: string,
  messages: Message[],
  model: string,
  temp: number,
  top_p: number,
  frequency_penalty: number,
  presence_penalty: number,
  max_length: number | null,
  response_format: ResponseFormat | null,
  safe_prompt: boolean | null,
  function_ids: number[] | null,
  functions: FunctionType[],
  function_call: string | null,
  organization_id: string | null,
  name: string | null,
};

export type InferenceResponse = {
  completion: string,
  column: number,
  row: number,
  latency: number,
  completion_tokens: number,
  result_id: number | null,
  cost: number,
  logprobs: LogProbability[] | null,
  perplexity: number | null,
};

export type LabSessionRow = {
  example_inputs: ExampleDict,
  completion: string | null,
  result_id: number | null,
  test_case_id: number | null,
  latency: number,
  cost: number,
  completion_tokens: number,
  logprobs: LogProbability[] | null,
  perplexity: number | null,
};

export type ConfigurationUnit = {
  version_id: number | null,
  content: string,
  messages: Message[],
  // each inputsCell row and inference pair
  example_inputs_completions: LabSessionRow[],
  model: string,
  temp: number,
  top_p: number,
  frequency_penalty: number,
  presence_penalty: number,
  max_length: number | null,
  response_format: ResponseFormat | null,
  safe_prompt: boolean | null,
};

export type SaveLabSessionRequest = {
  id: number | null,
  name: string,
  evaluation_metric_ids: number[],
  // each column and associated rows
  configurations: ConfigurationUnit[],
};

export type ResultSchema = {
  id: number,
  content: string,
  example_inputs: ExampleDict | null,
  test_case_id: number | null,
  tokens: number | null,
  latency: number | null,
  cost: number | null,
  scores: Scores | null,
  feedback: 'good' | 'bad' | null,
};

export type LabVersionResponse = {
  version_id: number,
  version_number: number,
  versions_list: {
    version_id: number,
    version: number,
  }[],
  result_params: ModelParams,
  results: ResultSchema[],
  messages: Message[],
  name: string,
  query_id: number,
  function_ids: number[] | null,
  function_call: string | null,
};

export type SaveLabSessionResponse = {
  id: number,
  versions: LabVersionResponse[],
  result_ids: number[][],
};

export type CreateTestCaseCollectionResponse = {
  /**
   * Unique identifier for the dataset
   */
  id: number,

  /**
   * Timestamp when the dataset was last updated
   */
  last_updated_at: string,

  /**
   * List of created test case IDs in the dataset
   */
  test_case_ids: number[],
};

export type TestCase = {
  id?: number,
  test_case_collection_id?: number,
  inputs: Record<string, string>,
  target?: string,
  tags?: string[],
  trace_id?: string,
};

export type TestCases = {
  [key: number]: TestCase,
};

export type TestCaseCollectionOverview = {
  id: number,
  name: string,
  column_names: string[],
  created_at: string,
  last_updated_at: string,
};

export type TestCaseCollectionsResponse = {
  test_case_collections: Record<number, TestCaseCollectionOverview>,
};

export type TestCaseCollectionResponse = TestCaseCollectionOverview & {
  test_cases: Record<number, TestCase>,
};

export type InternalTestCase = {
  id?: number,

  [columnName: string]: string,

  Target?: string,
  Tags?: string[],
};

export type TestCaseCollection = {
  id: number,
  name: string,
  createdAt: string,
  lastUpdatedAt: string,
  testCases: TestCases | null,
  columnNames: string[] | null,
};

export type TestCaseCollectionsObj = {
  [key: number]: TestCaseCollection,
};

export type TestCaseState = {
  error: string | null,
  loading: boolean,
  testCaseCollections: TestCaseCollectionsObj,
};

export type EvalMetric = {
  id: number,
  name: string,
  type: 'webhook' | 'function',
  webhookUrl: string | null,
  functionCode: string | null,
  created_at: string,
  last_updated_at: string,
  is_auto_eval: boolean,
};

export type NewEvalMetric = {
  id: number,
  created_at: string,
  last_updated_at: string,
  auto_eval_function_code: string | null,
};

export type EvalMetricsObj = {
  [key: number]: EvalMetric,
};

export type EvalMetricState = {
  error: null,
  loading: false,
  evalMetrics: EvalMetricsObj,
};

export type EvaluateRequestUnit = {
  inputs: ExampleDict,
  completion: string,
  target: string | null,
  result_id: number | null,
  column: number,
  row: number,
};

export type EvaluateResponse = {
  scores: Scores | null,
  reasons: Record<number, string> | null,
  column: number,
  row: number,
};

export type ProviderAPISchema = {
  model_provider: 'openai' | 'anthropic' | 'azure',
  api_key: string,
  endpoint: string | null,
  deployment_names: string[] | null,
};

export type EvalJobOutcome = {
  id: number,
  averageScores: Scores,
  averageLatency: number,
  averageCost: number,
  status: 'pending' | 'running' | 'completed' | 'failed',
  error: string | null,
  successfulEvaluations: number,
  totalSeconds: number,
};

export type EvalJobOutcomeObj = {
  [key: number]: EvalJobOutcome,
};

export type EvalJob = {
  id: number,
  name: string,
  testCaseCollectionId: number,
  testCaseIds: number[],
  evalMetricIds: number[],
  versionIds: number[],
  outcomes: EvalJobOutcomeObj,
  createdAt: string,
};

export type EvalJobsObj = {
  [key: number]: EvalJob,
};

export type EvalJobResult = {
  resultId: number,
  evaluationJobRunId: number,
  evaluationJobOutcomeId: number,
  versionId: number,
  testCaseId: number,
  scores: Scores,
  feedback: 'good' | 'bad' | null,
  output: string,
  latency: number,
  cost: number,
};

export type EvalJobResultsObj = {
  [key: number]: EvalJobResult,
};

export type TestCollectionToEvalJobResultsObj = {
  [key: number]: EvalJobResultsObj,
};

export type EvalJobState = {
  error: string | null,
  loading: boolean,
  evalJobs: EvalJobsObj,
  testCollectionIdToEvalJobResults: TestCollectionToEvalJobResultsObj,
};

export type VersionState = {
  error: string | null,
  loading: boolean,
  versions: VersionObj,
};

export type EvaluationScoreSchema = {
  id: number | null,
  name: string,
  score: number,
  reason?: string,
};

export type TraceLog = {
  trace_id: string,
  start_timestamp: string,
  organization_id: string,
  error: string | null,
  status: string | null,
  deployment_id: string | null,
  cache_hit: boolean,
  configuration: {
    model: string | null,
    provider: string | null,
    model_params: ModelParams | null,
    messages: Message[] | null,
    functions: FunctionType[] | null,
    function_call: string | { [key: string]: string } | null,
  },
  latency: number,
  time_to_first_token: number,
  input_tokens: number,
  output_tokens: number,
  total_tokens: number,
  cost: number,
  evaluation_metric_ids: number[] | null,
  scores: EvaluationScoreSchema[] | null,
  feedback_score: number | null,
  trace_name: string,
  end_timestamp: string,
  end_user_identifier: string | null,
  session_id?: string,
  metadata: { [key: string]: string } | null,
  target: string | null,
  tags: string[] | null,
  inputs: { [key: string]: string },
  output: string,
};

export type LogData = {
  inference_id: string,
  organization_id: string,
  trace_id: string | null,
  trace_name: string | null,
  session_id: string | null,
  end_user_identifier: string | null,
  error: string | null,
  status: string,
  start_timestamp: string,
  end_timestamp: string,
  duration: number,
  deployment_id: string | null,
  name: string | null,
  evaluation_metric_ids: number[] | null,
  user_defined_metadata: { [key: string]: string } | null,
  cache_hit: boolean,
  llm_inputs: { [key: string]: string } | null,
  model_type: string,
  provider: string,
  model_parameters: { [key: string]: string } | null,
  messages: { [key: string]: string }[],
  functions: { [key: string]: string }[] | null,
  function_call: string | { [key: string]: string } | null,
  output: string,
  latency: number,
  time_to_first_token: number,
  input_tokens: number,
  output_tokens: number,
  total_tokens: number,
  cost: number,
  target: string | null,
  tags: string[] | null,
  feedback_score: number | null,
};

export type PideSession = {
  id: number,
  name: string,
  source_code: string,
  trace_ids: string[],
  inputs: ExampleDict,
  logs: LogData[][],
  created_at: string,
  last_updated_at: string,
};

export type PideSessionObj = {
  [key: number]: PideSession,
};

export type PideState = {
  error: string | null,
  loading: boolean,
  saving: boolean,
  selectedSessionId: number | null,
  sessions: PideSessionObj,
  sourceCode: string,
  logData: LogData[][],
  inputsCells: InputsCellsObj,
};

export type DataContextValue = {
  fetchedPastVersions: QueriesResponse | null,
  fetchedSessionData: LabSession[] | null,
  fetchedTestCaseCollectionData: Record<
    number,
    TestCaseCollectionOverview,
  > | null,
  fetchedEvalMetrics: EvalMetricsObj | null,
  fetchedEvalJobs: EvalJobsObj | null,
  fetchedPideSessionsData: PideSessionObj | null,
};

export type Status = {
  value: string,
  label: string,
  icon: React.FC,
};

export const statuses: Status[] = [
  {
    value: 'success',
    label: 'Success',
    icon: CheckIcon,
  },
  {
    value: 'error',
    label: 'Error',
    icon: CrossCircledIcon,
  },
];

export type SummaryLog = {
  id: string,
  name: string,
  inputs: string,
  output: string,
  latency: number,
  tokens: number,
  cost: number,
  startedAt: string,
  status: string,
  error: string | null,
  feedback_score: number | null,
  user: string | null,
  session_id?: string,
  deployment_id: string | null,
  metadata: { [key: string]: string } | null,
  isChain: boolean,
  subRows: SummaryLog[],
  parent_id?: string,
};

export type TraceLogTree = TraceLog & {
  children: TraceLogTree[] | null,
  subRows: TraceLogTree[],
  parent_id: string | null,
};

export type SortOptions = {
  defaultVal: string,
  options: { value: string, name: string }[],
};

export type OverviewCard = {
  id: string,
  user_id?: string,
  name: string,
  body: string[],
  created_at?: string,
  updated_at?: string,
};

export type OverviewCardConfig = {
  cards: OverviewCard[],
  actionButtons: React.ReactNode,
  FooterButtons: React.FC,
  sortOptions: SortOptions,
  pageName: string,
  badge?: string,
  emptyStateMsg: string,
  emptyStateDesc: string,
  handleCardClick: (id: any) => void,
  emptyStateAction: () => void,
  emptyStateActionText: string,
};

export type OverviewSectionButtonFooterProps = {
  itemId: number,
};

export type Name = 'Free' | 'Team' | 'Enterprise';

export type Plan = {
  name: Name,
  cta: string,
  desc: string,
  price: number | string,
  priceAnnual: number | string,
  isMostPop: boolean,
  priceIdMonth: string,
  priceIdYear: string,
  features: string[],
  usageLimits: {
    deployedPrompts: number | null,
    loggedEvents: number | null,
    members: number | null,
  },
};

export type RunEvalMetricRequest = {
  functionCode: string,
  units: {
    resultId: number,
    inputs: ExampleDict,
    completion: string,
    target: string | null,
  }[],
};

export type RunEvalMetricResponse = {
  [key: number]: {
    score: number | null,
    error: string | null,
  },
};

export type TimelineItemData = {
  id: number,
  children: TimelineItemData[],
  variant: 'default' | 'child' | 'lastChild',
  hasChildren: boolean,
  selected: boolean,
  title: string,
  latency: string,
  numberOfTokens: string,
  cost: string,
  status: 'success' | 'error',
};

export type PlaygroundDeployedVersion = {
  deploymentId: string,
  parentDeploymentName: string,
  bumpOrRevert: 'Bump' | 'Revert',
  versionToRemove: number,
  previousVersionNumber: number,
};

export type ParentDeploymentNameAndRedeployType = {
  playgroundDeployedVersions: Record<string, PlaygroundDeployedVersion>,
};

export type StatPinnedToExperimentNoValue = {
  var1: string,
  var2: string | null,
  operation:
    | 'Mean'
    | 'Median'
    | 'Variance'
    | 'Std'
    | 'MSE'
    | 'MAE'
    | 'Correlation'
    | 'Spearman'
    | 'Accuracy'
    | 'Custom',
};

export type StatPinnedToExperiment = StatPinnedToExperimentNoValue & {
  value: number,
};

export type ParentTraceStats = {
  traceId: string,
  latency: number,
  inputTokens: number,
  outputTokens: number,
  totalTokens: number,
  cost: number,
  scores: EvaluationScoreSchema[],
};

export type ParentTracesStatsObj = {
  [key: string]: ParentTraceStats,
};

export type Experiment = {
  uuid: string,
  name: string,
  runName: string,
  createdAt: string,
  numSamples: number,
  parentTraceStats: ParentTracesStatsObj,
  pinnedStats: StatPinnedToExperiment[],
  isPublic: boolean,
  status: 'pending' | 'running' | 'completed' | 'failed',
  metadata: { [key: string]: string },
};

export type ExperimentsObj = {
  [key: string]: Experiment,
};

export type ComparisonScoredResults = {
  last_accessed: string,
  url: string,
  experiment_uuids: string[],
};

export type ExperimentState = {
  error: string | null,
  loading: boolean,
  experiments: ExperimentsObj,
  publicExperiments: ExperimentsObj,
  recentComparisons: ComparisonScoredResults[],
};

export type DashboardStat = {
  time: string,
  [key: string]: number,
};

export type DashboardStatObj = {
  requestsStats: DashboardStat[],
  latencyStats: DashboardStat[],
  totalCost: DashboardStat[],
  tokensStats: DashboardStat[],
  feedbackStats: DashboardStat[],
  avgScores: DashboardStat[],
};

export type TimeRangeToStatObj = {
  [key: string]: DashboardStatObj,
};

export type DashboardState = {
  error: string | null,
  loading: boolean,
  stats: TimeRangeToStatObj,
};

export type EvalInputsValues = {
  label: string,
  value: string | number | boolean | null,
};

export type EvalInputsObj = {
  name: string,
  desc: string,
  values: EvalInputsValues[] | null,
  number_values: boolean | null,
  default: string | number | boolean | null,
  max: number | null,
  step: number | null,
};

export type EvalObj = {
  name: string,
  description: string,
  required: EvalInputsObj[],
  optional: EvalInputsObj[],
  additionalContext: boolean,
  openAIOnly: boolean,
  requiresTarget: boolean,
};

export type EvalUseCase = {
  type: string,
  description: string,
  EVALS: EvalObj[],
};

export type Project = {
  uuid: string,
  name: string,
  createdAt: string,
};

export type ProjectsObj = {
  [key: string]: Project,
};

export type ProjectState = {
  error: string | null,
  loading: boolean,
  projects: ProjectsObj,
  curProjectUuid: string,
};

export type ExperimentComparisonAnnotation = {
  defined_annotation_id: number,
  score: number,
  value: string | null,
  name: string,
};

export type ComparisonData = {
  trace_id: string,
  output: string,
  scores: EvaluationScoreSchema[],
  annotations: ExperimentComparisonAnnotation[],
  metadata: { [key: string]: string },
  total_tokens: number,
  latency: number,
  cost: number,
};

export type ComparisonSupportingNTrials = {
  mean_or_single: ComparisonData,
  all_results: ComparisonData[],
};

export type ExperimentComparisonRow = {
  target: string,
  inputs: { [key: string]: any },
  eval_names: string[],
  annotation_names: string[],
  experiments: {
    [key: string]: ComparisonSupportingNTrials,
  },
};

export type DeploymentParamsObj = {
  model: string,
  model_params: ModelParams,
  functions: {
    id: number,
    name: string,
    content: string,
    created_at: string,
    version: number,
  }[],
  function_call: string,
};

export type DeploymentHeaderObj = {
  name: string,
  deploymentId: string,
  deployer: string,
  version: number,
  badgeText: string,
};

export type UserInfo = {
  id: string,
  name: string,
  profileUrl: string,
};

export type UserInfoObj = {
  [key: string]: UserInfo,
};

export type OrgUsersState = {
  error: string | null,
  loading: boolean,
  users: UserInfoObj,
};

export type CreateDatasetReference = {
  trace_id: string,
  collection_id: number,
  collection_name: string,
  test_case_id: number,
  organization_id: string,
};

export type DatasetReference = CreateDatasetReference & {
  id: number,
  created_at: string,
};

export type GetDatasetReferences = {
  organization_id: string,
  from_field: 'trace_id' | 'collection_id' | 'test_case_id',
  trace_id: string | null,
  collection_id: number | null,
  test_case_id: number | null,
};

export type DatasetReferenceObj = {
  // dataset_reference_id as key
  [key: number]: DatasetReference,
};

export type TraceDatasetReferencesObj = {
  // trace_id as key
  [key: string]: DatasetReferenceObj,
};

export interface APIResponse<T> {
  data: T;
  status: number;
}

export type AnnotationQueueItem = {
  annotation_queue_item_uuid: string,
  trace_id: string,
};

export type CreateAnnotationQueue = {
  name: string,
};

export type AnnotationQueue = CreateAnnotationQueue & {
  uuid: string,
  items: AnnotationQueueItem[],
  created_at: string,
  updated_at: string,
};

export type DoneAnnotationQueueItem = AnnotationQueueItem & {
  doneAt: Date,
};

export type AnnotationQueueUUID = string;
export type AnnotationQueueItemUUID = string;

export type DoneAnnotationQueue = Record<
  AnnotationQueueItemUUID,
  DoneAnnotationQueueItem,
>;

export type CreateDefinedAnnotation = {
  name: string,
  type: 'continuous' | 'categorical',
  min_value?: number | null,
  max_value?: number | null,
  value_score_map?: Record<number, string> | null,
};

export type BootstrapEvalSchema = {
  stats: Record<string, number>,
  experiment_uuid: string,
  source_code: string,
  status: 'pending' | 'running' | 'completed' | 'failed',
  output_target_pairs: { output: number, target: number }[],
};

export type DefinedAnnotation = CreateDefinedAnnotation & {
  id: number,
  description: string,
  annotationCount: number,
  cur_bootstrapped_eval_id: number | null,
  bootstrapped_evals: Record<number, BootstrapEvalSchema>,
};

export type DefinedAnnotationsObject = {
  [key: number]: DefinedAnnotation,
};

export type DefinedAnnotationsState = {
  usedAnnotationIds: number[],
  definedAnnotations: DefinedAnnotationsObject,
  loading: boolean,
  error: string | null,
};

export type UploadedAnnotationRow = {
  inputs: { [key: string]: any },
  output: string,
  score: number,
  value: string | null,
};

export type UpdateEvaluationScore = {
  trace_id: string,
  name: string,
  score: number,
  reason?: string | null,
  experiment_uuid?: string | null,
};

export type EvaluationScoreObj = {
  // key is evaluation score name
  [key: string]: EvaluationScoreSchema,
};

export type TraceLogImage = {
  url: string,
  caption: string | null,
};

export type TraceLogComment = {
  id: number,
  user_id: string,
  comment: string,
  created_at: string,
};

export type TraceLogAnnotation = TraceLogComment & {
  defined_annotation_id: number | null,
  score: number | null,
  value: string | null,
  annotation_name: string,
  trace_id: string,
  user_email_address: string,
};

export type TraceTree2 = {
  configuration: {
    model: string | null,
    provider: string | null,
    model_params: ModelParams | null,
    messages: Message[] | null,
    functions: FunctionType[] | null,
    function_call: string | { [key: string]: string } | null,
  },
  latency: number,
  time_to_first_token: number,
  input_tokens: number,
  output_tokens: number,
  total_tokens: number,
  cost: number,
  trace_id: string,
  start_timestamp: string,
  parent_trace_id: string | null,
  root_trace_id: string | null,
  organization_id: string,
  error: string | null,
  status: string,
  inputs: Record<string, string> | null,
  output: string,
  deployment_id: string | null,
  cache_hit: boolean,
  output_for_eval_metrics: string | null,
  evaluation_metric_names: string[],
  scores: EvaluationScoreSchema[] | null,
  feedback_score: number | null,
  trace_name: string,
  children: string[],
  children_ids: number[],
  end_timestamp: string,
  end_user_identifier: string | null,
  session_id?: string,
  metadata: Record<string, string> | null,
  tags: string[] | null,
  children_logs?: TraceTree2[],
  experiment_uuid: string | null,
  target: string | null,
  images: TraceLogImage[] | null,
  annotations: Record<number, Record<string, TraceLogAnnotation>> | null,
  comments: TraceLogComment[] | null,
};

// PaginatedResponse represents the paginated structure returned from the backend
export interface PaginatedResponse<T> {
  total: number;
  page: number;
  total_pages: number;
  page_size: number;
  results: T[];
}

// FilterQueryParams represents the filterable fields in the query
export interface FilterQueryParams {
  filter_field?: string;
  filter_operator?: string;
  filter_value?: string;
}

type KVPairs = Record<string, string>;

export type NewTestCaseItem = {
  inputs: KVPairs[],
  output: string | null,
  tags: string[],
};

export type NewTestCaseProps = {
  newTestCases: Record<number, NewTestCaseItem>,
  closeAll: () => void,
};

export type TestCaseAddition = {
  testCases: TestCase[],
  name: string | null,
  existingCollectionId: string | null,
  keys: string[],
};

export type VersionReference = {
  version_id: number,
  version: number,
};

export type NewVersionIdsResponseSchema = {
  query_id: number,
  version_id: number,
  version_number: number,
  versions_list: VersionReference[],
};
