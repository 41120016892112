import * as SubframeCore from '@subframe/core';
import React from 'react';

interface TooltipRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string;
  className?: string;
}

const TooltipRoot = React.forwardRef(function TooltipRoot(
  { children = 'Tooltip contents', className, ...otherProps }: TooltipRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'border border-solid border-neutral-border rounded flex-col gap-2 shadow pt-1 pr-2 pb-1 pl-2 items-start flex bg-default-background group/ccebd1e9',
        className
      )}
      ref={ref}
      {...otherProps}
    >
      {children ? (
        <span className="text-default-font text-label font-label">
          {children}
        </span>
      ) : null}
    </div>
  );
});

export const Tooltip = TooltipRoot;
