import Lottie from 'lottie-react';
import * as chicky from '../../assets/lottie/Polite Chicky.json';
import * as hamster from '../../assets/lottie/Aniki Hamster.json';
import * as plane from '../../assets/lottie/Paper Airplane.json';
import * as PartyParrot from '../../assets/lottie/PartyParrot.json';
import * as Pacman from '../../assets/lottie/Pacman.json';

type Animation =
  | typeof chicky
  | typeof hamster
  | typeof plane
  | typeof PartyParrot
  | typeof Pacman;

interface LoadingAnimationProps {
  title?: string;
  height?: number;
  width?: number;
  animation?: Animation;
  styles?: React.CSSProperties;
}

const LoadingAnimation = ({
  title,
  animation: defaultAnimation,
  height = 100,
  width = 100,
  styles = {},
}: LoadingAnimationProps) => {
  const animation = defaultAnimation || hamster;

  const style = { height: height, width: width, ...styles };

  return (
    <div className="flex flex-col items-center justify-center align-middle w-full space-y-4">
      <Lottie
        loop={true}
        autoplay={true}
        animationData={animation}
        style={style}
      />
      {title && (
        <p className="font-medium text-lg text-gray-900 dark:text-gray-100">
          {title}
        </p>
      )}
    </div>
  );
};

export default LoadingAnimation;
