import * as SubframeCore from '@subframe/core';
import React, { useRef, useState } from 'react';
import { Tooltip } from './Tooltip';
import { useToast } from '@chakra-ui/react';

const FormattedCopyToClipboard = ({ className, children, original }) => {
  const [isCopied, setIsCopied] = useState(false);
  const textRef = useRef(null);
  const toast = useToast();

  const handleCopy = () => {
    // const text = textRef.current.textContent;
    navigator.clipboard.writeText(original).then(
      () => {
        setIsCopied(true);
        toast({
          title: 'Copied to clipboard',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => setIsCopied(false), 2000); // reset after 2 seconds
      },
      (err) => {
        console.error('Failed to copy text: ', err);
        toast({
          title: 'Error copying to clipboard',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    );
  };

  return (
    <SubframeCore.Tooltip.Provider>
      <SubframeCore.Tooltip.Root>
        <div className={SubframeCore.twClassNames('relative group')}>
          <div
            ref={textRef}
            className={SubframeCore.twClassNames(
              'border border-solid border-neutral-border rounded gap-1.5 pt-2 pr-4 pb-0.5 pl-2 items-start flex bg-neutral-800 group/1e2e1a38',
              className
            )}
          >
            <span className="grow shrink-0 basis-0 w-full text-subtext-color text-monospace-body font-monospace-body whitespace-pre-wrap">
              {children}
            </span>
          </div>
          <SubframeCore.Tooltip.Trigger asChild={true}>
            <button
              onClick={handleCopy}
              className={SubframeCore.twClassNames(
                'w-8 h-8 rounded flex-col gap-2 items-center justify-center flex cursor-pointer group/e8c76626 hover:bg-neutral-800 absolute top-0 right-0 my-2 mr-4'
              )}
            >
              <SubframeCore.Icon
                className="text-subtext-color text-body font-body group-hover/e8c76626:text-default-font"
                name="FeatherClipboard"
              />
            </button>
          </SubframeCore.Tooltip.Trigger>
        </div>
        <SubframeCore.Tooltip.Portal>
          <SubframeCore.Tooltip.Content
            side="bottom"
            align="center"
            sideOffset={8}
            asChild={true}
          >
            <Tooltip>{isCopied ? 'Copied!' : 'Copy to clipboard'}</Tooltip>
          </SubframeCore.Tooltip.Content>
        </SubframeCore.Tooltip.Portal>
      </SubframeCore.Tooltip.Root>
    </SubframeCore.Tooltip.Provider>
  );
};

export default FormattedCopyToClipboard;
