import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button as CButton, useColorModeValue } from '@chakra-ui/react';
import { Check, Copy, FileCode2 } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import {
  CodeEditor,
  CodeEditorPasteTheme,
} from '@twilio-paste/core/code-editor-library';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../../components/ui/tabs';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { Label } from '../../components/ui/label';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../components/ui/avatar';
import openAILogoWhite from '../../assets/openai-white-logomark.png';
import openAILogo from '../../assets/openai-logomark.png';
import anthropicLogo from '../../assets/anthropic-logo.png';
import { cn } from '../../lib/utils';
import { supportedLangToIDELang } from './IntegrationCodeSnippet';
import { KeyFormDialog, ProviderAvatar } from '../Settings/Settings';
import { Separator } from '../../components/ui/separator';
import { useUserContext } from '../../store/UserContext';
import { Input } from '../../components/ui/input';
import { IoRocket } from 'react-icons/io5';
import { Button } from '../../components/ui/button';
import type { DeployedVersion } from '../../utils/types';
import { ConfigurationUnit } from '../../utils/types';
import { useAuthManager } from '../../hooks/auth/useAuthManager';
import { createNanoId } from '../../utils/nano_id';
import useDeploymentEndpoints from '../../hooks/studio/useDeploymentEndpoints';
import { useSuccessfulToast } from '../../hooks/useSuccessfulToast';
import useLabApiEndpoints from '../../hooks/lab/useLabApiEndpoints';
import { useAnalytics } from '../../hooks/usePageVisitedAnalytics';
import { ReloadIcon } from '@radix-ui/react-icons';

type SupportedLanguages = 'typescript' | 'python' | 'curl';

type Providers = 'openai' | 'anthropic';
type TabOptions = 'completion' | 'deployment';

const generateCodeSnippet = (
  apiKey: string,
  provider: Providers,
  language: SupportedLanguages,
  tabSelection: TabOptions,
  deploymentId?: string
) => {
  const snippets = {
    python: {
      completion: `from parea import Parea, trace
from parea.schemas import Completion, CompletionResponse, LLMInputs, Message, Role

p = Parea(api_key="${apiKey}")


@trace
def main() -> str:
    completion: CompletionResponse = p.completion(
        data=Completion(
            llm_configuration=LLMInputs(
                model="${provider === 'openai' ? 'gpt-4o-mini' : 'claude-3-haiku-20240307'}",
                messages=[Message(role=Role.user, content="Write a haiku about the moon")],
            )
        )
    )
    return completion.content`,
      deployment: `from parea import Parea, trace
from parea.schemas import CompletionResponse, Completion

p = Parea(api_key="${apiKey}")

@trace
def main() -> str:
    completion: CompletionResponse = p.completion(
        Completion(deployment_id="${deploymentId}", llm_inputs={"topic": "the moon"})
    )
    return completion.content`,
    },
    typescript: {
      completion: `import { CompletionResponse, Parea, trace } from 'parea-ai';

const p = new Parea("${apiKey}");

const main = trace('main', async () => {
  const completion: CompletionResponse =  await p.completion({
    llm_configuration: {
      model: "${provider === 'openai' ? 'gpt-4o-mini' : 'claude-3-haiku-20240307'}",
      messages: [{"role": "user", "content": "Write a haiku about the moon"}],
    }});
  return completion.content
  },
);`,
      deployment: `import { CompletionResponse, Parea, trace } from 'parea-ai';

const p = new Parea("${apiKey}");

const main = trace('main', async () => {
  const completion: CompletionResponse =  await p.completion({
    deployment_id: '${deploymentId}',
    llm_inputs: { topic: "the moon"}});
  return completion.content
  },
);
`,
    },
    curl: {
      completion: `curl --location 'https://parea-ai-backend-us-9ac16cdbc7a7b006.onporter.run/api/parea/v1/completion' \\
--header 'Content-Type: application/json' \\
--header 'x-api-key: ${apiKey}' \\
--data '{
    "llm_configuration": {
        "model": "${provider === 'openai' ? 'gpt-4o-mini' : 'claude-3-haiku-20240307'}",
        "messages": [{"role": "user", "content": "Write a haiku about the moon"}]
    }
}'
`,
      deployment: `curl --location 'https://parea-ai-backend-us-9ac16cdbc7a7b006.onporter.run/api/parea/v1/completion' \\
--header 'Content-Type: application/json' \\
--header 'x-api-key: ${apiKey}' \\
--data '{
    "deployment_id": "${deploymentId}",
    "llm_inputs": { "topic": "the moon"}
}'`,
    },
  };
  return snippets[language][tabSelection];
};

const docLink = {
  completion: 'https://docs.parea.ai/observability/logging_and_tracing',
  deployment: 'https://docs.parea.ai/platform/deployment',
};

const PareaProxyCodeSnippet = ({ apiKey }) => {
  const { handleCopy } = useCopyToClipboard();
  const { user } = useAuthManager();
  const org_id = user?.organizationMemberships?.[0]?.organization?.id;

  const { createDeploymentPrompt } = useDeploymentEndpoints();
  const { saveNewVersion } = useLabApiEndpoints();
  const showSuccessToast = useSuccessfulToast();

  const initialLang: SupportedLanguages = 'curl';
  const [language, setLanguage] = useState(initialLang);
  const initialTab: TabOptions = 'completion';
  const [tab, setTab] = useState(initialTab);
  const initialProvider: Providers = 'openai';
  const [selectedProvider, setSelectedProvider] = useState(initialProvider);

  const [copied, setCopied] = useState(false);
  const [codeSnippet, setCodeSnippet] = useState('');
  const [deploymentId, setDeploymentId] = useState(null);
  const [deploymentIdForProvider, setDeploymentIdForProvider] = useState({
    openai: null,
    anthropic: null,
  });
  const [loading, setLoading] = useState(false);
  const openAILogoSrc = useColorModeValue(openAILogo, openAILogoWhite);
  const { orgOpenAIKey, orgAnthropicApiKey } = useUserContext();

  const { sendAnalytics } = useAnalytics();

  const sendClickEvent = async (event, option, step) => {
    await sendAnalytics('onboarding_click_events', {
      page: 'llm_proxy',
      event: event,
      option: option,
      step: step,
    });
  };

  const tabDescription = {
    completion:
      "Parea's performant LLM proxy gives you one API format for all of your models. Automatic caching, built-in logging, and more.",
    deployment:
      'Version controlled prompt management with deployments. Access any prompt by its deployment id.',
  };

  const editorBackground = useColorModeValue('light', 'paste');
  const editorRef = useRef(null);

  const value = useMemo(() => {
    return generateCodeSnippet(
      apiKey,
      selectedProvider,
      language,
      tab,
      deploymentId
    );
  }, [apiKey, deploymentId, selectedProvider, language, tab]);

  useEffect(() => {
    setCodeSnippet(value);
  }, [value]);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    monaco.editor.defineTheme('paste', CodeEditorPasteTheme);
    monaco.editor.setTheme(editorBackground);
  }

  const handleLanguageChange = (newLanguage: SupportedLanguages) => {
    setLanguage(newLanguage);
    sendClickEvent('language_change', newLanguage, 3);
  };

  const handleProviderChange = (newProvider: Providers) => {
    setSelectedProvider(newProvider);
    sendClickEvent('provider_change', newProvider, 2);
  };

  const handleTabChange = (newTab: TabOptions) => {
    setTab(newTab);
    sendClickEvent('tab_change', newTab);
  };

  const handleCopyCode = () => {
    handleCopy(value).then(() => {
      setCopied(true);
    });
    sendClickEvent('copy_code', `${tab}_${language}_${selectedProvider}`, 4);
  };

  const handleViewDocs = () => {
    const url = docLink[tab];
    window.open(docLink[tab], '_blank');
    sendClickEvent('view_docs', url, 5);
  };

  const deployer_name = useMemo(
    () => user?.fullName || user?.firstName || 'Anonymous',
    [user]
  );
  const model = useMemo(
    () =>
      selectedProvider === 'openai' ? 'gpt-4o-mini' : 'claude-3-haiku-20240307',
    [selectedProvider]
  );

  const handleDeployPrompt = useCallback(
    async () => {
      setLoading(true);
      const nano = await createNanoId();
      const deploymentId = `p-${nano}`;

      const configuration: ConfigurationUnit = {
        name: 'onboarding_test_prompt',
        example_inputs_completions: [{ topic: 'the moon' }],
        model: model,
      };
      const newVersionInfo = await saveNewVersion(configuration);

      const request: DeployedVersion = {
        deployer_name: deployer_name,
        deployment_id: deploymentId,
        organization_id: org_id,
        version_id: newVersionInfo.data.version_id,
        version_number: 0,
        active: true,
        name: 'onboarding_test_prompt',
        messages: [{ role: 'user', content: 'Write a haiku about {{topic}}' }],
        input_variables: ['topic'],
        model_type: model,
        provider: selectedProvider,
        is_azure: false,
        model_parameters: { model: model },
        commit_message: 'initial onboarding commit',
      };
      await createDeploymentPrompt(request);
      // timeout for 1 second to allow the backend to update the deployment
      setTimeout(() => {
        showSuccessToast('Prompt successfully deployed.');
        setDeploymentId(deploymentId);
        setDeploymentIdForProvider((prevState) => ({
          ...prevState,
          [selectedProvider]: deploymentId,
        }));
        setLoading(false);
      }, 1000);
      await sendClickEvent(
        'prompt_deployed',
        `${tab}_${language}_${selectedProvider}`,
        3
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createDeploymentPrompt,
      deployer_name,
      language,
      model,
      org_id,
      saveNewVersion,
      selectedProvider,
      showSuccessToast,
      tab,
    ]
  );

  return (
    <Tabs value={tab} onValueChange={handleTabChange}>
      <div className="mb-4">
        <TabsList>
          <TabsTrigger value="completion">Completion</TabsTrigger>
          <TabsTrigger value="deployment">Deployment</TabsTrigger>
        </TabsList>
        <p className="text-sm text-muted-foreground italic pt-2">
          {tabDescription[tab]}
        </p>
      </div>
      <div className="overflow-y-auto">
        <div className="grid w-full items-center gap-4">
          {(!orgOpenAIKey || !orgAnthropicApiKey) && (
            <>
              <Label className="pt-2" htmlFor="providerkeys">
                Set your provider API key(s)
              </Label>
              <p className="text-sm text-muted-foreground pb-2">
                Set at least one provider api key. These will be used by the
                Parea completion proxy. You'll be able to set additional
                providers later.
              </p>
              <APIKeySetupCard
                openAILogoSrc={openAILogoSrc}
                orgOpenAIKey={orgOpenAIKey}
                orgAnthropicApiKey={orgAnthropicApiKey}
                org_id={org_id}
              />
              <Separator />
            </>
          )}
          {(orgOpenAIKey || orgAnthropicApiKey) && (
            <Label htmlFor="provider">Select your provider</Label>
          )}
          <div className="flex flex-wrap gap-4 w-full">
            {orgOpenAIKey && (
              <Button
                className={cn(
                  'flex cursor-pointer items-center gap-2 border border-gray-300 rounded-lg p-6 transition-all hover:bg-accent hover:text-accent-foreground',
                  selectedProvider === 'openai' &&
                    'bg-accent text-accent-foreground border-blue-500'
                )}
                variant={'secondary'}
                onClick={() => handleProviderChange('openai')}
              >
                <Avatar>
                  <AvatarImage src={openAILogoSrc} />
                  <AvatarFallback>O</AvatarFallback>
                </Avatar>
                <h2 className="font-semibold">OpenAI</h2>
              </Button>
            )}
            {orgAnthropicApiKey && (
              <Button
                className={cn(
                  'flex cursor-pointer items-center gap-2 border border-gray-300 rounded-lg p-6 transition-all hover:bg-accent hover:text-accent-foreground',
                  selectedProvider === 'anthropic' &&
                    'bg-accent text-accent-foreground border-blue-500'
                )}
                variant={'secondary'}
                onClick={() => handleProviderChange('anthropic')}
              >
                <Avatar>
                  <AvatarImage src={anthropicLogo} />
                  <AvatarFallback>A</AvatarFallback>
                </Avatar>
                <h2 className="font-semibold">Anthropic</h2>
              </Button>
            )}
          </div>
        </div>
        <TabsContent value="completion"></TabsContent>
        <TabsContent value="deployment">
          {(orgOpenAIKey || orgAnthropicApiKey) &&
            !deploymentIdForProvider[selectedProvider] && (
              <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-6 self-stretch rounded border border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4 mobile:h-auto mobile:w-full mobile:min-w-[240px] mobile:flex-none">
                <div>
                  <Label htmlFor="prompt">Example Prompt</Label>
                  <p className="text-sm text-muted-foreground italic">
                    We will deploy this example prompt. It contains one input
                    variable "topic". When calling this prompt we will provide
                    the value for "topic".
                  </p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <span className="text-body-bold font-body-bold text-default-font mr-4">
                    User
                  </span>
                  <Input value="Write a haiku about {{topic}}" disabled />
                </div>
                <Button variant="green" size="sm" onClick={handleDeployPrompt}>
                  {loading ? (
                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    <>
                      <IoRocket className="w-4 h-4 mr-2" />
                      Deploy
                    </>
                  )}
                </Button>
              </div>
            )}
        </TabsContent>
        {(orgOpenAIKey || orgAnthropicApiKey) &&
          (deploymentIdForProvider[selectedProvider] ||
            tab === 'completion') && (
            <>
              <div className="flex items-center justify-between my-4 ml-1">
                <div className="grid w-full items-center gap-4">
                  <Label htmlFor="language">Select your integration type</Label>
                  <Select value={language} onValueChange={handleLanguageChange}>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="python">Python</SelectItem>
                      <SelectItem value="typescript">Node.js</SelectItem>
                      <SelectItem value="curl">curl</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center space-x-2">
                  <CButton variant="ghost" size="sm" onClick={handleCopyCode}>
                    {copied ? (
                      <Check className="w-4 h-4 mr-2" />
                    ) : (
                      <Copy className="w-4 h-4 mr-2" />
                    )}
                    {copied ? 'Copied!' : 'Copy Code'}
                  </CButton>
                  <CButton variant="ghost" size="sm" onClick={handleViewDocs}>
                    <FileCode2 className="w-4 h-4 mr-2" />
                    View Docs
                  </CButton>
                </div>
              </div>
              <div className="grid w-full items-center gap-4">
                <Label htmlFor="code-editor">
                  {`Copy the code snippet and paste it in your ${language === 'curl' ? 'terminal' : 'IDE'}`}
                </Label>
                <CodeEditor
                  language={supportedLangToIDELang[language]}
                  value={codeSnippet}
                  onMount={handleEditorDidMount}
                  className="border-2 rounded-md h-[250px] xl:h-[375px]"
                  options={{
                    readOnly: true,
                    minimap: { enabled: false },
                  }}
                />
              </div>
            </>
          )}
      </div>
    </Tabs>
  );
};

function APIKeySetupCard({
  openAILogoSrc,
  orgOpenAIKey,
  orgAnthropicApiKey,
  org_id,
}) {
  const Providers = [
    {
      logo: openAILogoSrc,
      logoFallback: 'O',
      name: 'OpenAI',
      apikey: orgOpenAIKey,
      model_provider: 'openai',
    },
    {
      logo: anthropicLogo,
      logoFallback: 'A',
      name: 'Anthropic',
      apikey: orgAnthropicApiKey,
      model_provider: 'anthropic',
    },
  ];

  return (
    <div className="grid gap-6">
      {Providers.filter((provider) => !provider.apikey).map((provider) => (
        <div
          key={provider.name}
          className="flex items-center justify-between space-x-4"
        >
          <ProviderAvatar {...provider} />
          <KeyFormDialog
            provider={provider}
            org_id={org_id}
            forOrg={true}
            isOnboarding={true}
          />
        </div>
      ))}
    </div>
  );
}

export default PareaProxyCodeSnippet;
