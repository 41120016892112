import React, { useMemo, useState } from 'react';
import { useOrganizationList } from '@clerk/clerk-react';
import { useAuthManager } from '../../../hooks/auth/useAuthManager';
import { useSuccessfulToast } from '../../../hooks/useSuccessfulToast';
import { Label } from '../../../components/ui/label';
import { Input } from '../../../components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';
import StepCardWrapper from '../StepCardWrapper';
import { useAnalytics } from '../../../hooks/usePageVisitedAnalytics';
import { Switch } from '../../../components/ui/switch';

export const COMPANY_SIZES = ['Just me', '2-5', '5-25', '25-100', '100+'];

const referralOptions = [
  { value: 'friend_referral', label: 'Friend (referral)' },
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'google', label: 'Google' },
  { value: 'reddit', label: 'Reddit' },
  { value: 'github', label: 'Github' },
  { value: 'openAI', label: 'OpenAI' },
  { value: 'YCombinator', label: 'Y Combinator' },
  { value: 'other', label: 'Other' },
];

interface CreateOrgProps {
  nextStep: () => void;
}

const CreateOrg = (props: CreateOrgProps) => {
  const { nextStep } = props;

  const { user } = useAuthManager();
  const { createOrganization } = useOrganizationList();
  const orgId = useMemo(
    () => user?.organizationMemberships?.[0]?.organization?.id,
    [user]
  );
  const organizationName = useMemo(
    () => user?.organizationMemberships?.[0]?.organization?.name,
    [user]
  );
  const { sendAnalytics } = useAnalytics();

  const [joiningOrg, setJoiningOrg] = useState(false);
  const [referralType, setReferralType] = useState('');
  const [orgName, setOrgName] = useState(organizationName ?? '');
  const [orgSize, setOrgSize] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [referralTypeOther, setReferralTypeOther] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const showToast = useSuccessfulToast();

  const handleOrgCreate = async (e) => {
    if (!user) return;

    setIsLoading(true);

    if (orgName === '' && !joiningOrg) {
      showToast('Please enter a company name.', 'error');
      setIsLoading(false);
      return;
    }

    if (referralType === '' && referralTypeOther === '' && !joiningOrg) {
      showToast('Please select a referral source.', 'error');
      setIsLoading(false);
      return;
    }

    let newOrgId = null;
    if (!orgId && !joiningOrg) {
      e.preventDefault();
      const newOrg = await createOrganization({ name: orgName });
      newOrgId = newOrg?.id;
      setOrgName('');
      showToast('Successfully created organization.', 'success');
    }

    await sendAnalytics('onboarding_org_create', {
      referral_code: referralCode,
      org_size: orgSize,
      joining_existing_org: joiningOrg,
      referral_type:
        referralType === 'other'
          ? referralTypeOther
          : joiningOrg
            ? 'joining_existing_org'
            : referralType,
      answered_all: (orgSize && referralType) || joiningOrg,
    });
    setTimeout(
      () => {
        setIsLoading(false);
        nextStep(newOrgId, joiningOrg);
      },
      orgId || joiningOrg ? 0 : 600
    );
  };

  return (
    <StepCardWrapper
      nextStep={handleOrgCreate}
      previousStep={undefined}
      title={'Create your organization'}
      description={'Tell us a bit about you/your company'}
      isLoading={isLoading}
    >
      <React.Fragment>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="#">
            Toggle on if joining an existing organization?
          </Label>
          <div className="flex items-center space-x-2 mr-4">
            <Switch
              id="joiningOrg"
              checked={joiningOrg}
              onCheckedChange={() => setJoiningOrg(!joiningOrg)}
            />
            <Label htmlFor="joiningOrg" className="text-xs italic">
              {joiningOrg ? 'Joining existing org' : 'Creating new org'}
            </Label>
          </div>
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="org-name">What is your company name?</Label>
          <Input
            name="org-name"
            id="org-name"
            required
            placeholder={organizationName ?? 'Company name'}
            value={orgName}
            onChange={(e) => setOrgName(e.currentTarget.value)}
          />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="org-referral">How did you hear about us?</Label>
          <Select
            id="org-referral"
            name="org-referral"
            value={referralType}
            onValueChange={(value) => setReferralType(value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={'Select referral source'}>
                {referralType}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {referralOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {referralType === 'other' && (
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="referral-other">Other (optional)</Label>
            <Input
              id="referral-other"
              name="referral-other"
              placeholder={'Other referral source'}
              value={referralTypeOther}
              onChange={(e) => setReferralTypeOther(e.currentTarget.value)}
            />
          </div>
        )}
        {referralType === 'friend_referral' && (
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="referral-code">
              Referral Code/Company Name (optional)
            </Label>
            <Input
              id="referral-code"
              name="referral-code"
              placeholder={'Referral code/company name'}
              value={referralCode}
              onChange={(e) => setReferralCode(e.currentTarget.value)}
            />
          </div>
        )}
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="org-size">Expected team size</Label>
          <Select
            id="org-size"
            name="org-size"
            value={orgSize}
            onValueChange={(value) => setOrgSize(value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={'Select company size'}>
                {orgSize}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {COMPANY_SIZES.map((size) => (
                <SelectItem key={size} value={size}>
                  {size}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </React.Fragment>
    </StepCardWrapper>
  );
};

export default CreateOrg;
