import React from 'react';
import StepCardWrapper from '../StepCardWrapper';
import PareaProxyCodeSnippet from '../PareaProxyCodeSnippet';
import useFetchProjects from '../../../hooks/project/useFetchProjects';

interface FeaturesProps {
  apiKey?: string;
  previousStep: () => void;
  nextStep: () => void;
}

const Features = (props: FeaturesProps) => {
  const { apiKey, previousStep, nextStep } = props;
  useFetchProjects();

  return (
    <StepCardWrapper
      nextStep={() => {
        nextStep();
      }}
      previousStep={previousStep}
      title={'Parea LLM Proxy (Optional step)'}
      description={'Access any model using one unified API.'}
      isLoading={false}
    >
      <PareaProxyCodeSnippet apiKey={apiKey} />
    </StepCardWrapper>
  );
};

export default Features;
