import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ComparisonScoredResults,
  Experiment,
  ExperimentsObj,
  ExperimentState,
  StatPinnedToExperiment,
  StatPinnedToExperimentNoValue,
} from '../utils/types';
import { merge } from 'lodash';

const initialState: ExperimentState = {
  error: null,
  loading: false,
  experiments: {},
  publicExperiments: {},
  recentComparisons: [],
};

export const useExperimentStore = create(
  immer((set) => ({
    ...initialState,
    setError: (error: string) =>
      set((state) => {
        state.error = error;
      }),
    setLoading: (loading: boolean) =>
      set((state) => {
        state.loading = loading;
      }),
    setExperiments: (experiments: ExperimentsObj) =>
      set((state) => {
        state.experiments = merge(state.experiments, experiments);
      }),
    setPublicExperiments: (experiments: ExperimentsObj) =>
      set((state) => {
        state.publicExperiments = merge(state.publicExperiments, experiments);
      }),
    updateExperiment: (uuid: string, experiment: Experiment) =>
      set((state) => {
        state.experiments[uuid] = {
          ...state.experiments[uuid],
          ...experiment,
        };
      }),
    updatePublicExperiment(uuid: string, experiment: Experiment) {
      set((state) => {
        state.publicExperiments[uuid] = {
          ...state.publicExperiments[uuid],
          ...experiment,
        };
      });
    },
    deleteExperiment: (uuid: string) =>
      set((state) => {
        if (state.experiments[uuid]) {
          delete state.experiments[uuid];
        }
      }),
    addStatToExperiment: (uuid: string, stat: StatPinnedToExperiment) =>
      set((state) => {
        state.experiments[uuid].pinnedStats.push(stat);
      }),
    deleteExperimentBulk: (uuids: string[]) =>
      set((state) => {
        uuids.forEach((uuid) => {
          if (state.experiments[uuid]) {
            delete state.experiments[uuid];
          }
        });
      }),
    removeStatFromExperiment: (
      uuid: string,
      stat: StatPinnedToExperimentNoValue
    ) =>
      set((state) => {
        state.experiments[uuid].stats = state.experiments[uuid].stats.filter(
          (s) =>
            s.var1 !== stat.var1 ||
            s.var2 !== stat.var2 ||
            s.operation !== stat.operation
        );
      }),
    setRecentComparisons: (comparisons: ComparisonScoredResults[]) =>
      set((state) => {
        state.recentComparisons = comparisons;
      }),
  }))
);
