import {
  DEPLOYED_PROMPT_ENDPOINT,
  UPDATE_DEPLOYED_PROMPT_ENDPOINT,
} from '../../utils/constants';

import { useApiClient } from '../useApiClient';
import { useCallback } from 'react';
import { useDeployedPromptStore } from '../../store/useDeployedPromptStore';

const useDeploymentEndpoints = () => {
  const { postRequest, deleteRequest } = useApiClient();
  const { setLoading, setError } = useDeployedPromptStore((state) => ({
    setLoading: state.setLoading,
    setError: state.setError,
  }));

  const createDeploymentPrompt = useCallback(
    async (request) => {
      setLoading(true);
      try {
        await postRequest(DEPLOYED_PROMPT_ENDPOINT, request);
        return true;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [postRequest, setError, setLoading]
  );

  const deleteDeploymentPrompt = useCallback(
    async (request) => {
      setLoading(true);
      try {
        const response = await deleteRequest(DEPLOYED_PROMPT_ENDPOINT, request);
        return response.data;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [deleteRequest, setError, setLoading]
  );

  const updateDeploymentPrompt = useCallback(
    async (request) => {
      setLoading(true);
      try {
        const response = await postRequest(
          UPDATE_DEPLOYED_PROMPT_ENDPOINT,
          request
        );
        return response.data;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [postRequest, setError, setLoading]
  );

  return {
    createDeploymentPrompt,
    deleteDeploymentPrompt,
    updateDeploymentPrompt,
  };
};

export default useDeploymentEndpoints;
